/**
 * Created by Hamdi on 12/01/2018.
 */

angular.module('app').controller('deviceCtrl', function ($rootScope,$translate,ngDialog,alertConfigSrv,deviceMsgSrv,$stateParams,$timeout,Notification, $scope,deviceSrv) {
    $scope.checkedSrc=null;
    $scope.start = 0;
    $scope.alertConfigList=[];
    $scope.alertConfg={};

    var io_dt=[];


    $scope.newIO=[];

    $scope.HistoIO={};
    $scope.HistoIOLenght=0;

    $scope.asset={};

    $scope.getBType = function(test){
        return( typeof test);
    };

    $rootScope.$on('deviceEdited',function(event,data) {
        $scope.alertConfigList=[];


        $scope.checkedSrc=null;


        $scope.dev=angular.copy(data);

        if(data && data._asset.length>0){
            $scope.asset=angular.copy(data._asset[0]);
            $scope.start=0;
            $scope.loadMoreAlertConfig();


        }else{
            $scope.asset={};
        }

        if(data && data._asset.length>0 && data._asset[0].rt && data._asset[0].rt.io ){
            var io=angular.copy(data._asset[0].rt.io);

            io_dt=[];

            for(var key in io){
                var value = io[key];

                if(key.endsWith("_dt")){
                    var elem={src:key,val:value}

                    io_dt.push(elem)
                }

            }
            $scope.newIO=[];

            for(var key in io){
                var value = io[key];

                angular.forEach(io_dt, function (elem) {
                    if (typeof(value)=='number' && elem.src.includes(key)) {

                        var newElem = {src: key, val: value , dt:elem.val}

                        $scope.newIO.push(newElem)
                    }
                })
            }

            if(data._asset[0].rt.gps_dt){

                var newElem = {src: 'srv_dt', val:null , dt:data._asset[0].rt.srv_dt}
                $scope.newIO.push(newElem)

            }
            if(data._asset[0].rt.loc && data._asset[0].rt.loc.coordinates.length>0 ){

                var newElem = {src: 'loc', val:'lng: '+data._asset[0].rt.loc.coordinates[0]+', lat: '+data._asset[0].rt.loc.coordinates[1] , dt:null};
                //  var newElem = {src: 'loc', val:[data._asset[0].rt.loc.coordinates[0],data._asset[0].rt.loc.coordinates[1]], dt:null};
                $scope.newIO.push(newElem)

            }


        }else{
            $scope.newIO=[];
            $scope.asset={};
        }
    });


    $rootScope.$on('deviceDetails',function(event,data) {
        $scope.alertConfigList=[];


        $scope.checkedSrc=null;


        $scope.dev=angular.copy(data);

        if(data && data._asset.length>0){
            $scope.asset=angular.copy(data._asset[0]);
            $scope.start=0;

            $scope.loadMoreAlertConfig();

        }else{
            $scope.asset={};
        }

        if(data && data._asset.length>0 && data._asset[0].rt && data._asset[0].rt.io ){
            var io=angular.copy(data._asset[0].rt.io);

            io_dt=[];

            for(var key in io){
                var value = io[key];

                if(key.endsWith("_dt")){
             var elem={src:key,val:value}

                    io_dt.push(elem)
                }

            }
            $scope.newIO=[];

            for(var key in io){
                var value = io[key];

                angular.forEach(io_dt, function (elem) {
                    if (typeof(value)=='number' && elem.src.includes(key)) {

                        var newElem = {src: key, val: value , dt:elem.val}

                        $scope.newIO.push(newElem)
                    }
                })
            }

            if(data._asset[0].rt.gps_dt){

                var newElem = {src: 'srv_dt', val:null , dt:data._asset[0].rt.srv_dt}
                $scope.newIO.push(newElem)

            }
            if(data._asset[0].rt.loc && data._asset[0].rt.loc.coordinates.length>0 ){

                var newElem = {src: 'loc', val:'lng: '+data._asset[0].rt.loc.coordinates[0]+', lat: '+data._asset[0].rt.loc.coordinates[1] , dt:null};
              //  var newElem = {src: 'loc', val:[data._asset[0].rt.loc.coordinates[0],data._asset[0].rt.loc.coordinates[1]], dt:null};
                $scope.newIO.push(newElem)

            }


        }else{
            $scope.newIO=[];
            $scope.asset={};
        }
    });

    if(!$scope.dev) {
        $scope.alertConfigList=[];


        var query = {};

        var    options={
            populate: [
                {
                    path: '_asset'
                    // select: 'name'
                }
            ]
        }

        deviceSrv.getDevicesList({query: query, options: options },1,0,function (data) {


            if (data.success) {
                $scope.dev = angular.copy(data.result[0]);

                if($scope.dev &&  $scope.dev._asset.length>0){
                    $scope.asset=angular.copy($scope.dev._asset[0]);
                    $scope.start=0;

                    $scope.loadMoreAlertConfig();

                }else{
                    $scope.asset={}
                }

                if($scope.dev &&  $scope.dev._asset.length>0 &&  $scope.dev._asset[0].rt &&  $scope.dev._asset[0].rt.io ){


                    io_dt=[];

                    for(var key in $scope.dev._asset[0].rt.io ){
                        var value = io[key];

                        if(key.endsWith("_dt")){
                            var elem={src:key,val:value}

                            io_dt.push(elem)
                        }


                    }
                    $scope.newIO=[];

                    for(var key in $scope.dev._asset[0].rt.io){
                        var value = io[key];

                        angular.forEach(io_dt, function (elem) {
                            if (typeof(value)=='number' && elem.src.includes(key)) {

                                var newElem = {src: key, val: value , dt:elem.val}

                                $scope.newIO.push(newElem)
                            }

                        })

                    }

                    if($scope.dev._asset[0].rt.gps_dt){

                        var newElem = {src: 'srv_dt', val:null , dt:$scope.dev._asset[0].rt.srv_dt}
                        $scope.newIO.push(newElem)

                    }
                    if(data._asset[0].rt.loc && data._asset[0].rt.loc.coordinates.length>0 ){

                        var newElem = {src: 'loc', val:'lng: '+data._asset[0].rt.loc.coordinates[0]+',lng: '+data._asset[0].rt.loc.coordinates[1] , dt:null};
                        $scope.newIO.push(newElem)

                    }

                }else{
                    $scope.newIO=[];
                    $scope.asset={};
                }


            } else {

                console.log(data.error)
            }

        }, function (data) {
            console.log(data)

        }) ;


    }


    ////////////////// showGraph  /////////////////////

    $scope.showGraph=function(item){
        if(item)
            $scope.checkedSrc='io.'+item;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/chart/device.chart.dlg.html",
            controller: "chartCtrl",
            scope: $scope,
            width: '500px'


        });

    };

    $scope.showMap = function () {

            ngDialog.open({
                template: 'app/commons/dialogs/map/map.dlg.html',
                controller: 'mapDlgCtrl',
                scope: $scope,
                width: '600px'

            })

    };


    $scope.loadMoreAlertConfig = function() {


        $scope.start = $scope.start + 1;

        var query = {};
        var options  = {
            populate : ["_assetId _company_owner"],
            sortBy:{start_dt:-1}
        };

          if($scope.asset && $scope.asset._id) {


              query["_assetId"]={'$eq':$scope.asset._id};




                  alertConfigSrv.getAlertConfigsList({query: query, options: options }, 1000, $scope.start, function (data) {
                      if (data.success && data.result && data.result.length > 0) {

                          angular.forEach(data.result, function (element) {
                              $scope.alertConfigList.push(element)
                          })

                      }


                  }, function (err) {
                      console.log(err)
                  })

          }
    };
    $scope.removeAlertConfig=function(alert,index){
        ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm/confirm.dlg.html',overlay:true ,showClose:false,controller: ['$scope', function($scope) {
            // controller logic
            $scope.title=$translate.instant("ALERT_CONFIG_CONDITION_DELETE_CONFIRM_TITLE");
            $scope.message=$translate.instant("ALERT_CONFIG_CONDITION_DELETE_CONFIRM_MESSAGE")
        }] }).then(function () {
            alertConfigSrv.deleteAlertConfigById(alert._id,function(data){
                if(data.success) {
                    $scope.alertConfigList.splice(index,1);
                    Notification($translate.instant("ALERT_ALERT_CONFIG_DELETED", {name:alert.name?alert.name:null}));

                }else {
                    console.log(data.error)
                }
            },function(err){

            })
        }, function (reason) {

        });
    };

    $scope.editAlertConf = function (alertConfg) {
        $scope.alertConfg = alertConfg;
/*
var soket=false
var email=false

 if($scope.alertConfg && $scope.alertConfg.actions && $scope.alertConfg.actions.length>0) {
     angular.forEach($scope.alertConfg.actions, function (element) {
         if (element && element.type == 'SOCKET_IO')
             soket = true;

         if (element && element.type == 'EMAIL')
             email = true;
     })

 }else{
     $scope.alertConfg.actions=[];

 }


if(soket==false)
    $scope.alertConfg.actions[1]={"type":"SOCKET_IO"};

        if(email==false)
    $scope.alertConfg.actions[0]={"type":"EMAIL"};

*/




        ngDialog.openConfirm({
            template: 'app/commons/dialogs/alert/alert.edit.dlg.html',
            overlay: true,
            showClose: false,
            className: "ngdialog-theme-default",
            controller: 'alertConfigEditCtrl',
            scope: $scope,
            width: '800px'
        })
    };

});