/**
 * Created by Kais CHAIBI on 04/05/2017.
 */
angular.module("app.services").factory('RefuelingSrv', function ($http, $rootScope, dataSrv, $window, $filter, deviceMsgSrv, Notification, $q, CONFIG, dUtilsSrv,fuelFilterSrv ,geoDataSrv) {

    /**
     * refueling Service  constructor
     * @param config
     *  refuelingPeriod : the max difference time between   2  histo data to detect refueling (in seconds)
     *  refuelingValue  : fuel difference   between  2   histo data  to detect refueling (in liter )
     *  maxTimeDiffTotalIxd : the max time difference between  TOTAL DATA  and IAXEED data to be considered as same refueling
     * @constructor
     */
    const FUEL_LEVEL_SENSOR_TYPE = "FUEL_LEVEL";
    const DEFAULT_FUEL_PROPERTY = "io.CAN_FLL";

    const  FUEL_TYPES={

        GASOLINE:["ESSENCE", "SUPER SP"],
        ESSENCE_SP:["ESSENCE", "SUPER SP"],
        ESSENCE:["ESSENCE", "SUPER SP"],
        DIESEL:["GASOIL", "GASOIL SS","GASOIL 50"],
        GASOIL:["GASOIL", "GASOIL SS","GASOIL 50"],
        'GASOIL 50':["GASOIL 50"],
        'GASOIL SS':["GASOIL", "GASOIL SS"]
    };
    const debug = true;

    function RefuelingSrv(config) {
        this.maxPeriodRefuelDetection = config.refuelingPeriod ? config.refuelingPeriod : 1200;
        this.maxPeriodBetweenRefuling = config.maxPeriodBetweenRefuling ? config.maxPeriodBetweenRefuling : 30*60*1000; // 5 min
        this.minValueRefuelDetection = config.refuelingValue ? config.refuelingValue : 5;
        this.maxTimeDiffTotalIxd = config.maxTimeDiffTotalIxd ? config.maxTimeDiffTotalIxd : 60*60;
        this.asset = config.asset ? config.asset : null;
        this.startDate = moment().startOf('day');
        this.endDate = moment().endOf('day');
        this.company = config.company ? config.company : null;
        this.localData = [];
        this.localRefulingData = [];
        this.totalData = [];
        this.mergedData = [];
    }

    /**
     *
     * computing  refueling  from histo data
     * @param assetId
     */
    RefuelingSrv.prototype.getLocalRefueling = function (data, cb) {

       /* var movingWindowAvg = function (arr, step) {  // Window size = 2 * step + 1
            return arr.map(function (val, idx) {
                var wnd = arr.slice(idx - step, idx + step + 1);
                var result =arr.reduce(function (a, b) {
                   console.log(typeof  a ,typeof b.fuel ,parseFloat(a) + b.fuel);
                    return parseFloat(a) + b.fuel;
                })

                //console.log(val.fuel,"vs", result, wnd.length)
                result=result/wnd.length
                arr[idx].fuel=(result == result) ? result : val.fuel;

                return val;
            });
        };*/


        var self = this;
        var Refueling = [];



        if (data && data.length > 1) {

            var  fuelData = [];
            var signalTime=[];
             // data = movingWindowAvg(data, 7);
    /*    data.forEach(function(val,index){

            if(index!=0 && index!=data.length-1){
                data[index].fuel=(val.fuel+data[index-1].fuel +  data[index+1].fuel)/3
            }

            })*/



            for (var i = 0; i < data.length - 1; i++) {


                if (Number.isFinite(data[i + 1].fuel) && Number.isFinite(data[i].fuel) && moment(data[i + 1].gps_dt).diff(moment(data[i].gps_dt), "seconds") < self.maxPeriodRefuelDetection && (data[i + 1].fuel - data[i].fuel ) > self.minValueRefuelDetection) {
                          console.log("Refuling founded " ,data[i ].fuel ,data[i + 1].fuel ,moment(data[i+1].gps_dt).format("YYYY-MM-DD HH:mm:ss"),  moment(data[i].gps_dt).format("YYYY-MM-DD HH:mm:ss")  )

                    Refueling.push({
                        gps_dt: moment(data[i].gps_dt),
                        refueling: data[i + 1].fuel - data[i].fuel,
                        fuelBefore: data[i].fuel,
                        fuelAfter: data[i + 1].fuel,
                        loc: data[i ].loc
                    })
                }


            }

           // console.log(Refueling);

            Refueling.sort(function (a, b) {
                return moment(a.gps_dt).toDate().getTime() - moment(b.gps_dt).toDate().getTime();
            });

            var auxRef=[] ;
            var currentRefuling=null;

                for (i = 1; i < Refueling.length; i++) {

                    //console.log("increment "+i +"/"+(moment(Refueling[i - 1].gps_dt).diff(moment(Refueling[i].gps_dt))))
                    if (!currentRefuling) {
                        currentRefuling = Refueling[i - 1];
                    }
                    if (moment(Refueling[i].gps_dt).diff(moment(Refueling[i-1].gps_dt)) < self.maxPeriodBetweenRefuling) {
                        currentRefuling.gps_dt = Refueling[i].gps_dt;
                        currentRefuling.refueling += Refueling[i].refueling;
                        currentRefuling.fuelAfter = data[i + 1].fuel
                    } else {
                        auxRef.push(currentRefuling);
                        //console.log(currentRefuling);
                        currentRefuling = null;
                    }
                    if(i==Refueling.length-1){

                        if(currentRefuling){
                            auxRef.push(currentRefuling)
                        }else{
                            auxRef.push(Refueling[i])
                        }
                    }

                }


            //console.log(auxRef)


            self.revGeoCodingAddress(auxRef, function (err, res) {

                cb(err,res);
                /*self.findClosestTotalStation(res,function(result){

                    return cb(null, result);
                })*/
            })


        } else {
            return cb(null, []);

        }


    };
    /**
     * GEt histo data from iaxeed local server
     * @param cb callback function (err,res )
     */
    RefuelingSrv.prototype.getLocalHistoData = function (cb) {

        var self = this;
        var sensor = self.getFuelLevelSensor();
        var src = DEFAULT_FUEL_PROPERTY;
        var ratio = 1;

        if (sensor) {
            var src = sensor.src;
            var ratio = sensor.ratio;

        }
        var query = self.getHistoQuery(src, sensor);
        deviceMsgSrv.getDeviceMsgAgg({query: query}, 10000, 0, function (res) {
            if (res && res.length > 0) {
                res = $filter("filter")(res, function (a) {
                    return Number.isFinite(a.fuel) && a.con===1 && a.fuel>0


                }, true);

                res.sort(function (a, b) {
                    return moment(a.gps_dt).toDate().getTime() - moment(b.gps_dt).toDate().getTime();
                });

                //self.localDataBrut= angular.copy(res);
                function createMedianFilter(length) {
                    var buffer   = new Float64Array(length);
                    var history  = new Int32Array(length);
                    var counter  = 0;
                    var bufCount = 0;
                    function insertItem(x) {
                        var nextCounter = counter++;
                        var oldCounter  = nextCounter - length;

                        //First pass:  Remove all old items
                        var ptr = 0;
                        for(var i=0; i<bufCount; ++i) {
                            var c = history[i];
                            if(c <= oldCounter) {
                                continue
                            }
                            buffer[ptr] = buffer[i];
                            history[ptr] = c;
                            ptr += 1
                        }
                        bufCount = ptr;

                        //Second pass:  Insert x
                        if(!isNaN(x)) {
                            var ptr = bufCount;
                            for(var j=bufCount-1; j>=0; --j) {
                                var y = buffer[j];
                                if(y < x) {
                                    buffer[ptr] = x;
                                    history[ptr] = nextCounter;
                                    break
                                }
                                buffer[ptr] = y;
                                history[ptr] = history[j];
                                ptr -= 1
                            }
                            if(j < 0) {
                                buffer[0]  = x;
                                history[0] = nextCounter
                            }
                            bufCount += 1
                        }

                        //Return median
                        if(!bufCount) {
                            return NaN
                        } else if(bufCount & 1) {
                            return buffer[bufCount>>>1]
                        } else {
                            var mid = bufCount>>>1;
                            return 0.5*(buffer[mid-1] + buffer[mid])
                        }
                    }
                    return insertItem
                }
                try {
                    var median = createMedianFilter(20);
                    var dataseq = res.map(function (val) {
                        return val.fuel;
                    });
                    for (var i = 0; i < dataseq.length; ++i) {
                        var currentVal = median(dataseq[i]);
                     //   console.log(res[i].fuel, currentVal, res[i].gps_dt)
                        res[i].fuel = currentVal;
                        res[i].gps_dt = moment(res[i].gps_dt,"YYYY-MM-DDTHH:mm:ss");

                    }
                }catch(e){
                    console.log(e);
                }
              /**  var dataseq = res.map(function (val) {
                    return val.fuel;
                })


               var filtredData= fuelFilterSrv.processData(dataseq);
                for (var i = 0; i < dataseq.length; ++i) {
                    var currentVal = filtredData[i];
                    console.log(res[i].fuel, currentVal, res[i].gps_dt)
                    res[i].fuel = currentVal;

                }*/

                self.getLocalRefueling(res, function (errr, ress) {
                    self.localRefulingData = ress;

                    cb(null, res)
                });


            } else {
                if (debug)
                    console.debug("RefuelingSrv.getLocalHistoData ", "NO DATA FOUNDED ", query, res);
                cb(null, [])
            }
        }, function (err) {

            if (err && err.status == -1) {
                Notification.error("Problème de connexion Internet");
            }
            cb(err);
            console.error("Device message error ", err);
        }, true)


    };
    RefuelingSrv.prototype.getFuelLevelSensor = function () {
        var self = this;
        if (self.asset && self.asset.sensors && self.asset.sensors.length > 0) {
            var filteredSensor = $filter("filter")(self.asset.sensors, {type: FUEL_LEVEL_SENSOR_TYPE}, true);
            if (filteredSensor && filteredSensor.length > 0) {
                var src = filteredSensor[0].src.replace("rt.", "");
                var ratio = 1;
                var  max= 1;
                var min = 0 ;
                var minSrc=0;
                var maxSrc=1;
                if (Number.isFinite(filteredSensor[0].maxSrc) && Number.isFinite(filteredSensor[0].max) && filteredSensor[0].maxSrc > 0) {

                    ratio = filteredSensor[0].max / filteredSensor[0].maxSrc
                    var  max= filteredSensor[0].max ;
                    var min = filteredSensor[0].min ;
                    var minSrc=filteredSensor[0].minSrc ;
                    var maxSrc=filteredSensor[0].maxSrc;
                }

                self.sensorMax=filteredSensor[0].max;
                if(Number.isFinite(self.sensorMax) && self.sensorMax>0){
                    self.minValueRefuelDetection=0.05*self.sensorMax   ;
                }
                return {
                    src: src,
                    ratio: ratio,
                    max:max,
                    min:min,
                    maxSrc:maxSrc,
                    minSrc:minSrc
                }
            }
        } else {
            return null;
        }
    };

    RefuelingSrv.prototype.getHistoQuery = function (src, sensorFuel) {
        var self = this;

        var query = [];
        var multiplyRate = sensorFuel && sensorFuel.ratio ? sensorFuel.ratio : 1;
        var src = src ? src : DEFAULT_FUEL_PROPERTY;


        query = [
            {
                "$match": {
                    "_asset": self.asset._id,
                    gps_dt: {
                        $gte: self.startDate.toDate(),
                        $lte: self.endDate.toDate()
                    }
                 //  "io.con":1


                }
            }, {
                "$project": {


                    "gps_dt": {"$add": ["$gps_dt", moment().utcOffset() * 60 * 1000]},
                    "fuel": {$multiply: ["$" + src, multiplyRate]},

                    "loc": "$loc",
                    "con": "$io.con"


                }

            },

            {"$sort": {"gps_dt": 1}}
        ];
        //query[0].$match[src]= {$gte:0.00001}
      if(sensorFuel.min ||  sensorFuel.minSrc){
          query[1]. $project.fuel= {$add:[{$multiply: [{"$subtract":["$" + src,sensorFuel.minSrc]},(sensorFuel.max - sensorFuel.min)/(sensorFuel.maxSrc -sensorFuel. minSrc)  ]},sensorFuel.min]};
      }
        return query


    };

    RefuelingSrv.prototype.createTotalSession = function (cb) {
        var self = this;

        if (self.company && self.company.totalData && self.company.totalData.username) {


            var user = {'login': self.company.totalData.username, 'password': self.company.totalData.password};
            $http.post(CONFIG.API_URI + '/total/Sessions/Create', user, {'withCredentials': true}).then(function (response) {
                if (response && response.status == 200) {
                    if (response && response.data &&
                        response.data.userInfo &&
                        response.data.userInfo.ClientsIds &&
                        response.data.userInfo.ClientsIds.length > 0) {
                        self.totalClientId = response.data.userInfo.ClientsIds[0];
                        self.totalClientId=-1;
                        cb(null, response);
                    } else {
                        Notification.error('Problème lié au serveur Total');
                        // TODO NOTIFY FOR WRONG TOTAL SERVER RESPONSE , UNABLE TO GET CLIENT ID
                        if (debug)
                            console.debug("UNABLE TO GET CLIENT ID FROM TOTAL RESPONSE ", response);
                        cb("UNABLE TO GET CLIENT ID FROM TOTAL RESPONSE ");
                    }

                } else {
                    if (debug)
                        console.debug("GET TOTAL SESSION FAIL", response);
                    cb(response, null);
                }
            }, function (response) {
                console.error("GET TOTAL SESSION FAIL", response);
                cb(response, null);
            });
        } else {
            Notification.error('Pas de compte Total associé à cette entreprise');
            // TODO NOTIFY COMPANY HAS NO TOTAL ACCOUNT
            if (debug)
                console.debug("GET TOTAL SESSION FAIL", "COMPANY HAS NO TOTAL ACCOUNT ");
            cb("COMPANY HAS NO TOTAL ACCOUNT")
        }
    };
    RefuelingSrv.prototype.getTotalTransactions = function (page, limit, cb) {

        var self = this;
        var l = 100;
        var p = 0;

        if (limit) l = limit;
        if (page && page >= 1) p = (page - 1) * l;

        var dateRange = '&dateDebut=' + (self.startDate.format("MM/DD/YYYY")) + '&dateFin=' + (self.endDate.format("MM/DD/YYYY"));
        if (self.totalClientId) {
            var cardId = -1;
            if (self.cardId) {
                cardId = self.cardId
            } else if (self.asset && self.asset.idCard) {
                cardId = self.asset.idCard
            }
            $http.post(CONFIG.API_URI + '/total/Operations?idClient=' + self.totalClientId + '&idCarte=' + cardId + dateRange + '&typeOp=transaction&limit=' + l + '&start=' + p, {}, {withCredentials: true}).then(function successCallback(response) {
                if (response && response.status === 200) {
                    self.totalData = response.data;

                    var data = response.data.data.map(function (val) {
                        val.date = self.getASPDate(val.date_trans, val.heure_trans);
                        return val;
                    });
                    data.sort(function (a, b) {
                        return moment(a.date).toDate().getTime() - moment(b.date).toDate().getTime();
                    });
                    self.totalData.data = data;
                    cb(null, response.data)
                } else if (response && response.status === 401) {
                    Notification.error('Veuillez vous reconnecter');
                    // TODO ambigus UNAUTHORIZED ACCESS DUE THE SAME ERROR CODE RESPONSE FROM  IAXEED API  AND  TOTAL API
                    // TODO JUST PROMPT FOR EXPIRED IAXEED  SESSION AND RE-LOGIN
                    cb(response);
                } else {
                    if (debug)
                        console.debug("INVALID RESPONSE FROM  TOTAL API  ", response);
                    cb(response);
                }
            }, function errorCallback(data, status, headers, config) {
                if (debug)
                    console.debug("TOTAL API HTTP ERROR   ", response);
                cb(data)
            });

        } else {
            Notification.error('Pas de compte Total associé à cette entreprise');
            //TODO PROMPT FOR INVALID TOTAL COMPANY ACCOUNT
            cb("COMPANY HAS NO TOTAL ACCOUNT ")
        }

    };

    RefuelingSrv.prototype.findClosestTotalStation=function(iaxeedData,cb){

        var query={
            query: {"properties.Type":"PETROL_STATION","properties.extraType":{"$regex": "TOTAL_STATION"}  },
            points:[],
            options:{
                maxDistance:2000 // in meters
            }
        };
        iaxeedData.forEach(function(val){
            query.points.push(val.loc)
        });

        geoDataSrv.findBulkNear(query,function(res){
           if(res.success && res.result && res.result.length>0){
               iaxeedData.forEach(function(val,index){

                   if(res.result[index] && res.result[index].nearest && res.result[index].nearest.length>0){
                       val.nearest=res.result[index].nearest[0];
                   }
               })

           }


            cb(iaxeedData)
        },function(err){

            cb(iaxeedData)
        })
    };
    RefuelingSrv.prototype.mergeData = function (totalTransaction, iaxeedHistoData) {

        var self = this;
        var iaxeedHistoData = angular.copy(iaxeedHistoData);
        var totalTransaction = angular.copy(totalTransaction);

        var refuelingMerged = [];
        self.fraudQttCalc = function (merged, iaxeedQtt, totalQtt) {
            merged.ecart = Math.abs(iaxeedQtt - totalQtt).toFixed(2);
            merged.ecartPercentage = self.ecartPercentage(merged.ecart);

            if((merged.ecartPercentage > self.company.params.fraudRate) || (merged.ecartPercentage > 5)){
                merged.isQttFraud=true;
            }

            return merged;
        };

        self.ecartPercentage = function (ecart) {
            return Math.abs(ecart) * 100 / self.sensorMax;
        };
        if (totalTransaction && totalTransaction.length > 0) {
            if (iaxeedHistoData && iaxeedHistoData.length > 0) {
                for (var i = 0; i < totalTransaction.length; i++) {

                    var closestRefuel = null;
                    var closestRefuelIndex = -1;
                    var totalRefulingDate = moment((totalTransaction[i].date));
                    var j = 0;
                    while (j < iaxeedHistoData.length) {


                        if (!closestRefuel) {
                            closestRefuel = iaxeedHistoData[j];
                            closestRefuelIndex = j;

                        } else {
                            var iaxeedRefuelingTime = moment(iaxeedHistoData[j].gps_dt);


                            // console.log(,iaxeedRefuelingTime.format("YYYY-MM-DD HH:mm:ss"))
                            //     console.log("TOTAL REFULING  | ",totalRefulingDate.format("YYYY-MM-DD HH:mm:ss"),' Iaxeed Refuling |' ,iaxeedRefuelingTime.format("YYYY-MM-DD HH:mm:ss")," CLOSEST  POINT |  "+ moment(closestRefuel.gps_dt).format("YYYY-MM-DD HH:mm:ss") , Math.abs(iaxeedRefuelingTime.diff(totalRefulingDate, "seconds")),Math.abs(moment(closestRefuel.gps_dt).diff(totalRefulingDate, "seconds")));


                            if (Math.abs(moment(closestRefuel.gps_dt).diff(totalRefulingDate, "seconds")) > Math.abs(iaxeedRefuelingTime.diff(totalRefulingDate, "seconds"))) {

                                closestRefuel = iaxeedHistoData[j];
                                closestRefuelIndex = j;
                            }
                            if (iaxeedRefuelingTime.diff(totalRefulingDate, "seconds") > self.maxTimeDiffTotalIxd) {
                                //    break;
                                // j= iaxeedHistoData.length+1;
                                //TODO BREAK ALWAYS IF TOTAL DATE GO  FAR FROM HISTO DATA for maxTimeDiffTotalIxd and more
                            }

                        }

                        j++;
                    }
                    var merged = {
                        date: totalTransaction[i].date,
                        totalData: totalTransaction[i],
                        ecart : totalTransaction[i].qtt,
                        ecartPercentage : self.ecartPercentage(totalTransaction[i].qtt)
                    };

                    /*if( self.checkProductFraud(totalTransaction[i].prod)){
                        merged.isProductFraud=true;
                    }*/
                    if (closestRefuel) {
                        //  console.log("closestRefuel "  + moment(closestRefuel.gps_dt).format("YYYY-MM-DD HH:mm:ss"), totalRefulingDate.format("YYYY-MM-DD HH:mm:ss"),Math.abs(moment(closestRefuel.gps_dt).diff(totalRefulingDate, "seconds")) );
                        if (Math.abs(moment(closestRefuel.gps_dt).diff(totalRefulingDate, "seconds")) <= self.maxTimeDiffTotalIxd) {
                            merged.iaxeedData = closestRefuel;
                            if(angular.isUndefined(closestRefuel.refueling) || !closestRefuel.refueling || closestRefuel.refueling === null){
                                closestRefuel.refueling = 0;
                            }
                            if(angular.isUndefined(totalTransaction[i].qtt) || !totalTransaction[i].qtt || totalTransaction[i].qtt === null){
                                totalTransaction[i].qtt = 0;
                            }
                            if(closestRefuel.fuelBefore +totalTransaction[i].qtt> self.sensorMax ){

                               // merged.totalData.qtt=self.sensorMax-closestRefuel.fuelBefore;
                                merged.iaxeedData.refueling+=totalTransaction[i].qtt+ closestRefuel.fuelBefore  - self.sensorMax;


                            }
                            merged = self.fraudQttCalc(merged, closestRefuel.refueling,totalTransaction[i].qtt);

                            iaxeedHistoData.splice(closestRefuelIndex, 1);
                        }
                    }
                    refuelingMerged.push(merged);

                }
                iaxeedHistoData.forEach(function (val) {
                    var merged = {
                        date: moment(val.gps_dt),
                        totalData: null,
                        iaxeedData: val
                    };

                    refuelingMerged.push(merged);
                })

            } else {

                refuelingMerged = totalTransaction.map(function (val) {
                    return {
                        date: val.date,
                        totalData: val,
                        iaxeedData: null
                    }
                });


            }

        }
        else if (iaxeedHistoData && iaxeedHistoData.length > 0) {
            refuelingMerged = iaxeedHistoData.map(function (val) {
                return {
                    date: val.gps_dt,
                    iaxeedData: val
                }
            });

        }
        refuelingMerged.sort(function (a, b) {
            return moment(a.date).toDate().getTime() - moment(b.date).toDate().getTime();
        });
        self.mergedData = refuelingMerged;
        return refuelingMerged;

    };

    RefuelingSrv.prototype.getTransationAndCompare = function (cb) {
        var self = this;
        var totalDefer = $q.defer();
        var iaxeedDefer = $q.defer();
        self.totalData = [];
        self.iaxeedData = [];
        self.localRefulingData = [];
        self.mergedData = [];
        self.getTotalTransactions(0, 1000000, function (err, res) {
            if (err) {
                if(err.data && err.data.err && err.data.err=="CardNotFound"){
                    Notification.warning("Le numéro  de la carte est invalide");
                    totalDefer.resolve(null);
                }else{
                    totalDefer.reject(err);
                }

            } else {
                self.totalData = res;
                totalDefer.resolve();
            }
        });
        self.getLocalHistoData(function (err, res) {

            if (err) {
                iaxeedDefer.reject(err);
            } else {
                self.iaxeedData = res;
                iaxeedDefer.resolve();
            }
        });

        $q.all([iaxeedDefer.promise, totalDefer.promise]).then(function () {


            if (self.totalData && self.localRefulingData && ((self.totalData.data && self.totalData.data.length > 0) || self.localRefulingData.length > 0 )) {
                try {

                    self.mergeData(self.totalData.data, self.localRefulingData);

                    cb(null, self.mergedData);
                } catch (e) {
                    console.error(e);
                }
            } else {
                if (debug)
                    console.debug("NO DATA FOUNDED  ", self.totalData, self.iaxeedData);
                cb("NO DATA ")
            }


        }, function (reaison) {
            console.log("raison", reaison);
        })


    };

    /*RefuelingSrv.prototype.getTotalRefulingSerie = function () {
        var self = this;


        var totalSeries = [
            {name: 'TOTAL', data: [], color: '#01DF01', type: 'column'},
            {
                "type": "scatter", "name": "TOTAL_D", "zIndex": 30,
                "marker": {
                    "radius": 1, "symbol": 'url(app/assets/images/historic/fuel-total.png)'
                },
                data: [], "turboThreshold": 0,
                pointWidth: 35

            },
        ];

        self.totalData.data.forEach(function (val) {
            totalSeries[0].data.push([moment(val.date).valueOf(), val.qtt]);
            totalSeries[1].data.push([moment(val.date).valueOf(), val.qtt]);
        })

        return totalSeries;
    }
    RefuelingSrv.prototype.getLocalRefulingSerie = function () {
        var self = this;

        var localSeries = [
            {name: 'NGI', data: [], color: '#006DF0', marker: {symbol: 'square'}},
            {
                "type": "scatter", "name": "NGI_D", "zIndex": 30,
                "marker": {
                    "radius": 1, "symbol": 'url(app/assets/images/historic/fuel-ngi.png)'
                },
                data: [],
                "turboThreshold": 0
            }];


        self.localRefulingData.forEach(function (val) {

            localSeries[1].data.push([moment(val.gps_dt).valueOf(), val.refueling]);

        })

        self.iaxeedData.forEach(function (val) {
            localSeries[0].data.push([moment(val.gps_dt).valueOf(), val.fuel]);

        })


        return localSeries;
    }*/





    /*RefuelingSrv.prototype.getTotalRefulingSerie = function () {
        var self = this;


        var totalSeries = [
            {name: 'TOTAL', data: [], color: '#c42525', type: 'column'},
            {
                "type": "scatter", "name": "TOTAL_D", "zIndex": 30,
                "marker": {
                    "radius": 1, "symbol": 'url(app/assets/images/historic/fuel-total.png)'
                },
                data: [], "turboThreshold": 0,
                pointWidth: 35

            },
        ];

        self.totalData.data.forEach(function (val) {
            totalSeries[0].data.push([moment(val.date).valueOf(), val.qtt]);
            totalSeries[1].data.push([moment(val.date).valueOf(), val.qtt]);
        })

        return totalSeries;
    }*/
    RefuelingSrv.prototype.getRefulingSerie = function () {
        var self = this;

        var refulingSeries = [
            {name: 'DETECTION TOTAL', data: [], color: '#64FE2E', type: 'column'},
            {name: 'NO DETECTION TOTAL', data: [], color: '#c42525', type: 'column'}
            /*{
                "type": "scatter", "name": "TOTAL_D", "zIndex": 30,
                "marker": {
                    "radius": 1, "symbol": 'url(app/assets/images/historic/fuel-total.png)'
                },
                data: [], "turboThreshold": 0,
                pointWidth: 15

            },*/

            //{name: 'NGI', data: [], color: '#006DF0', marker: {symbol: 'square'}},
            /*{
                "type": "scatter", "name": "NGI_D", "zIndex": 30,
                "marker": {
                    "radius": 1, "symbol": 'url(app/assets/images/historic/fuel-ngi.png)'
                },
                data: [],
                "turboThreshold": 0
            }*/
        ];

        self.mergedData.forEach(function (val) {
            if(val.totalData){
                if(val.iaxeedData){
                    //color green
                    refulingSeries[0].data.push([moment(val.totalData.date).valueOf(), val.totalData.qtt]);
                    //refulingSeries[1].data.push([moment(val.totalData.date).valueOf(), val.totalData.qtt]);

                }else{
                    //default color
                    //refulingSeries[0].data.push([moment(val.totalData.date).valueOf(), val.totalData.qtt]);
                    refulingSeries[1].data.push([moment(val.totalData.date).valueOf(), val.totalData.qtt]);
                }
            }
            /*if(val.iaxeedData){
                refulingSeries[2].data.push([moment(val.iaxeedData.gps_dt).valueOf(), val.iaxeedData.refueling]);
                //refulingSeries[3].data.push([moment(val.iaxeedData.gps_dt).valueOf(), val.iaxeedData.refueling]);
            }*/

        });
        return refulingSeries;
    };


    RefuelingSrv.prototype.getLocalRefulingSerie = function () {
        var self = this;

        var localSeries = [
            {name: 'Fuel Level', data: [], marker: {symbol: 'square'}},

            {
                "type": "scatter", "name": "Ravitaillement", "zIndex": 30,
                "marker": {
                    "radius": 1, "symbol": 'url(app/assets/images/historic/fuel-total.png)'
                },
                data: [],
                "turboThreshold": 0
            }

     //   ,  {name: 'Fuel BRUT', data: [], marker: {symbol: 'square'}}


        ];


        self.localRefulingData.forEach(function (val) {

            localSeries[1].data.push([moment(val.gps_dt).valueOf(), val.refueling]);

        });

        self.iaxeedData.forEach(function (val) {
            localSeries[0].data.push([moment(val.gps_dt).valueOf(), val.fuel]);

        });
       /* self.localDataBrut.forEach(function (val) {
            localSeries[2].data.push([moment(val.gps_dt).valueOf(), val.fuel]);

        });*/


        return localSeries;
    };


    RefuelingSrv.prototype.getSeries = function () {
        return this.getRefulingSerie().concat(this.getLocalRefulingSerie());
    };








    RefuelingSrv.prototype.revGeoCodingAddress = function (refuling,callback) {

        var self = this;
        var coordinates = [];

          refuling.forEach(function (val) {

              if (val.loc && val.loc.coordinates && val.loc.coordinates.length > 1 && Number.isFinite(val.loc.coordinates[0]) && Number.isFinite(val.loc.coordinates[1])) {
                  coordinates.push(val.loc.coordinates);
              }


          });


        if (coordinates && coordinates.length > 0) {

//            console.log(coordinates);

            dUtilsSrv.getAddressList(coordinates).then( function (res) {



                    var currentAddressIndex = 0;
                    refuling.forEach(function (val) {

                        if (val.loc && val.loc.coordinates && val.loc.coordinates.length > 1 && Number.isFinite(val.loc.coordinates[0]) && Number.isFinite(val.loc.coordinates[1])) {
                            val.address =  res[currentAddressIndex];
                            currentAddressIndex++;
                        }


                    });
//                console.log(refuling);

                callback( null,refuling);

            }, function (err) {

                console.warn("Reverse geocoding fail", err);
                callback(null, self.localRefulingData);
            })
        } else {
            console.warn("Reverse geocoding no data ", err);


            callback(null, refuling);
        }

    };
    RefuelingSrv.prototype.formatAddress = function (address) {
        var addr = "";
        if (address && address.PostalAddress) {
            if (address.PostalAddress.StreetNumber) {
                addr += address.PostalAddress.StreetNumber;
            }
            if (address.PostalAddress.Street) {
                addr += " " + address.PostalAddress.Street;

            }
            if (address.PostalAddress.District) {
                addr += " " + address.PostalAddress.District;
            }
            if (address.PostalAddress.City) {
                addr += " " + address.PostalAddress.City;
            }
            if (address.PostalAddress.Country) {
                addr += ", " + address.PostalAddress.Country;
            }
        }
        return addr;
    };

    RefuelingSrv.prototype.setAsset = function (asset) {
        this.asset = asset;
    };
    RefuelingSrv.prototype.setCompany = function (company) {
        this.company = company;
    };
    RefuelingSrv.prototype.setCardId = function (cardId) {
        this.cardId = cardId;
    };
    RefuelingSrv.prototype.setPeriod = function (startDate, endDate) {
        this.startDate = startDate ? moment(startDate) : moment().startOf('day');
        this.endDate = endDate ? moment(endDate) : moment().startOf('day');
    };


    RefuelingSrv.prototype.datefromMSString = function (a) {
        if (null != a && "" != a) {
            var b = -1 != a.indexOf("+"),
                d = [],
                d = b ? a.split("+") : a.split("-"),
                a = parseInt(d[0].substr(6)),
                d = 0 == d[1].charAt(0) ? parseInt(d[1].substr(1, 4)) : parseInt(d[1].substr(0, 4));
            // d == timezone offset , b: + ==> timezone + else  -

            //moment.utc( Date(a)).offset(b)

            return new Date(b ? a + 36E5 * (d / 100) : a - 36E5 * (d / 100))// localTime +2
        }
        return ""
    };
    RefuelingSrv.prototype.getHeure = function (heure_trans) {
        var a = null != heure_trans ? heure_trans.toString() : "",
            b = a.length;
        if (2 >= b) return "00:00";
        switch (b) {
            case 3:
                return "00:0" +
                    a.substr(0, 1);
            case 4:
                return "00:" + a.substr(0, 2);
            case 5:
                return "0" + a.substr(0, 1) + ":" + a.substr(1, 2);
            case 6:
                return a.substr(0, 2) + ":" + a.substr(2, 2);
            default:
                return "00:00"
        }
    };
    RefuelingSrv.prototype.checkProductFraud=function(product){
        var assetFuelType=this.asset.fuel;
//        console.log(assetFuelType.toUpperCase(),FUEL_TYPES[assetFuelType.toUpperCase()] ,product.toUpperCase() )
        if (FUEL_TYPES[assetFuelType.toUpperCase()].indexOf(product.toUpperCase())>=0){
            return  false;
        }else{
            return  true;
        }
    };
    RefuelingSrv.prototype.getASPDate = function (date_trans, heure_trans) {
        var a = this.datefromMSString(date_trans),
            b = this.getHeure(heure_trans);

        console.log(a,b);
        a.setUTCHours(parseInt(b.substr(0, 2))-1); //TODO get  timeZone  from  total  and update this value , now we just sucstruct  -1  for TZ= +1 as received data !!! ;
        a.setUTCMinutes(parseInt(b.substr(3, 2)));
        return a
    };

    return RefuelingSrv;


});



