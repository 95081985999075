/**
 * Created by Mohjamed Saad on 12/01/2018.
 */


angular.module("app").controller('adminAccountCtrl', function ($scope, $state, companySrv,countrySrv,ngDialog,$stateParams,$filter,$translate,Notification) {

    $scope.$state=$state;
    $scope.accounts = {};
    $scope.search = {};
    $scope.accounts.limit = 5;
    $scope.itemsByPage=5;
    $scope.accounts.CompanySearch = "";
    $scope.countries = countrySrv.getCountryList()
    /*clear  search field of company*/
    /*load companies list*/
    $scope.loadAccounts = function (tableState,ctrl) {
        if ( !$scope.stCtrl && ctrl )
        {
            $scope.stCtrl = ctrl;
        }

        if( !tableState && $scope.stCtrl ) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = $scope.itemsByPage;

        var query = {
            options: {populate:
                    [
                        {
                            path: '_ctry',
                            select: 'name'

                        }
                    ]

            }
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            // This is NOT the page number, but the index of item in the list that you want to use to display the table.
            number =  pagination.number ||  $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            query.options.sortBy = {};

            var predicate = tableState.sort.predicate;
            query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
            }
        }


        if($scope.search){
            if ($scope.search && $scope.search._ctry) {
                requery._ctry= $scope.search._ctry.code;
            }
        }
        query.query=requery
        companySrv.getCompaniesList(query,number,start, function (data) {
                if (data.success) {
                    $scope.accounts = data.result;
                    $scope.accounts.count = data.total_count;
                    tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }

            }, function (data) {
                console.log(data)
            })
    };


    /*delete company*/
    $scope.deleteCompany=function(company){

        ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm.delete.dlg.html',overlay:false ,showClose:false,controller: ['$scope', function($scope) {
            // controller logic
            $scope.title=$translate.instant("ACCOUNT_DELETE_CONRIFM_TITLE",{name:company.name});
            $scope.message=$translate.instant("ACCOUNT_DELETE_CONRIFM_MESSAGE",{name:company.name});
        }] }).then(function () {

            companySrv.deleteCompanyById(company._id,function(data){
                if(data.success) {
                    Notification($translate.instant("ALERT_ACCOUNT_DELETED", {name: company.name}));
                    $scope.loadCompanies({});
                    $scope.tableState.pagination.start = 0;
                }else {
                    console.log(data.error)
                }
            },function(err){

            })
        }, function (reason) {

        });
    };
});
