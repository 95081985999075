/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('countryApiSrv', function ($http,$rootScope) {

    var service={};

    service.getCountryList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/country/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getCountryAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/country/agg?limit='+l+'&page='+p,agg).then(function(data) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    return service;

});
