/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

angular.module("app.services").factory('userSrv',function ($http,$rootScope, $window) {

    var service={};

    service.getUsersList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/user/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getUsersAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/user/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    service.getUserById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/user/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteUserById=function(id,success,error){
        $http.delete($rootScope.config.API_URI+'/user/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {

            error(data)

        };
    };

    service.updateUserById=function(id,user,success,error){
        $http.put($rootScope.config.API_URI+'/user/'+id,user).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(user,success,error){
        $http.post($rootScope.config.API_URI+'/user/',user).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    service.hasPermission = function(obj, assoc){
        var permissions = $rootScope.SESSION.user._role.permissions;
        var validated = false;
        angular.forEach(permissions, function(permission){
            var splited = permission.split("_");
            var association = splited[1];
            var object = splited.slice(2,splited.length).join("_");

            if(obj.toUpperCase() === object && assoc.toUpperCase() === association){
                validated = true;
            }
        });
        return validated;
    };

    service.updateUserParams = function(keys, params){
        var userSession = JSON.parse($window.sessionStorage.SESSION).user;
        angular.forEach(keys, function (key) {
            userSession.params.views_config[key]=params[key];
        });
        service.updateUserById(userSession._id, userSession, function (data) {
            if (data.success) {
                $rootScope.SESSION.user = userSession;
                $window.sessionStorage.SESSION = JSON.stringify($rootScope.SESSION);
                //console.log("user updated ",$rootScope.SESSION.user)
            }

        }, function (error) {
            if (error) {
                console.log(error);
            }
        })
    };

    /*
 Send mail of connection settings
 */
    service.sendMailOfParams = function (userData, success, error) {
        $http.post($rootScope.config.API_URI + '/sendMailOfParams', userData).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success) {
                success(response.data);
            }

        }),function (data) {
            error(data)
        };
    };

    return service;

});
