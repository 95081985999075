/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

angular.module("app.services").factory('driverSrv', function ($http,$rootScope) {

    var service={};

    service.getDriversList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/driver/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getDriversAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/driver/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getDriverById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/driver/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteDriverById=function(id,success,error){
 $http.delete($rootScope.config.API_URI+'/driver/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.updateDriverById=function(id,driver,success,error){
        $http.put($rootScope.config.API_URI+'/driver/'+id,driver).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(driver,success,error){
        $http.post($rootScope.config.API_URI+'/driver/',driver).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getDriverRealTime=function(companyId,success,error){
        $http.post($rootScope.config.API_URI+'/assetdriver/drivers_rt',{compId:companyId}).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data &&  response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }

        },function(data) {
            error(data)
        });
    };
    return service;

});
