/**
 * Created by ngiTeam on 11/02/2017.
 *
 * this file is used to configure geo-server client parameters
 */
/**
 *  Web services parameters configuration
 *  @field geocodingUrl configure geo-coding webservice URL
 *  @field routingUrl  configure routing webService URL
 *  @field reverseGeocodingUrl configure reverse geo-coder webService URL
 */


// var customPoiModuleName="modulePoi1";





var webMapURL = "http://webmap.ngi-maghreb.com";


var gs_config_test = {

    geocodingUrl: "http://localhost:1337/webmap.ngi-maghreb.com/bgis/bnd",
    routingUrl: "http://localhost:1337/webmap.ngi-maghreb.com/bgis/bnd",
    reverseGeocodingUrl: "http://localhost:1337/webmap.ngi-maghreb.com/bgis/bnd",
    getFeatureUrl: "http://localhost:1337/webmap.ngi-maghreb.com/bgis/bnd"
    //customPoiServiceUrl: "http://10.20.160.194:8081/customPoi"

}

var gs_config_prod = {


    geocodingUrl: "http://webmap.ngi-maghreb.com/wms",
    routingUrl: "http://webmap.ngi-maghreb.com/wms",
    reverseGeocodingUrl: "http://webmap.ngi-maghreb.com/wms",
    getFeatureUrl: "http://webmap.ngi-maghreb.com/wms"
    // customPoiServiceUrl: "http://webmap.ngi-maghreb.com/modules/PoiModule1/data/poiList.json"

}


var currentConfig = gs_config_test;
/**
 * Constant represent's  the name of the Benomad geoServer
 * @type {string}
 */

var BENOMAD_GEO_SERVER_CONSTANT = "benomad";


/**
 * define the geoserver to be used for the current configuration
 * @type {string}
 */
var current_geoserver = BENOMAD_GEO_SERVER_CONSTANT;

var availableTransportType = {
    BICYCLE: {
        label: "transportType.bicycle",
        value: "BICYCLE"
    },
    PEDESTRIAN: {
        label: "transportType.pedestrain",
        value: "PEDESTRIAN"
    },
    CAR: {
        label: "transportType.car",
        value: "CAR"
    }
}

/**
 * Contains all request constant parameters for webService    query
 * @type {{geoserver: string  , version: string, format: string}}
 */
var geoServerConstantParam = {

    geoserver: "benomad",
    version: "1.0.0",
    format: "json"

}


var PonderationType = {

    ALL: "ALL",
    CAL: "CAL",
    ETA: "ETA"

}


var transportTypeConst = {
    PEDESTRIAN: "PEDESTRIAN",
    BICYCLE: "BICYCLE",
    MOTORCYCLE: "MOTORCYCLE",
    TAXI: "TAXI",
    PUBLIC_BUS: "PUBLIC_BUS",
    EMERGENCY: "EMERGENCY",
    DELIVERY_TRUCK: "DELIVERY_TRUCK",
    TRUCK: "TRUCK",
    CAR: 'CAR'

}


var reversGeoCodingConst = {
    options: {
        SKIP_EMPTY_STREETNAME: "SKIP_EMPTY_STREETNAME",
        ROAD_FEATURE: "ROAD_FEATURE",
        SEGMENTID: "SEGMENTID",
        POLYLINE: "POLYLINE"
    },
    transportType: {
        PEDESTRIAN: "PEDESTRIAN",
        BICYCLE: "BICYCLE",
        MOTORCYCLE: "MOTORCYCLE",
        TAXI: "TAXI",
        PUBLIC_BUS: "PUBLIC_BUS",
        EMERGENCY: "EMERGENCY",
        DELIVERY_TRUCK: "DELIVERY_TRUCK",
        TRUCK: "TRUCK"

    }

}


var routingConst = {
    criterias: {
        FASTER: "FASTER",
        SHORTEST: "SHORTEST",
        AVOID_TOLLS: "AVOID_TOLLS",
        AVOID_MOTORWAYS: "AVOID_MOTORWAYS",
        AVOID_FERRIES: "AVOID_FERRIES",
        AVOID_UNPAVED: "AVOID_UNPAVED",
        AVOID_CROSSING_BORDER: "AVOID_CROSSING_BORDER",
        CARPOOL: "CARPOOL"
    },

    mode: {
        MODE_VIAS: "MODE_VIAS",
        MODE_1_TO_N: "MODE_1_TO_N",
        MODE_N_TO_1: "MODE_N_TO_1",
        MODE_N_TO_N: "MODE_N_TO_N",
        MODE_ISOCHRONE: "MODE_ISOCHRONE"

    },

    option: {
        ROUTESHEET: "ROUTESHEET",
        POLYLINE: "POLYLINE",
        DETAILED_POLYLINE: "DETAILED_POLYLINE",
        POLYLINE_INDEX: "POLYLINE_INDEX",
        SEGMENTIDS: "SEGMENTIDS",
        JUNCTION_NODES: "JUNCTION_NODES",
        TRAFFIC: "TRAFFIC",
        TRAFFIC_PATTERNS: "TRAFFIC_PATTERNS",
        OPTIMIZED_TRIP: "OPTIMIZED_TRIP",
        OPTIMIZED_TRIP_ROUND: "OPTIMIZED_TRIP_ROUND",
        OPTIMIZED_TRIP_UNDEFSTOP: "OPTIMIZED_TRIP_UNDEFSTOP",
        TOLL_COST: "TOLL_COST",
        TAX_COST: "TAX_COST",
        ECO_TAX: "ECO_TAX",
        SORTBY_USED_ORDER: "SORTBY_USED_ORDER",
        REVGEO_STRICT_DISABLE: "REVGEO_STRICT_DISABLE"

    },


    pr: {

        RoadAttribute: {

            PREFERRED_UNDEFINED: "PREFERRED_UNDEFINED",
            PREFERRED_STAA: "PREFERRED_STAA",
            PREFERRED_NATIONAL_ACCESS: "PREFERRED_NATIONAL_ACCESS",
            PREFERRED_TRUCK_ROUTE: "PREFERRED_TRUCK_ROUTE",
            PREFERRED_TRUCK_BYPASS: "PREFERRED_TRUCK_BYPASS",
            PREFERRED_TRUCK_LOCAL: "PREFERRED_TRUCK_LOCAL",
            PREFERRED_HAZMAT_NRHM: "PREFERRED_HAZMAT_NRHM",
            PREFERRED_HAZMAT_EXPLOSIVES: "PREFERRED_HAZMAT_EXPLOSIVES",
            PREFERRED_HAZMAT_PIH: "PREFERRED_HAZMAT_PIH",
            PREFERRED_HAZMAT_MEDICAL: "PREFERRED_HAZMAT_MEDICAL",
            PREFERRED_HAZMAT_RADIOACTIVE: "PREFERRED_HAZMAT_RADIOACTIVE",
            PREFERRED_HAZMAT_GENERAL: "PREFERRED_HAZMAT_GENERAL",
            PREFERRED_TOURIST_SCENIC: "PREFERRED_TOURIST_SCENIC",
            PREFERRED_TOURIST_HISTORIC: "PREFERRED_TOURIST_HISTORIC",
            PREFERRED_B_DOUBLES: "PREFERRED_B_DOUBLES",
            PREFERRED_TOURIST_NATIONAL: "PREFERRED_TOURIST_NATIONAL",
            PREFERRED_TOURIST_REGIONAL: "PREFERRED_TOURIST_REGIONAL",
            PREFERRED_TOURIST_NATURE: "PREFERRED_TOURIST_NATURE",
            PREFERRED_WEIGHT_DEPENDENT: "PREFERRED_WEIGHT_DEPENDENT",
            TOLL_ROAD: "TOLL_ROAD",
            BUILT_UP: "BUILT_UP",
            TUNNEL: "TUNNEL",
            BRIDGE: "BRIDGE",
            TAX: "TAX",
            CARPOOL: "CARPOOL"

        }

    }

}


rb = {}

sp = {
    factor: "factor",
    level: "level",
    roadType: {
        ALL: "ALL",
        DEFAULT: "DEFAULT",
        FERRY: "FERRY",
        MOTORWAY: "MOTORWAY",
        PEDESTRIAN: "PEDESTRIAN",
        ROUNDABOUNT: "ROUNDABOUNT",
        SLIPROAD: "SLIPROAD"
    }

}
vf = {
    NONE: "NONE",
    ALL: "ALL",
    EXPLOSIVE: "EXPLOSIVE",
    WATER: "WATER",
    US_CORROSIVE: "US_CORROSIVE",
    US_EXPLOSIVES: "US_EXPLOSIVES",
    US_FLAM: "US_FLAM",
    US_FLAM_SOL: "US_FLAM_SOL",
    US_GAS: "US_GAS",
    US_ORGANIC: "US_ORGANIC",
    US_OTHER: "US_OTHER",
    US_PIH: "US_PIH",
    US_POISON: "US_POISON",
    US_RADIO_ACTIVE: "US_RADIO_ACTIVE",
    TUNNEL_CAT_B: "TUNNEL_CAT_B",
    TUNNEL_CAT_B1000C: "TUNNEL_CAT_B1000C",
    TUNNEL_CAT_BD: "TUNNEL_CAT_BD",
    TUNNEL_CAT_BE: "TUNNEL_CAT_BE",
    TUNNEL_CAT_C: "TUNNEL_CAT_C",
    TUNNEL_CAT_C5000D: "TUNNEL_CAT_C5000D",
    TUNNEL_CAT_CD: "TUNNEL_CAT_CD",
    TUNNEL_CAT_CE: "TUNNEL_CAT_CE",
    TUNNEL_CAT_D: "TUNNEL_CAT_D",
    TUNNEL_CAT_DE: "TUNNEL_CAT_DE",
    TUNNEL_CAT_E: "TUNNEL_CAT_E",
    UNDEFINED: "UNDEFINED",
    MOTORCYCLE: "MOTORCYCLE",
    AUTO: "AUTO",
    TRUCK: "TRUCK",
    MINIBUS: "MINIBUS",
    BUS: "BUS",
    SIDECAR: "SIDECAR",
    TRICYCLE: "TRICYCLE",
    DLV_TRUCK: "DLV_TRUCK",
    SNOWMOBILE: "SNOWMOBILE",
    PICK_UP: "PICK_UP",
    TRACTOR: "TRACTOR",
    EURO1: "EURO1",
    EURO2: "EURO2",
    EURO3: "EURO3",
    EURO4: "EURO4",
    EURO5: "EURO5",
    EURO6: "EURO6",
    EURO_EEV: "EURO_EEV",
    ELECTRIC: "ELECTRIC",
    ADR: "ADR",
    NONE: "NONE",
    CAT_B: "CAT_B",
    CAT_C: "CAT_C",
    CAT_D: "CAT_D",
    CAT_E: "CAT_E"

}

var availableOptions = routingConst.option;
var availableCriterias = routingConst.criterias;
