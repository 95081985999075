angular.module('app.widgets').directive('graphWdg', function ($rootScope, $translate, $timeout, deviceMsgSrv) {
    return{
        templateUrl: "app/commons/widgets/graph/graph.tpl.html",
        scope : {
            item : "=",
            options : "="

        },
        link: function (scope, elem, attr) {
            var onChange=false;
            scope.categories = [];
            scope.data = [];
            var id = "graph_"+scope.item._id;

                function getMessageValue(message, input) {
                    var val = "";
                    if(input)
                    var parts = input.split('.');


                    if(message && parts && parts.length === 2){
                        val = message[parts[1]];
                    }else {
                        if(message && message.io && parts)
                        val = message.io[parts[2]];
                    }
                    return val;

                }



            scope.histoWidget = function () {

                if (scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.period) {
                    switch (scope.item.extra.histo.period) {
                        case "TODAY" :
                            scope.startDt = moment().clone().startOf('day');
                            scope.endDt = moment().clone();
                            break;
                        case "YESTERDAY":
                            scope.startDt = moment().clone().subtract(1, "day").startOf("day");
                            scope.endDt = moment().clone().subtract(1, "day").endOf("day");
                            break;
                        case "THIS_WEEK":
                            scope.startDt = moment().clone().startOf("week");
                            scope.endDt = moment().clone();
                            break;
                        case "LAST_WEEK":
                            scope.startDt = moment().clone().subtract(1, 'weeks').startOf("week");
                            scope.endDt = moment().clone().subtract(1, 'weeks').endOf("week");
                            break;
                        case "SEVEN_DAYS":
                            scope.startDt = moment().clone().subtract(7, 'days').startOf("day");
                            scope.endDt = moment().clone();
                            break;
                        case "THIS_MONTH":
                            scope.startDt = moment().clone().startOf("month");
                            scope.endDt = moment().clone();
                            break;
                        case "LAST_MONTH":
                            scope.startDt = moment().clone().subtract(1, "months").startOf("month");
                            scope.endDt = moment().clone().subtract(1, "months").endOf("month");
                            break;
                        case "THIRTEEN_DAYS":
                            scope.startDt = moment().clone().subtract(30, "days").startOf("day");
                            scope.endDt = moment().clone();
                            break;
                        case "THIS_YEAR":
                            scope.startDt = moment().clone().startOf("year");
                            scope.endDt = moment().clone();
                            break;
                        default:
                            scope.startDt = moment(scope.item.extra.histo.startDt).clone();
                            scope.endDt = moment(scope.item.extra.histo.endDt).clone();
                            break;
                    }
                }
if(scope.item && scope.item._assets && scope.item._assets.length>0 &&  scope.startDt && scope.endDt){
                var query = {
                    query: [
                        {
                            "$match": {
                                "_asset": scope.item._assets[0]._id,
                                gps_dt: {
                                    $gte: scope.startDt.clone(),
                                    $lte: scope.endDt.clone()
                                }
                            }
                        }
                    ]
                };


                deviceMsgSrv.getDeviceMsgAgg(query, 10000, 0, function (response) {
                    scope.data = [];
                    angular.forEach(response, function (message) {
                        var val = Number(getMessageValue(message, scope.item.input));
                        if (val) {
                            var row = [Date.parse(moment(message.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate()), val];
                            scope.data.push(row);
                        }


                    });

                        angular.element(elem).ready(function () {

                            scope.chart = Highcharts.chart(id, {
                                credits: {
                                    enabled: false
                                },
                                chart: {
                                    type: scope.item.extra.graphType,
                                    backgroundColor: 'rgba(255, 255, 255, 0)',
                                    zoomType: 'x'
                                },
                                title: {
                                    text: ''
                                },
                                subtitle: {
                                    text: ''
                                },
                                tooltip: {
                                    headerFormat: '<b>{series.name}</b><br>',
                                    pointFormat: '{point.x:%e. %b}: {point.y:.2f} m'
                                },
                                plotOptions: {
                                    spline: {
                                        marker: {
                                            enabled: true
                                        }
                                    }
                                },
                                xAxis: {
                                    type: 'datetime',
                                    dateTimeLabelFormats: {
                                        month: '%e. %b',
                                        year: '%b'
                                    },
                                    title: {
                                        text: 'Date'
                                    }
                                },
                                yAxis: {
                                    title: {
                                        text: scope.item.extra.yAxis
                                    },
                                    labels: {
                                        formatter: function () {
                                            return this.value
                                        }
                                    }
                                },
                                tooltip: {
                                    //split: true,
                                    valueSuffix: scope.item.extra.unit
                                },
                                plotOptions: {
                                    area: {
                                        stacking: 'normal',
                                        lineColor: '#666666',
                                        lineWidth: 1,
                                        marker: {
                                            lineWidth: 1,
                                            lineColor: '#666666'
                                        }
                                    }
                                },
                                series: [{
                                    name: $translate.instant(scope.item.type),
                                    data: scope.data
                                }]
                            });
                        });


                }, function (err) {
                    console.log(err);
                })
            }
                };

                if(scope.item.type === "GRAPH"){
                    angular.element(document).ready(function () {

                    scope.chart = Highcharts.chart( id, {
                        credits : {
                            enabled : false
                        },
                        chart: {
                            type:  scope.item.extra.graphType,
                            backgroundColor:'rgba(255, 255, 255, 0)'
                        },
                        title: {
                            text: ''
                        },
                        subtitle: {
                            text: ''
                        },
                        xAxis: {
                            categories: scope.categories,
                            tickmarkPlacement: 'on',
                            title: {
                                text: scope.item.extra.xAxis
                            }
                        },
                        yAxis: {
                            title: {
                                text: scope.item.extra.yAxis
                            },
                            labels: {
                                formatter: function () {
                                    return this.value
                                }
                            }
                        },
                        tooltip: {
                            //split: true,
                            valueSuffix: scope.item.extra.unit
                        },
                        plotOptions: {
                            area: {
                                stacking: 'normal',
                                lineColor: '#666666',
                                lineWidth: 1,
                                marker: {
                                    lineWidth: 1,
                                    lineColor: '#666666'
                                }
                            }
                        },
                        series: [{
                            name: $translate.instant(scope.item.type),
                            data: scope.data
                        }]
                    });
                    });


                    angular.forEach(scope.item._assets, function (asset) {
                        var value = getValue(asset, scope.item.input);
                        if(value !== ""){
                            scope.categories.push(asset.name);
                            scope.data.push(Number(parseFloat(value).toFixed(2)));
                        }
                    });
                }else{
                    //Graph historic
                    scope.histoWidget();


                }


                $rootScope.$on('widgetResized', function (event, resizedId) {
                    $timeout(function () {
                        if(id === resizedId){
                            console.log(resizedId);

                            elem.parent().css({
                                overflow : "hidden"
                            });
                            //scope.chart.xAxis[0].setExtremes(-10, 150);
                            scope.chart.setSize(elem.parent().width(), elem.parent().height())
                        }
                    }, 500)
                });


            function getValue(device, input) {
                var val = "";

                if(input)
                    var parts = input.split('.');

                if(device && device.rt && parts && parts.length === 2){
                    val = device.rt[parts[1]];
                }else {
                    if(device && device.rt && device.rt.io && parts)
                        val = device.rt.io[parts[2]];
                }
                return val;

            }


            /**
             * Function to be called from outside directive to update widget
             */
            angular.extend(scope.options,{onChange:function(cfg){
                onChange=true;

                //TODO Implement on configuration changed
                    scope.categories = [];
                    scope.data = [];

                    if(scope.item.type === "GRAPH"){
                        angular.element(document).ready(function () {

                            scope.chart = Highcharts.chart( id, {
                                credits : {
                                    enabled : false
                                },
                                chart: {
                                    type:  scope.item.extra.graphType,
                                    backgroundColor:'rgba(255, 255, 255, 0)'
                                },
                                title: {
                                    text: ''
                                },
                                subtitle: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: scope.categories,
                                    tickmarkPlacement: 'on',
                                    title: {
                                        text: scope.item.extra.xAxis
                                    }
                                },
                                yAxis: {
                                    title: {
                                        text: scope.item.extra.yAxis
                                    },
                                    labels: {
                                        formatter: function () {
                                            return this.value
                                        }
                                    }
                                },
                                tooltip: {
                                    //split: true,
                                    valueSuffix: scope.item.extra.unit
                                },
                               plotOptions: {
                                    area: {
                                        stacking: 'normal',
                                        lineColor: '#666666',
                                        lineWidth: 1,
                                        marker: {
                                            lineWidth: 1,
                                            lineColor: '#666666'
                                        }
                                    }
                                },
                                series: [{
                                    name: (scope.item && scope.item.type ? $translate.instant(scope.item.type):"-"),
                                    data: (scope.data && scope.data.length>0?scope.data:[])
                                }]
                            });
                        });


                        angular.forEach(scope.item._assets, function (asset) {
                            var value = getValue(asset, scope.item.input);
                            if(value !== ""){
                                scope.categories.push(asset.name);
                                scope.data.push(Number(parseFloat(value).toFixed(2)));
                            }
                        });
                    }else{
                        //Graph historic
                         scope.histoWidget();


                    }
                }});
            /**
             * listen  for  newMsg and  update value
             * @type {function(): void|*|function()}
             */
            var listener =$rootScope.$on("newMsg",function(event,args){

                if(args && args._id && onChange==false){
                        if (scope.categories.indexOf(args.name) > -1) {
                            var value = "";
                            value = getValue(args, scope.item.input);
                            if (value !== "" && typeof value == 'number') {

                                scope.data[(args && args.name ? scope.categories.indexOf(args.name) : "-")] = Number(parseFloat(value).toFixed(2));
                                // $timeout(function () {//

                                    angular.element(document).ready(function () {
                                        scope.chart.yAxis[0].removePlotLine('plotOptions')
                                        // scope.chart.xAxis[0].addPlotLine()
                                        // scope.chart.yAxis[0].setExtremes(value, value)
                                       // console.log(' scope.chart.series[0]', scope.data + " / " + scope.item.type)

                                        scope.chart.series[0].update({name: $translate.instant((scope.item ? scope.item.type : "-"))}, false);
                                        $timeout(function () {

                                        scope.chart.series[0].setData((scope.data && scope.data.length > 0 ? scope.data :[]),false);
                                        scope.chart.addSeries();//
                                        $timeout(function () {
                                            scope.chart.redraw();


                                        })
                                             })

                                    });
                            }
                         }

                }else{
                    // console.log('onchange')
                    $timeout(function () {
                        if(args && args._id){

                            if (scope.categories.indexOf(args.name) > -1) {
                                var value="";
                                value = getValue(args, scope.item.input);
                                if (value !=="" && typeof value=='number') {

                                    scope.data[(args && args.name ?scope.categories.indexOf(args.name):"-")] = Number(parseFloat(value).toFixed(2));
                                    angular.element(document).ready(function () {
                                        scope.chart.yAxis[0].removePlotLine('plotLine')
                                        // scope.chart.xAxis[0].addPlotLine()
                                        // scope.chart.yAxis[0].setExtremes(value, value)
                                      //  console.log(' scope.chart.series[0]', scope.data.length+" / "+scope.item.type)

                                        scope.chart.series[0].update({name: $translate.instant((scope.item?scope.item.type:"-"))}, false);

                                        scope.chart.series[0].setData((scope.data && scope.data.length>0 ?scope.data:0), false);

                                        $timeout(function () {
                                             scope.chart.redraw();


                                        },200)


                                    });
                                }
                            }
                        }

                    },1000)
                     onChange=false;

                }
            });
            /**
             * remove listener on destroy
             */
            scope.$on('$destroy', function() {
                listener();
            });


        }
    }
});