/**
 * Project: tc-fleet
 * Created by NGI team 31/07/2017.
 */

angular.module("app.services").factory('claimSrv', function ($http,$rootScope) {

    var service={};

    service.getClaimsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/claim/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getClaimsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/claim/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getClaimById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/claim/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteClaimById=function(id,success,error){
        $http.delete($rootScope.config.API_URI+'/claim/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.updateClaimById=function(id,driver,success,error){
        $http.put($rootScope.config.API_URI+'/claim/'+id,driver).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(driver,success,error){
        $http.post($rootScope.config.API_URI+'/claim/',driver).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    return service;

});
