angular.module('app').controller('alertConfigAddCtrl', function ($scope, $rootScope,$timeout,assetSrv,alertConfigSrv,enumsSrv,$filter,$translate,Notification) {
    enumsSrv.getEnums(function (data) {
        if(data.success && data.result){
            $scope.ENUMS=data.result;
            $scope.CHECKERS=[];
            Object.keys($scope.ENUMS.ALERT_FUNCTION_MODEL).forEach(function(key){
                $scope.CHECKERS.push($scope.ENUMS.ALERT_FUNCTION_MODEL[key]);
            });
        } else {
            console.error(data.error)
        }
    }, function(err){
        console.error(err);
    });
    $scope.searchAsset=function($select){
        assetSrv.getAssetsList({query:{name:{'$regex' :$select.search, '$options' : 'i'}}},10,0,function(res) {
            if(res && res.success){
                $scope.assets=res.result;
            }
        },function(err){
            console.log(err);
        })
    };
    $scope.alertConfg={enabled:true,config:[],actions:[{type:'SOCKET_IO',add:'false',update:'false'},{type:'EMAIL',add:'false',update:'false'}]};
    $scope.addCondition=function(){
        $scope.alertConfg.config.push({
        })
    };
    $scope. deleteConditionCfg=function(index){
        $scope.alertConfg.config.splice(index, 1);
    };
    $scope.updateChecker=function(config){
        if(config === undefined && config.data.field === undefined) {return}
        var filteredField = $filter('filter')($scope.CHECKERS, $scope.filterCheckerByFiledType(config.data.field.src),true);
        setTimeout(function(){
            if(filteredField && filteredField.length>0){
                if(filteredField.indexOf(config.checker)<=0){
                    config.checker=filteredField[0];
                    if(config.data.field==="UNDEFINED")
                        config.data={field:config.data.field,type:"SENSOR"};

                    else
                        config.data={field:config.data.field};
                }
            }else{
                console.log("NOT FOUND ", config ,filteredField[0] )
            }
            $scope.$apply();
        });
        // }
    };
    $scope.filterCheckerByFiledType = function (field) {
        return function (item) {
            if($scope.fields === undefined) {return}
            if($scope.fields && $scope.fields.length>0) {
                var valueType = $filter('filter')($scope.fields, {src: field}, true)[0].value_type;
                if (valueType === 'number') {
                    return (item.indexOf("GREATER") >= 0 || item.indexOf("LESS") >= 0 || item.indexOf("RANGE") >= 0)
                } else if (valueType === 'date') {
                    return (item.indexOf("TIME_INTERVAL") >= 0 )
                } else if (valueType === 'position') {
                    return ((item.indexOf("POLYGON") >= 0) || (item.indexOf("POI")>=0) || (item.indexOf("CORRIDOR")>=0))
                } else {
                    return true;
                }
            }else{
                return false;
            }
        }

    };
    $scope.addActionEmail=function(action,email){
        if(!action.data)
            action.data={}
        if(action.data && action.data.email)
            $scope.mails = action.data.email.split(";")
        else {
            action.data={email:""}
            $scope.mails = [];
        }
        if(validateEmail(email)) {

            if($scope.mails.indexOf(email)>=0){
                Notification.error($translate.instant("EMAIL_ALREADY_EXIST"));
            }else{
                $scope.mails.push(email);
                $scope.email = "";
                action.data.email = $scope.mails.join(';')
            }
        }else{
            Notification.error($translate.instant("NO_RECIPIENT"));
        }

    };
    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    $scope.removeActionEmail = function(action,mail){
        var index = $scope.mails.indexOf(mail);
        if (index > -1) {
            $scope.mails.splice(index, 1);
            action.data.email = $scope.mails.join(';')
        }
    };
    $scope.saveAlert=function(){
        angular.forEach($scope.alertConfg.config,function(val){
            //if(val.data.field.src !== 'loc'){
                val.data.field = val.data.field.src
            //}
            // else {
            //     console.log("sensor location don't do anything")
            // }
        });
         alertConfigSrv.add($scope.alertConfg,function(res){
             if(res && res.success){
                 Notification.success($translate.instant("ALERT_CONFIG_CREATED",{name: $scope.alertConfg.name}));
                 $scope.closeThisDialog();
             }
             else{
                 console.log('Alert error')
             }
             } ,function(err){
             console.log('error')
            })

    };
    $scope.prepareFields = function () {
        $scope.fields = [];
        if ($scope.ENUMS) {
            $scope.fields = $scope.ENUMS.ASSET_PROPERTIES;
        }
    };
    // $scope.prepareFields = function (item) {
    //
    //     console.log('asseeeeet', item);
    //     $scope.fields = []
    //
    //     if (item.rt) {
    //
    //         Object.keys(item.rt).forEach(function (key) {
    //             if (key == 'loc') {
    //                 $scope.fields.push({
    //                     name: "Location",
    //                     src: '' + key,
    //                     value: {lat: item.rt.loc.coordinates[1], lng: item.rt.loc.coordinates[0], zoom: 19},
    //                     value_type: "position",
    //                     asset: item._id,
    //                     asset_name: item.name,
    //                     gps_dt: item.rt.gps_dt,
    //                     srv_dt: item.rt.srv_dt
    //                 })
    //             } else if (item.rt[key].coordinates && item.rt[key].coordinates.length > 0) {
    //                 $scope.fields.push({
    //                     name: "Location",
    //                     src: '' + key,
    //                     value: {lat: item.rt.loc.coordinates[1], lng: item.rt.loc.coordinates[0], zoom: 19},
    //                     value_type: "position"
    //                 })
    //             }
    //
    //             else if (key == 'odo') {
    //                 $scope.fields.push({
    //                     name: "Odometer",
    //                     src: '' + key,
    //                     value: item.rt.odo,
    //                     value_type: 'number',
    //                     asset: item._id,
    //                     asset_name: item.name,
    //                     gps_dt: item.rt.gps_dt,
    //                     srv_dt: item.rt.srv_dt
    //                 })
    //             }
    //
    //             else if (key == 'io') {
    //                 Object.keys(item.rt.io).forEach(function (io_key) {
    //                     if (io_key != 'odo') {
    //                         var value = item.rt.io[io_key]
    //                         var value_type = typeof(value)
    //                         $scope.fields.push({
    //                             name: io_key.toUpperCase(),
    //                             src: 'io.' + io_key,
    //                             value: value,
    //                             value_type: value_type,
    //                             asset: item._id,
    //                             asset_name: item.name,
    //                             gps_dt: item.rt.gps_dt,
    //                             srv_dt: item.rt.srv_dt
    //                         })
    //                     }
    //
    //                 })
    //             }
    //             else {
    //
    //                 var value = item.rt[key]
    //                 var value_type = typeof(value)
    //                 console.log(value_type)
    //                 if (typeof (value.getMonth) === 'function') {
    //                     value_type = "date"
    //                 }
    //
    //
    //                 $scope.fields.push({
    //                     name: key.toUpperCase(),
    //                     src: '' + key,
    //                     value: value,
    //                     value_type: value_type,
    //                     asset: item._id,
    //                     asset_name: item.name,
    //                     gps_dt: item.rt.gps_dt,
    //                     srv_dt: item.rt.srv_dt
    //                 })
    //
    //             }
    //         })
    //     }
    // }

    });