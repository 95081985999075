/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

angular.module("app.services").factory('deviceMessageMetricSrv', function ($http,$rootScope) {

    var service={};

    service.getMonthlyMetricList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/device_msg_monthly/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getMonthlyMetricAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/device_msg_monthly/agg?limit='+l+'&page='+p,agg).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.getDailyMetricList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/device_msg_daily/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getDailyMetricAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/device_msg_daily/agg?limit='+l+'&page='+p,agg).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    service.getHourlyMetricList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/device_msg_hourly/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getHourlyMetricAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/device_msg_hourly/agg?limit='+l+'&page='+p,agg).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };



    service.getAssetMetricList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/metric_asset/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getAssetMetricAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/metric_asset/agg?limit='+l+'&page='+p,agg).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    return service;

});
