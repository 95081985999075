angular.module('app').controller('adminDashboardCtrl', function ($rootScope, $scope,dashboardSrv,ngDialog,Notification,$translate) {

    $scope.dashboard = {};
    $scope.itemsByPage = 5;
    $scope.search = {};

    $scope.loadDashboards = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();

            return;
        }
        var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = $scope.itemsByPage;
        var query = {


        };

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            query.options.sortBy = {};

            var predicate = tableState.sort.predicate;
            query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }

        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery.name = {$regex: tableState.search.predicateObject.name, $options: "i"};
            }
            if (tableState.search.predicateObject.description) {
                requery.description = {$regex: tableState.search.predicateObject.description, $options: "i"};
            }

        }
        query.query = requery;

        dashboardSrv.getDashboardList(query, number, start, function (data) {
            if (data.success) {
                $scope.dashboards = data.result;
                $scope.dashboardCount = data.total_count;
                tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                tableState.pagination.totalItemCount = data.total_count;
                $scope.tableState = tableState;
            } else {
                console.error(data.error);
            }
        }, function (data) {
            console.log(data);
        });
    };
    /* delete asset*/
    $scope.remove = function (dashboard) {
        ngDialog.openConfirm({template: 'app/commons/dialogs/confirm/confirm.dlg.html', overlay: true, showClose: false, controller: ['$scope', function ($scope) {
                // controller logic
                $scope.title = $translate.instant("DASHBOARD_DELETE_CONFIRM_TITLE", {name: dashboard.name});
                $scope.message = $translate.instant("DASHBOARD_DELETE_CONFIRM_MESSAGE", {name: dashboard.name});
            }]}).then(function () {
            dashboardSrv.deleteDashboardById(dashboard._id, function (data) {
                if (data.success) {
                    Notification($translate.instant("ALERT_DASHBOARD_DELETED", {name: dashboard.name}));
                    $scope.loadDashboards();
                    $scope.tableState.pagination.start = 0;
                } else {
                    console.log(data.error);
                }
            }, function (err) {

            });
        }, function (reason) {

        });
    };
    $scope.shareDash = function (dashboard) {
        $scope.sharedDashboard = dashboard;
        var dialog = ngDialog.open({
            template : "app/commons/dialogs/dashboard/share.dashboard.tpl.html",
            controller: "shareDashboardDlg",
            scope: $scope
        });
    };
});