angular.module('app.widgets').directive('mapWdg', function ($rootScope,$state, $timeout, deviceMsgSrv) {
    return{
        templateUrl: "app/commons/widgets/map/map.tpl.html",
        scope : {
            item : "=",
            options : "="
        },
        link: function (scope, elem, attr) {

            angular.element(document).ready(function () {

                var NGI = L.tileLayer.wms($rootScope.config.LBS_WMS, {
                    maxZoom: 19,
                    name: "NGI",
                    attribution: 'Powered by iaxeed',
                    icon: 'app/commons/directives/map/images/ngiMap.png'
                });
                var googleRoadmap = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                    maxZoom: 19,
                    name: "GOOGLE_NOR",
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    icon: 'app/commons/directives/map/images/gNorm.png'

                });
                var googleTerrain = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                    maxZoom: 19,
                    name: "GOOGLE_SAT",
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    icon: 'app/commons/directives/map/images/gSat.png'

                });
                var googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
                    maxZoom: 19,
                    name: "GOOGLE_HYB",
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    icon: 'app/commons/directives/map/images/gHybr.png'

                });
                var osm= L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    name: "OPEN_STREET",
                    icon: 'app/commons/directives/map/images/osmMap.png'

                });


                var id = 'map_'+scope.item._id;
              //  $timeout(function(){

                    scope.map = L.map(id, {
                        center: [36.843602, 10.206953],
                        minZoom: 4,
                        maxZoom: 14,
                        layers : [NGI]

                        // zoomControl: false,

                    });
             //   },200)

                scope.markers = L.markerClusterGroup();

                var baseMaps = {
                    "NGI": NGI,
                    "Google Roadmap": googleRoadmap,
                    "Google Terrain": googleTerrain,
                    "Google Hybrid": googleHybrid,
                    "Open Street Map": osm
                };

               // scope.zoomHome = L.Control.zoomHome({position: 'topleft', zoomHomeIcon: "globe"});
               // scope.zoomSliderControl = new L.control.zoomslider({position: 'topleft'});
               // scope.zoomSliderControl.addTo(scope.map);


                L.control.layers(baseMaps, null,{position: 'topleft'}).addTo(scope.map);

                function getValue(device, input) {
                    var val = "";
                    var parts = input.split('.');

                    if(parts.length === 2){
                        val = device.rt[parts[1]];
                    }else {
                        val = device.rt.io[parts[2]];
                    }
                    return val;

                }
                function getMessageValue(message, input) {
                    var val = "";
                    var parts = input.split('.');

                    if(parts.length === 2){
                        val = message[parts[1]];
                    }else {
                        val = message.io[parts[2]];
                    }
                    return val;

                }

                if(scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.period){
                    switch (scope.item.extra.histo.period){
                        case "TODAY" :
                            scope.startDt = moment().clone().startOf('day');
                            scope.endDt = moment().clone();
                            break;
                        case "YESTERDAY":
                            scope.startDt = moment().clone().subtract(1, "day").startOf("day");
                            scope.endDt = moment().clone().subtract(1, "day").endOf("day");
                            break;
                        case "THIS_WEEK":
                            scope.startDt = moment().clone().startOf("week");
                            scope.endDt = moment().clone();
                            break;
                        case "LAST_WEEK":
                            scope.startDt = moment().clone().subtract(1, 'weeks').startOf("week");
                            scope.endDt = moment().clone().subtract(1, 'weeks').endOf("week");
                            break;
                        case "SEVEN_DAYS":
                            scope.startDt = moment().clone().subtract(7, 'days').startOf("day");
                            scope.endDt = moment().clone();
                            break;
                        case "THIS_MONTH":
                            scope.startDt = moment().clone().startOf("month");
                            scope.endDt = moment().clone();
                            break;
                        case "LAST_MONTH":
                            scope.startDt = moment().clone().subtract(1, "months").startOf("month");
                            scope.endDt = moment().clone().subtract(1, "months").endOf("month");
                            break;
                        case "THIRTEEN_DAYS":
                            scope.startDt = moment().clone().subtract(30, "days").startOf("day");
                            scope.endDt = moment().clone();
                            break;
                        case "THIS_YEAR":
                            scope.startDt = moment().clone().startOf("year");
                            scope.endDt = moment().clone();
                            break;
                        default:
                            scope.startDt = moment(scope.item.extra.histo.startDt).clone();
                            scope.endDt = moment(scope.item.extra.histo.endDt).clone();
                            break;
                    }
                }


                scope.getDeviceMessages = function (asset) {

                    if(scope.item && scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.period){
                        switch (scope.item.extra.histo.period){
                            case "TODAY" :
                                scope.startDt = moment().clone().startOf('day');
                                scope.endDt = moment().clone();
                                break;
                            case "YESTERDAY":
                                scope.startDt = moment().clone().subtract(1, "day").startOf("day");
                                scope.endDt = moment().clone().subtract(1, "day").endOf("day");
                                break;
                            case "THIS_WEEK":
                                scope.startDt = moment().clone().startOf("week");
                                scope.endDt = moment().clone();
                                break;
                            case "LAST_WEEK":
                                scope.startDt = moment().clone().subtract(1, 'weeks').startOf("week");
                                scope.endDt = moment().clone().subtract(1, 'weeks').endOf("week");
                                break;
                            case "SEVEN_DAYS":
                                scope.startDt = moment().clone().subtract(7, 'days').startOf("day");
                                scope.endDt = moment().clone();
                                break;
                            case "THIS_MONTH":
                                scope.startDt = moment().clone().startOf("month");
                                scope.endDt = moment().clone();
                                break;
                            case "LAST_MONTH":
                                scope.startDt = moment().clone().subtract(1, "months").startOf("month");
                                scope.endDt = moment().clone().subtract(1, "months").endOf("month");
                                break;
                            case "THIRTEEN_DAYS":
                                scope.startDt = moment().clone().subtract(30, "days").startOf("day");
                                scope.endDt = moment().clone();
                                break;
                            case "THIS_YEAR":
                                scope.startDt = moment().clone().startOf("year");
                                scope.endDt = moment().clone();
                                break;
                            default:
                                scope.startDt = moment(scope.item.extra.histo.startDt).clone();
                                scope.endDt = moment(scope.item.extra.histo.endDt).clone();
                                break;
                        }
                    }


                  //  console.log('call getDeviceMessages',asset._id)
                    scope.pathList = [];
                    scope.paths = [];
                    scope.stopList = [];

                    if ( scope.item && scope.item.extra && scope.item.extra.histo.displayType === "POLYLINE") {
                        scope.histoLayer = new L.featureGroup();
                        scope.histoLayer.addTo(scope.map);
                    }

                    var stopIcon = L.icon({
                        iconUrl: 'app/assets/images/stop.png',
                        iconSize: [24, 24],
                        iconAnchor: [12, 12]
                    });

                if(asset && asset._id && scope.startDt && scope.endDt){
                   // console.log('dates',moment(scope.startDt).format() +" / "+moment(scope.endDt).format())

                    var query = {
                        query: [
                            {
                                "$match": {
                                    "_asset": asset._id,
                                    gps_dt: {
                                        $gte: scope.startDt,
                                        $lte: scope.endDt
                                    }
                                }
                            }
                        ]
                    };

                    deviceMsgSrv.getDeviceMsgAgg(query, 10000, 0, function (response) {
                      //  console.log('response ',response)
                        //  if(response && response.length>0) {
                        angular.forEach(response, function (message) {
                            var latLng = {lat: message.loc.coordinates[1], lng: message.loc.coordinates[0]};
                            if (message.io !== undefined && message.io.con !== undefined && message.gps_dt !== undefined && message.loc !== undefined && message.loc.coordinates.length > 0) {
                                if (message.io.con === 1) {
                                    scope.paths.push(latLng);
                                    if (scope.item.extra.histo.displayType === "HEATMAP") {
                                        latLng = {};
                                        latLng.lat = message.loc.coordinates[1];
                                        latLng.lon = message.loc.coordinates[0];
                                        latLng.value = getMessageValue(message, scope.item.input);
                                    }
                                    scope.pathList.push(latLng);
                                } else {
                                    if (scope.item.extra.histo.displayType === "POLYLINE") {
                                        L.marker(latLng, {icon: stopIcon}).addTo(scope.histoLayer);
                                    }
                                }
                            } else {
                            }
                        });


                        if (scope.item.extra.histo.displayType === "HEATMAP") {
                            scope.heatmap = new L.DivHeatmapLayer({
                                clickable: false,
                                radius: 100,
                                gradient: true,
                                color: "#d9534f"
                            });
                            scope.heatmap.addTo(scope.map);
                            scope.heatmap.setData(scope.pathList);

                        } else if (scope.item.extra.histo.displayType === "CLUSTER") {
                            scope.pointCluser = L.markerClusterGroup();

                            var pointIcon = L.icon({
                                iconUrl: "app/assets/images/home/signal.png",
                                iconSize: [24, 24],
                                iconAnchor: [12, 12]
                            });

                            angular.forEach(scope.pathList, function (point) {
                                L.marker(point, {icon: pointIcon}).addTo(scope.pointCluser);
                            });

                            scope.pointCluser.addTo(scope.map);


                        } else {


                            var path = L.polyline(scope.pathList, {
                                color: '#0275d8',
                                weight: 3
                            });
                            path.addTo(scope.histoLayer);
                        }

                        $timeout(function () {
                            if (scope.markers.getLayers().length > 0) {
                                scope.map.fitBounds(scope.markers.getBounds());
                            } else{
                                if (scope.paths && scope.paths.length > 0)
                                    scope.map.fitBounds(scope.paths);

                            }
                        });

                        // }else{}

                    }, function (err) {
                        console.log(err);
                    })
                }
                };



                if(scope.item._assets && scope.item._assets.length>0) {


                    angular.forEach(scope.item._assets, function (asset) {
                        if(scope.item.type ==="MAP_HISTO"){
                            scope.getDeviceMessages(asset);
                        }else{
                            if (asset.rt.loc && asset.rt.loc.coordinates && asset.rt.loc.coordinates.length > 0) {
                                var x = asset.rt.loc.coordinates[1];
                                var y = asset.rt.loc.coordinates[0];

                                var marker = L.marker([x, y]).bindTooltip('<strong class="text-primary">'+asset.name+'</strong>',  { permanent: true, interactive: false });
                                scope.markers.addLayer(marker);
                            }
                        }
                    });
                }
                scope.map.addLayer(scope.markers);




                $timeout(function () {
                    elem.children().css({
                        width: elem.parent().width(),
                        height: elem.parent().height()
                    });
                    $timeout(function () {
                        if(scope.markers.getLayers().length > 0){
                            scope.map.fitBounds(scope.markers.getBounds());
                        }else if(scope.paths && scope.paths.length>0){

                            scope.map.fitBounds(scope.paths);
                        }
                    });

                    scope.map.invalidateSize();
                },500);

                /**
                 * Function to be called from outside directive to update widget
                 */
                angular.extend(scope.options,{onChange:function(cfg){
                    try {
                       // console.log('onchange', cfg)

                        if (cfg && cfg._id) {
                            scope.item = cfg;
                            if (scope.item._assets && scope.item._assets.length > 0) {

                                var id = 'map_' + scope.item._id;

                                if (scope.map.hasLayer(scope.markers)) {
                                    scope.map.removeLayer(scope.markers);
                                }
                                if (scope.map.hasLayer(scope.heatmap)) {
                                    scope.map.removeLayer(scope.heatmap);
                                }
                                if (scope.map.hasLayer(scope.pointCluser)) {
                                    scope.map.removeLayer(scope.pointCluser);
                                }
                                if (scope.map.hasLayer(scope.histoLayer)) {
                                    scope.map.removeLayer(scope.histoLayer);
                                }
                                scope.markers = L.markerClusterGroup();
                                scope.map.addLayer(scope.markers);
                                angular.forEach(scope.item._assets, function (asset) {
                                    if (scope.item.type === "MAP_HISTO") {
                                        scope.getDeviceMessages(asset);
                                    } else {
                                        if (asset.rt.loc && asset.rt.loc.coordinates && asset.rt.loc.coordinates.length > 0) {
                                            var x = asset.rt.loc.coordinates[1];
                                            var y = asset.rt.loc.coordinates[0];

                                            var marker = L.marker([x, y]).bindTooltip('<strong class="text-primary">' + asset.name + '</strong>', {
                                                permanent: true,
                                                interactive: false
                                            });
                                            scope.markers.addLayer(marker);
                                        }

                                        scope.map.fitBounds(scope.markers.getBounds());

                                    }
                                });


                                $timeout(function () {
                                    scope.map.invalidateSize();
                                })
                            }


                        }

                    }catch(error){
                        console.log(error);

                    }
                    }});
                /**
                 * listen  for  newMsg and  update value
                 * @type {function(): void|*|function()}
                 */
                var listener =$rootScope.$on("newMsg",function(event,args){
                    if(args && args._id){
                        if (scope.item.type === "MAP") {
                            if (scope.item._assets && scope.item._assets.length > 0) {
                                var isAssetInList = false;
                                for (var i = 0; i < scope.item._assets.length; i++) {
                                    if (scope.item._assets[i]._id === args._id) {
                                        scope.item._assets[i] = args;
                                        isAssetInList = true;
                                    }
                                }
                                if (isAssetInList && scope.item._assets && scope.item._assets.length > 0) {
                                    scope.map.removeLayer(scope.markers);
                                    scope.markers = L.markerClusterGroup();
                                    scope.map.addLayer(scope.markers);
                                    angular.forEach(scope.item._assets, function (asset) {

                                        scope.val = getValue(asset, scope.item.input);
                                        if (scope.val && scope.val.coordinates.length > 0) {
                                            var x = scope.val.coordinates[1];
                                            var y = scope.val.coordinates[0];

                                            var marker = L.marker([x, y]);

                                            scope.markers.addLayer(marker);

                                        }
                                    });


                                    scope.map.fitBounds(scope.markers.getBounds());

                                    $timeout(function () {
                                        scope.map.invalidateSize();
                                    })
                                }
                            }

                        }

                    }
                });
                /**
                 * remove listener on destroy
                 */
                scope.$on('$destroy', function() {
                    listener();
                });

                $rootScope.$on('widgetResized', function (event, resizedId) {
                    $timeout(function () {
                        if(id === resizedId){

                           /* elem.parent().css({
                                overflow : "hidden"
                            });

                            elem.children().css({
                                width: elem.parent().width(),
                                height: elem.parent().height()
                            });
                            scope.map.invalidateSize();*/

                            $timeout(function () {
                                elem.children().css({
                                    width: elem.parent().width(),
                                    height: elem.parent().height()
                                });
                                $timeout(function () {
                                    if(scope.markers.getLayers().length > 0){
                                        scope.map.fitBounds(scope.markers.getBounds());
                                    }else if(scope.paths && scope.paths.length>0){

                                        scope.map.fitBounds(scope.paths);
                                    }
                                });

                                scope.map.invalidateSize();
                            },500);







                        }
                    }, 500)
                })


            });



        }



    }
});