angular.module("app.directives").directive('myHeader', function ($rootScope,$state, loginSrv, ngDialog) {
    return{
        templateUrl: "app/commons/directives/header/header.tpl.html",
        scope: {

        },
        link:  function (scope, elem, attr) {

            //Sidebar toggled (false when sidebar opened)
            $rootScope.sidebarToggled = false;

            // Sidebar toggling
            scope.toggleSidebar  = function () {
                $rootScope.sidebarToggled = !$rootScope.sidebarToggled;
            };

            // Sign out user
            scope.logout=function() {
                loginSrv.logout( function(){
                    $rootScope.appData={};
                    $rootScope.maplayers={};
                    $state.go("login", {});
                })
            };

            scope.toggleAlerts = function () {
                $rootScope.$emit('toggleAlerts');
            };
            scope.toggleConfigAlerts = function () {
                $rootScope.$emit('toggleConfigAlerts');
            };

            //Editing profile
            scope.profileEdit = function () {
                ngDialog.open({
                    template: 'app/commons/dialogs/profile/profile.dlg.tpl.html',
                    controller: 'profileDlgCtrl',
                    className: "ngdialog-theme-default custom_big_dialog",
                    scope: scope
                })
            }


        }
    }
});