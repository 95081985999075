angular.module('app.dialogs')
    .controller('confirmDashDeleteDlg', function ($scope, $rootScope, dashboardSrv, Notification) {
       $scope.confirm = function () {
           dashboardSrv.deleteDashboardById($scope.deletedDashboard._id, function (res) {
               if(res.success){
                   Notification.success({
                       title: "Dashboard deleted",
                       message: "Dashboard was successfully deleted!"
                   });
                   $scope.dashboards.splice($scope.dashboards.indexOf($scope.deletedDashboard), 1);
                   $scope.closeThisDialog($scope.dashboards);
               }else{
                   Notification.error({
                       title: "Dashboard deleting failed",
                       message: "An error occurred while trying to delete dashboard, Try again!"
                   });
                   $scope.closeThisDialog();
               }
           }, function (err) {
               Notification.error({
                   title: "Dashboard deleting failed",
                   message: "An error occurred while trying to delete dashboard, Try again!"
               });
               console.log(err);
           });
           $scope.deletedDashboard = {};
       };
    });