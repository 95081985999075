angular
    .module('app',
    [
        'app.services',
        'app.directives',
        'app.dialogs',
        'app.widgets',
        'angularjs-gauge',
        'angular.filter',
        'ngAnimate',
        'ngSanitize',
        'ngCookies',
        'angular-jwt',
        'ui.router',
        'ui-notification',
        'pascalprecht.translate',
        'angularSpinner',
        'ngProgress',
        'ngDialog',
        'gridster',
        'ngDragDrop',
        'ui.odometer',
        'ui.select',
        'smart-table',
        "ui.bootstrap",
        "ngFileUpload",
        "satellizer",
        'infinite-scroll',
        "ui-leaflet"
    ]);

angular.module("app").factory('ajax-loader',["$rootScope", "$q", "$window","usSpinnerService", function ($rootScope, $q, $window,usSpinnerService) {
        var requests = 0;
        function show() {
            if (!requests) {

                $rootScope.$broadcast("ajax-start");

                usSpinnerService.spin('spinner-1');
            }
            requests++;
        }

        function hide() {
            requests--;
            if (!requests) {

                $rootScope.$broadcast("ajax-stop");
                usSpinnerService.stop('spinner-1');

            }
        }

        return {
            'request': function (config) {

                config.headers = config.headers || {};
                if ($window.sessionStorage.token  && !config.headers.Authorization) {
                    config.headers.Authorization = 'Bearer ' + $window.sessionStorage.token;
                }
                if(config.url.indexOf("NOLOAD") === -1)
                    show();
                return  $q["when"](config);
            }, 'response': function (response) {

                if(response.config.url.indexOf("NOLOAD") === -1)
                hide();
                return  $q["when"](response);
            }, 'responseError': function (rejection) {

                if(response.config.url.indexOf("NOLOAD") === -1)
                hide();
                return  $q["when"](rejection);
            }
        };
    }]
).config(["$httpProvider",function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('ajax-loader');
    }]);

angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250)