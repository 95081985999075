angular.module('app').controller('adminUserAddCtrl', function ($scope, $rootScope, $state, $translate, userSrv, ngDialog, Notification) {
    $scope._ADMIN_EDIT_ADD = {};
    $scope.user = {};
    /**
     * Save user
     */
    $scope.save = function () {
        if ($scope.user.password && $scope.user.password !== $scope._ADMIN_EDIT_ADD.confirm_password) {
            ngDialog.openConfirm({
                template: 'app/commons/dialogs/error.dlg.html',
                overlay: true,
                showClose: false,
                controller: ['$scope', function ($scope) {
                    // controller logic
                    $scope.title = $translate.instant("CHECK_YOUR_PASSWORD_TITLE");
                    $scope.message = $translate.instant("CHECK_YOUR_PASSWORD_MESSAGE");
                }]
            });
            return;
        }
        userSrv.add($scope.user, function (response) {
            if (response.success) {
                Notification.success({
                    title: "User created",
                    message: "User " + $scope.user.login + " was created successfully!"
                });
                $state.go('app.admin.user');
                $scope.user = {};
            } else {
                Notification.error({
                    title: "Erreur",
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.user.login + "!"
                });
                $scope.user = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message: msg
            });
        });
    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.user = {};
        $state.go('app.admin.user');
    };
});
