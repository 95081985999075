angular.module('app.widgets').directive('stringWdg', function () {
    return{
        templateUrl: "app/commons/widgets/string/string.tpl.html",
        scope : {
            item : "=",
            options : "="
        },
        link: function (scope, elem, attr) {
            
        }
    }
});