/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('actionLogSrv', function ($http,$rootScope) {

  var service={};


  service.getActionLogList=function(searchQuery,limit,page,success,error){
    var l=10;
    var p= 1;
    if (page) p=page;
    if (limit) l=limit;

    $http.post($rootScope.config.API_URI+'/actionLog/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
      if (response && response.data && response.data.success){
        success(response.data);
      }
      else{
        error(data);
      }



    }),function(data) {
      error(data)

    };
  };
  service.getActionLogAgg=function(agg,limit,page,success,error){
    var l=10;
    var p= 1;
    if (page) p=page;
    if (limit) l=limit;


    $http.post($rootScope.config.API_URI+'/actionLog/agg?limit='+l+'&page='+p,agg).then(function(response) {
      if (response && response.data && response.data.success){
        success(response.data);
      }
      else{
        error(data);
      }



    }),function(data) {
      error(data)

    };
  };

  return service;

});
