angular.module('app').controller('homeCtrl', function ($rootScope,$stateParams, $timeout, $scope,$q,$state, ngDialog,dashboardSrv) {
    $scope.gridColumns = 6;
    if(angular.element(window).width() <= 1234 && angular.element(window).width() > 744){
        $scope.gridColumns = 4;
    }
    $scope.gridsterOpts = {
        columns: $scope.gridColumns,
        mobileBreakPoint: 744,
        mobileModeEnabled: true,
        pushing: true,
        floating: true,
        swapping: true,
        draggable: {
            handle: '.gridster-header',
            stop: function(event, $element, widget) {
                var widgets=angular.copy($rootScope.currentDashboard._widgets);

                widgets= widgets.map(function(val){
                    // check for populated asset
                    if(!Array.isArray(val._assets)) {
                        val._assets=[val._assets]
                    }
                    if(val._assets && val._assets.length>0){
                        val._assets=val._assets.map(function(asset){
                            if(asset && asset._id) return asset._id;
                            else return  asset;
                        })

                    }
                    if(val._share && val._share.length>0){
                        val._share=val._share.map(function(share){
                            if(share && share._shared_by && share._shared_by._id) return share._shared_by._id;
                            else  return share._shared_by;
                        })

                    }

                    return  val;
                });
                dashboardSrv.updateDashboardById($rootScope.currentDashboard._id,{_widgets: widgets},function(res){
                    if(res && res.success){

                        $scope.closeThisDialog();
                    }else{
                        console.error(res);
                    }

                },function(err){
                    console.error(err);
                })




            }
        },
        resizable: {

            stop: function(event, $element, widget) {
                if($element.find('.map').length > -1){
                    $rootScope.$emit('widgetResized', $element.find('.map').attr('id'));
                }
                if($element.find('.graph').length > -1){
                    $rootScope.$emit('widgetResized', $element.find('.graph').attr('id'));
                }
                var widgets=angular.copy($rootScope.currentDashboard._widgets);

                widgets= widgets.map(function(val){
                    // check for populated asset
                    if(!Array.isArray(val._assets)) {
                        val._assets=[val._assets]
                    }
                    if(val._assets && val._assets.length>0){
                        val._assets=val._assets.map(function(asset){
                            if(asset && asset._id) return asset._id;
                            else return  asset;
                        })

                    }
                    if(val._share && val._share.length>0){
                        val._share=val._share.map(function(share){
                            if(share && share._shared_by && share._shared_by._id) return share._shared_by._id;
                            else  return share._shared_by;
                        })

                    }

                    return  val;
                });
                dashboardSrv.updateDashboardById($rootScope.currentDashboard._id,{_widgets: widgets},function(res){
                    if(res && res.success){

                        $scope.closeThisDialog();
                    }else{
                        console.error(res);
                    }

                },function(err){
                    console.error(err);
                })
            }
        }
    };

    angular.element(window).on('resize', function () {
        clearTimeout(window.resizedFinished);
        window.resizedFinished = setTimeout(function(){

            if (angular.element(window).width() > 1700) {
                $scope.gridsterOpts.columns = 6;
            }
            if (angular.element(window).width() <= 1700 && angular.element(window).width() > 744) {
                $scope.gridsterOpts.columns = 4;
                $state.reload();
            }
        }, 250);


    });

    $scope.$on("drag:disable",function(event,args){
        $scope.gridsterOpts.draggable.enabled=false;

    });
    $scope.$on("drag:enable",function(event,args){
        $scope.gridsterOpts.draggable.enabled=true;

    });
    $scope.$on("resize:enable",function(event,args){
        $scope.gridsterOpts.resizable.enabled=true;
    });
    $scope.$on("resize:disable",function(event,args){
        $scope.gridsterOpts.resizable.enabled=false;

    });
    $scope.deviceToWidget = function (e, ui) {
        var deferred = $q.defer();

        $rootScope.deviceToWidget($rootScope.currentDashboard);
        deferred.reject();
        return deferred.promise;
    };

    $scope.configDash = function (dashboard) {
        $rootScope.$emit('actionDashboard', {dashboard : dashboard, action : "CONFIG"});
    };
    $scope.deleteDash = function (dashboard) {
        $rootScope.$emit('actionDashboard', {dashboard : dashboard, action : "DELETE"});
    };
    $scope.shareDash = function (dashboard) {
        $rootScope.$emit('actionDashboard', {dashboard : dashboard, action : "SHARE"});
    };

});