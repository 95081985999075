/**
 * Created by Mohamed Saad on 18/04/2017.
 */


angular.module("app.services").factory('chartSrv', [function () {

    var service = {};

    service.zoom=function(centerDate){
        service.chart.xAxis[0].setExtremes(moment(centerDate).add(-30,"minutes"),moment(centerDate).add(30,"minutes") );
        service.chart.showResetZoom();
    }

    service.loadChart = function (assetName, idCard, series, xaxis, date, chartObject,plotOptions, yaxis) {
        service.chart = Highcharts.chart('chart', {
            chart: chartObject,

            title: {
                text:"<span class='ion-model-s'>"+" "+ assetName,
                useHTML: true
            },
            subtitle: {
                text:"<span class='ion-card'>"+" "+idCard,
                useHTML: true
            },
            xAxis: {
                events: {
                    afterSetExtremes: function(evt){
                        console.log('extreme changed ',evt);
                    }
                },
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: 'Date'
                }
            },
            yAxis:yaxis,
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.x:%Y-%m-%d %H:%M:%S}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: plotOptions,
            series: series

        });
    };

    return service;
}]);
