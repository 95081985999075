angular.module('app').controller('adminUserCtrl', function ($scope, $rootScope, $state,userSrv, $translate,ngDialog, Notification) {
    $scope.user = {};
    $scope.itemsByPage = 5;
    $scope.search = {};

    /**
     * Getting users
     */
    $scope.loadUsers = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options = {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                },
                {
                    path: '_ctry',
                    select: 'name'

                },
                {
                    path: '_role',
                    select: 'permissions'
                }
            ]
        };

        var query = {};
        var roles = $rootScope.SESSION.user._role._childs;
        if (roles.indexOf($rootScope.SESSION.user._role._id) === -1) {
            roles.push($rootScope.SESSION.user._role._id);
        }
        query._role = {$in: roles};
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }

        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.last_name) {
                query.last_name = {$regex: tableState.search.predicateObject.last_name, $options: "i"};
            }
            if (tableState.search.predicateObject.first_name) {
                query.first_name = {$regex: tableState.search.predicateObject.first_name, $options: '-i'};
            }
            if (tableState.search.predicateObject.login) {
                query.login = {$regex: tableState.search.predicateObject.login, $options: '-i'};
            }
        }

        if ($scope.search) {
            if ($scope.search._role) {
                query._role = {$regex: $scope.search._role._id, $options: '-i'};
            }
            if ($scope.search._company_owner) {
                query._company_owner = $scope.search._company_owner;
            }
        }

        userSrv.getUsersList({query: query, options: options}, number, start, function (response) {
            if (response.success) {
                $scope.users = response.result;
                $scope.userCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };
    /**
     * Removing user
     * @param user
     */
    $scope.remove = function (user) {
        ngDialog.openConfirm({template: 'app/commons/dialogs/confirm/confirm.dlg.html', overlay: true, showClose: false, controller: ['$scope', function ($scope) {
                // controller logic
                $scope.title = $translate.instant("USER_DELETE_CONFIRM_TITLE");
                $scope.message = $translate.instant("USER_DELETE_CONFIRM_MESSAGE", {firstName: user.first_name, lastName: user.last_name});
            }]}).then(function () {
            userSrv.deleteUserById(user._id, function (data) {
                if (data.success) {
                    Notification($translate.instant("USER_DELETED", {
                        firstName: user.first_name,
                        lastName: user.last_name
                    }));
                    $scope.loadUsers();
                    $scope.tableState.pagination.start = 0;
                } else {
                    console.log(data.error);
                }
            }, function (err) {

            });
        }, function (reason) {

        });
    };
});
