/**
 * Project: ngiTracking
 * Created by NGI team 02/03/2017.
 */
angular.module("app.services").factory('histoChartSrv', function ($http, $rootScope, $translate) {

    var service = {};
    /**
     * update Chart
     * @param id
     * @param data
     * @param paths
     * @param title
     * @param periodLabel
     * @param containerId
     */
    service.updateLineChart = function (id, data, paths,yA, title, periodLabel, containerId) {
        var series = data;
        var charOptions = {
             chart: {
                zoomType: 'x',
                events: {},
                 marginTop: 45

             },
            title: {
                text: "",
                useHTML: true,
                y:5


            },
/*
            subtitle: {
                text: periodLabel,
                useHTML: true,
                y:25


            },*/
            xAxis: {
                //categories: xaxis
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                plotBands: [],

                crosshair: {
                    color: "red"
                },
            },

           yAxis: yA,

            tooltip: {
           /*     crosshairs: {
                    color: 'pink'
                },*/
                shared: true,
                valueSuffix: '',
                xDateFormat: '%d/%m/%Y %H:%M:%S'
            },
            legend: {

                itemMarginBottom: 0,
                padding:0

            },

            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    },
                    enableMouseTracking: true
                },

                area: {
                    stacking: 'normal',
                    step: 'right'

                },
                     series: {




                    cursor: 'pointer',
                    showInLegend: true,
                    point: {
                        events: {
                            click: function (event) {
                                var chart = this.series.chart.xAxis[0];
                                chart.removePlotLine('plot-line-1');
                                chart.addPlotLine({
                                    value: this.x,
                                    color: '#FF0000',
                                    width: 2,
                                    id: 'plot-line-1',
                                    zIndex: 4
                                });
                                var pos = this.x;
                                angular.forEach(charOptions.xAxis.plotBands, function (item, key) {
                                    if (key < (charOptions.xAxis.plotBands.length)) {
                                        if (pos >= item.from && pos <= item.to) {
                                            angular.forEach(item.path.paths, function (elem) {
                                                var dt = Date.parse(moment(elem.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate());
                                                if (pos == dt) {
                                                    var index = item.path.paths.indexOf(elem);
                                                    $rootScope.$broadcast('showSelectedPath', {
                                                        path: item.path,
                                                        index: index,
                                                        position: elem.loc
                                                    });

                                                }

                                            })


                                        }

                                    }
                                })

                            },
                 /*           mouseOver: function() {


                                angular.forEach(charOptions.xAxis.plotBands, function (elem) {

                                    if (elem.path.state === 'drive') {
                                        elem.color="#0000FF"
                                        console.log("ddddddddddddddddd ",elem)

                                    }
                                })




                            },
                            mouseOut: function() {

                            }*/
                        }

                    }
                }


            },
            credits: {
                enabled: false
            },
            series: data
        };

        service.drawStateBandHigchart(paths, charOptions);
        if(paths.length>0) {
            service.drawStopsHigchart(paths, charOptions);
        }
        Highcharts.chart(id, charOptions);
    };
    /**
     * initChart
     * @param id
     * @param title
     * @param periodLabel
     */
    service.initChart = function (id, title, periodLabel) {
        //chart.series[0].remove(true);

        var charOptions = {
            title: {
                text: title,
                useHTML: true
            },

            subtitle: {
                text: periodLabel,
                useHTML: true
            },
        };
        Highcharts.chart(id, charOptions);

    };
    /**
     * drawStateBandHigchart
     * @param paths
     * @param charOptions
     */
    service.drawStateBandHigchart = function (paths, charOptions) {
        var brand = {}, color = "", dateto = "";
        var previousPoint = null;
        angular.forEach(paths, function (item, key) {
            if (key < (paths.length - 1)) {
                dateto = Date.parse(moment(paths[key + 1].Start.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate());

            } else {
                dateto = Date.parse(moment(item.End.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate());
            }

            if (item.state === 'stop')
                color = '#e0e0e0';
            else
                color = '#ffffff';
            brand = {
                path: item,
                id: item.index,
                color: color,
                from: Date.parse(moment(item.Start.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate()), // Start of the plot band
                to: dateto, // End of the plot band

                events: {
                        click: function (event) {
                        if (this.options.path.state === 'drive') {
                            $rootScope.$broadcast('showSelectedPath', {path: item,position:null, index: 0});


                        }
                    }
                }


            };
            //Add band
            charOptions.xAxis.plotBands.push(brand);

        });


    };
    /**
     * drawStopsHigchart
     * @param paths
     * @param charOptions
     */
    service.drawStopsHigchart = function (paths, charOptions) {
        var stop = "";
        var obj = {};
        var k = 0;
        var find = false;
        var po = '{point.x}';

        stop = {
            "type": "scatter",
            "name": $translate.instant("STOP"),
            "zIndex": 30,
            "marker": {
                "radius": 1,
                "symbol": 'url(app/assets/images/historic/stop-higchart.png)'
            },
            tooltip: {
                pointFormat: '<span style="font-size:10px">{point.x:%d/%m/%Y %H:%M:%S}</span>',
                shared: true,
                useHTML: true

            },
            data: []

        };
        var demarrage = "";
        demarrage = {
            "type": "scatter",
            "name": $translate.instant("START_UP"),
            "zIndex": 30,
            "marker": {
                "radius": 1,
                "symbol": 'url(app/assets/images/historic/demarrage.png)'

            },
            tooltip: {
                pointFormat: '<span>{point.x:%d/%m/%Y %H:%M:%S}</span>',
                shared: true,
                useHTML: true

            },

            data: []
        };
        var i = 0, j = 0;
        angular.forEach(paths, function (item) {
            if (item.state === 'stop') {
                //  console.log(item);
                stop.data[i] = {


                    "x": Date.parse(moment(item.Start.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate()),

                    y: 0,

                    events: {
                        click: function (e) {
                         //   $rootScope.$broadcast('showSelectedPath', {path: item,position:null, index: 0});
                            $rootScope.$broadcast('showSelectedPath', {path: item,position:null, index: 0});


                        }

                    }
                };
                i++;
            } else {
                demarrage.data[j] = {
                    "x": Date.parse(moment(item.Start.gps_dt, "YYYY-MM-DD HH:mm:ss").toDate()),
                    y: 0

                };
                j++
            }

        });

        charOptions.series.push(stop);
        charOptions.series.push(demarrage);

    };

    return service;
});
