/**
 * Created by Mohamed Saad on 16/05/2017.
 */


angular.module("app.services").factory('scheduledPeriodSrv',["$translate", function ($translate) {

    var service={};
    service.getScheduledPeriod=function(){
        return [
            {"name":$translate.instant( "DAILY"), "value": "DAILY"},
            {"name":$translate.instant( "WEEKLY"), "value": "WEEKLY"},
            {"name":$translate.instant( "MONTHLY"), "value": "MONTHLY"},
            {"name":$translate.instant( "YEARLY"), "value": "YEARLY"}
        ]
    }
    service.getScheduledDailyConfig=function(){
        return [
            {"name":$translate.instant( "Jour précédant"), "value": -1},
            {"name":$translate.instant( "Jour actuel"), "value": 0},
            {"name":$translate.instant( "Exactement 24 Heures"), "value": 1}
        ]
    }
    service.getScheduledWeeklyConfig=function(){
        return [
            {"name":$translate.instant( "Semaine précédente"), "value": -1},
            {"name":$translate.instant( "Semaine actuelle"), "value": 0},
            {"name":$translate.instant( "Exactement 7 Jours"), "value": 1}
        ]
    }
    service.getScheduledMonthlyConfig=function(){
        return [
            {"name":$translate.instant( "Mois précédant"), "value": -1},
            {"name":$translate.instant( "Mois courant"), "value": 0},
            {"name":$translate.instant( "Exactement 30 Jours"), "value": 1}
        ]
    }
    service.getScheduledYearlyConfig=function(){
        return [
            {"name":$translate.instant( "Année précédente "), "value": -1},
            {"name":$translate.instant( "Année actuelle "), "value": 0},
            {"name":$translate.instant( "Exactement 12 mois"), "value": 1}
        ]
    }
    service.getScheduledTypeReport=function(){
        return [
            {"name":$translate.instant( "PDF"), "value": 'PDF'},
            {"name":$translate.instant( "DOCX"), "value": 'DOCX'},
            {"name":$translate.instant( "XLSX"), "value": 'XLSX'}
        ]
    };

    return service;

}]);
