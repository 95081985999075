/**
 * Created by Kais CHAIBI on 28/12/2016.
 */
angular.module("app.directives").directive('polygonSelector', ['leafletData', '$rootScope', function (leafletData, $rootScope) {
    return {
        restrict: "EA",
        scope: {
            map: '@',
            config: '=',
            conditionIndex:"@"
        },
        templateUrl: 'app/commons/directives/alerts/PolygonSelector.tpl.html',
        controller: ["$scope","$element",'$timeout', function ($scope,$element,$timeout) {



            $scope.$watch("config.data.value", function(newVal){
                if(newVal && newVal.coordinates && newVal.coordinates.length>2) {
                    $scope[ 'polygon'].polygonVal.$setValidity("required", true);
                    $scope.isValidPolygon=true;
                }else {
                    $scope.isValidPolygon=false;
                    $scope[ 'polygon'].polygonVal.$setValidity("required", false);
                }
            })



            $scope.drawnItems = new L.FeatureGroup();
            var drawControl = new L.Control.Draw({
                position: 'topleft',
                draw: {
                    polygon: {
                        title: 'Draw polygon!',
                        allowIntersection: false,
                        drawError: {
                            color: '#b00b00',
                            timeout: 1000
                        },
                        shapeOptions: {
                            color: '#bada55'
                        },

                        showArea: true
                    },
                    rectangle: {
                        allowIntersection: false,
                        drawError: {
                            color: '#b00b00',
                            timeout: 1000
                        },
                        shapeOptions: {
                            color: '#bada55'
                        }
                    },
                    circle: false,
                    marker:false,
                    polyline:false

                },
                edit: {
                    featureGroup: $scope.drawnItems

                }
            });


            angular.extend($scope, {
                events: {
                    map: {
                        enable: ['click', 'drag', 'blur', 'touchstart'],
                        logic: 'emit'
                    }
                },
                defaults: {},

                center: {
                    lat: 36.843602,
                    lng: 10.206953,
                    zoom: 5

                },



                controls: {},
                layers: {
                    baselayers: {
                        NGI: {
                            name: 'Carte NGI',
                            url: $rootScope.config.LBS_WMS,
                            type: 'wms',
                            options: {
                                attribution: '� Powered by NGI'

                            }
                        }

                        /*
                         googleRoadmap: L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                             name: 'Google normale',
                             subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                             icon: './scripts/commons/directives/map/images/gNorm.png'

                         }),
                         googleRoadmap: L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                             name: 'Google normale',
                             subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                             icon: './scripts/commons/directives/map/images/gNorm.png'

                         }),
                         googleTerrain: L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                             name: 'Google Satellite',
                             subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                             icon: './scripts/commons/directives/map/images/gSat.png'

                         }),
                         googleHybrid: L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
                             name: 'Google Hybride',

                             subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                             icon: './scripts/commons/directives/map/images/gHybr.png'

                         }),
                         osm: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                             name: 'Open Street Map',

                             icon: './scripts/commons/directives/map/images/osmMap.png'

                         })*/
                    },
                    /* overlays:{
                         draw:$scope.drawnItems
                     }*/
                },


                tiles: {
                    url: "http://sig.weenee-fleet.com/bgis/wms",
                    options: {
                        attribution: '@ Powered by NGI'
                    }
                }

            });

            leafletData.getMap("map_"+$scope.conditionIndex).then(function (map) {

                map.options.attributionControl = true;
                map.attributionControl.setPrefix('');
                //map.addControl( scope.sidebar);
                map.whenReady(function (e) {

                    map.addLayer($scope.drawnItems);
                    map.addControl(drawControl);
                    map.on('draw:created', function (e) {

                        $scope.drawnItems.clearLayers();
                        var type = e.layerType,
                            layer = e.layer;
                        layer.addTo($scope.drawnItems)
                        $scope.config.data.value={type:'Polygon',coordinates:e.layer._latlngs[0].map(function(val){
                                return  [val.lng,val.lat];
                            })}
                        $scope.config.data.value.coordinates=$scope.enclosePolygon($scope.config.data.value.coordinates)
                    });
                    map.on("draw:deleted", function (e) {
                        $scope.drawnItems.clearLayers();

                        $scope.config.data.value=null;
                    });
                    map.on('draw:edited', function (e) {
                        var layers = e.layers;
                        layers.eachLayer(function (layer) {
                            layer.addTo($scope.drawnItems)
                            $scope.config.data.value={type:'Polygon',coordinates:layer._latlngs.map(function(val){
                                    return  [val.lng,val.lat];
                                })}
                            $scope.config.data.value.coordinates=$scope.enclosePolygon($scope.config.data.value.coordinates)

                        });

                    });

                    // map.addControl(measureControl);
                    if($scope.config && $scope.config.data && $scope.config.data.value && $scope.config.data.value.coordinates && $scope. config.data.value.coordinates.length>2 ) {
                        var polygone = L.polygon($scope.config.data.value.coordinates.map(function (val) {

                            return {lat: val[1], lng: val[0]}
                        }), {

                            color: '#a94442',
                            weight: 1


                        }).addTo($scope.drawnItems);
                        map.fitBounds(polygone.getBounds());

                        $timeout(function () {

                            map.invalidateSize();


                        });

                    }

                });



            })


            $scope.enclosePolygon=function(coordinates){
                if(coordinates && coordinates.length>2){
                    if(coordinates[0][0] !=coordinates[coordinates.length-1][0] || coordinates[0][1] !=coordinates[coordinates.length-1][1]  ){
                        coordinates.push(coordinates[0]);
                    }
                }
                return  coordinates;
            }

        }]
    }
}])
