/**
 * Formatting request and response for Benomad geo-service
 */

/**
 * Constant type for Formatter Name
 * @type {{}}
 */
var formatters = {
    GEO_CODING_FORMATTER: "GEO_CODING_FORMATTER",
    REVERSE_GEO_CODING_FORMATTER: "REVERSE_GEO_CODING_FORMATTER",
    REVERSE_GEO_CODING_BATCH_FORMATTER: "REVERSE_GEO_CODING_BATCH_FORMATTER",

    ROUTING_FORMATTER: "ROUTING_FORMATTER",
    //POI_PROXIMITY: "POI_PROXIMITY"
}
/**
 * Simple class for formatting Request for BDN geo-service
 * @constructor
 */
var BndRequestFormatter = function () {


    /**
     * format Request to BND request format
     * @param formatter name of the formatter see @link{BndRequestFormatter.formatters}
     * @param params the standard  request parameters
     * @param maxResults maximum result to be queried
     * @param language the language
     */
    this.formatRequest = function (formatter, params, maxResults, language) {
        if (formatter == formatters.GEO_CODING_FORMATTER) {
            return this.formatGeoCodingRequest(params, maxResults, language);
        } else if (formatter == formatters.REVERSE_GEO_CODING_FORMATTER) {
            return this.formatRevGeoCodingRequest(params, maxResults, language);
        } else if (formatter == formatters.ROUTING_FORMATTER) {
            return this.formatRoutingRequest(params, maxResults, language);
        } else if (formatter == formatters.REVERSE_GEO_CODING_BATCH_FORMATTER) {
            return this.formatRev4BatchRequest(params, maxResults, language);
        } else {
            console.error("Unkown  request formatter " + formatter);
            return null;
        }
    }

    /**
     *format geocoding request
     * @param params
     * @param maxResults
     * @param language
     */
    this.formatGeoCodingRequest = function (params, maxResults, language) {

        var requestParams = angular.copy(geoServerConstantParam);
        if (params.bbox != undefined && params.bbox != null)
            requestParams.bbox = params.bbox;

        requestParams.country = (params.country != undefined) ? params.country : null;
        requestParams.countrycode = (params.countrycode != undefined) ? params.countrycode : null;
        requestParams.district = (params.district != undefined) ? params.district : null;
        requestParams.postalCode = (params.postalCode != undefined) ? params.postalCode : null;
        requestParams.state = (params.state != undefined) ? params.state : null;
        requestParams.city = (params.city != undefined) ? params.city : null;
        requestParams.street = (params.street != undefined) ? params.street : null;
        requestParams.maxresult = (maxResults != undefined) ? maxResults : 1;
        requestParams.streetNumber = (params.streetNumber != undefined) ? params.streetNumber : null;
        requestParams.language = (language != undefined && language != null ) ? language : "en";
        requestParams.action = "geocoding";
        requestParams.searchtype = "contains";
        return requestParams;
    }

    this.formatRevGeoCodingRequest = function (params, maxResults, language) {

     //   console.log("format rev geocoding  %o", params);
        var requestParams = angular.copy(geoServerConstantParam);

        requestParams.xy = params.position.lng + "," + params.position.lat;
        requestParams.radius = params.radius != undefined && params.radius != null && !isNaN(params.radius) ? params.radius : 50; // default radius 50 meters
        if (params.angle != undefined && params.angle != null)
            requestParams.angle = params.angle;
        if (params.options != undefined && params.options != null)
            requestParams.options = params.options;
        if (params.speed != undefined && params.speed != null)
            requestParams.speed = params.speed;
        if (params.transportType != undefined && params.transportType != null)
            requestParams.speed = getBndTransportType(params.transportType);

        requestParams.maxresult = (maxResults != undefined) ? maxResults : null;
        requestParams.language = (language != undefined && language != null ) ? language : "en";
        requestParams.action = "revgeocoding";
        requestParams.searchtype = "contains";

        return requestParams;
    }


    this.formatRev4BatchRequest = function (params, maxResults, language) {

       // console.log("format rev geocoding  batch %o", geoServerConstantParam);
        var requestParams = angular.copy(geoServerConstantParam);
        requestParams.xy = params.position.lng + "," + params.position.lat;
        requestParams.radius = params.radius != undefined && params.radius != null && !isNaN(params.radius) ? params.radius : 50; // default radius 50 meters
        if (params.options != undefined && params.options != null)
            requestParams.options = params.options;
        if (params.transportType != undefined && params.transportType != null)
            requestParams.speed = getBndTransportType(params.transportType);
        requestParams.maxresult = (maxResults != undefined) ? maxResults : null;
        requestParams.language = (language != undefined && language != null ) ? language : "en";
        requestParams.action = "revgeoBatch";
        requestParams.searchtype = "contains";

       // console.log("end formatter   %o , request ", params, requestParams);
        return requestParams;
    }
    /**
     * format routing request to BND specification
     * @param params
     * @param maxResults
     * @param language
     */
    this.formatRoutingRequest = function (params, maxResults, language) {
        var requestParams = angular.copy(geoServerConstantParam);
        var xy = prepareSteps(params.roadSteps);
        var bndCriterias = getBndCriteriaByName(params.criterias);
       // console.log("cretriaaaaaaaaaaaaaaaaaaaaaaaas ", bndCriterias);
        var bndOptions = getBndRoutingOptions(params.options);
        var bndtransportType = getBndtransportType(params.transportType);
       // console.log("transport type =" + bndtransportType + " intial params " + params.transportType);
        requestParams.xy = xy;

        if (bndCriterias != undefined && bndCriterias != null) {
            requestParams.criterias = bndCriterias;
        }
        requestParams.departureTime = params.departureTime;
        requestParams.isoChroneLimit = params.isoChroneLimit;
        requestParams.mode = routingConst.mode.MODE_VIAS;
        if (bndOptions != null && bndOptions != undefined)
            requestParams.options = bndOptions;
        requestParams.pr = params.pr;
        requestParams.rb = params.rb;
        requestParams.sp = params.sp;
        requestParams.speed = params.speed;
        requestParams.transportType = bndtransportType;
        requestParams.vf = params.vf;
        requestParams.xyRadius = params.xyRadius;

        requestParams.maxresult = (maxResults != undefined) ? maxResults : null;
        requestParams.language = (language != undefined && language != null ) ? language : "en";
        requestParams.action = "routing";
        requestParams.searchtype = "contains";

       // console.log(params.options);
        return requestParams;
    }

}

var BndResponseFormatter = function () {
    /**
     * format response from BND server
     * @param Formatter
     * @param response
     */
    this.formatResponse = function (formatter, response) {
        if (formatter == formatters.GEO_CODING_FORMATTER) {
            return this.formatGeoCodingResponse(response);
        } else if (formatter == formatters.REVERSE_GEO_CODING_FORMATTER) {
            return this.formatRevGeoCodingResponse(response);
        } else if (formatter == formatters.ROUTING_FORMATTER) {
            return this.formatRoutingResponse(response);
        } else if (formatter == formatters.REVERSE_GEO_CODING_BATCH_FORMATTER) {
            return this.formatRev4BatchResponse(response);
        }
        //else if (formatter == formatters.REVERSE_GEO_CODING_BATCH_FORMATTER) {
        //    return this.formatRev4BatchResponse(response);
        //}  else if (formatter == formatters.POI_PROXIMITY) {
        //    return this.formatPoiResponse(response);
        //}
        else {
            console.error("Unkown  response formatter " + formatter);
        }
    }
    /**
     * format BND geo-coding response
     *
     * @param response
     */
    this.formatGeoCodingResponse = function (response) {
        var count = 0;
        var points = [];
        if (typeof response == 'object') {
            var count = 0;
            var points = [];
            if (response.BND.Elements.count != null && response.BND.Elements.count != undefined) {
                count = response.BND.Elements.count;
            }
            var elements = response.BND.Elements.Element;

            if (elements != undefined) {
                elements.forEach(function (value, Index) {
                    var address = {
                        country: value.PostalAddress.Country,
                        city: value.PostalAddress.City,
                        district: value.PostalAddress.District,
                        postalCode: value.PostalAddress.PostalCode,
                        street: value.PostalAddress.Street,
                        countryCode: value.PostalAddress.CountryCode,
                        county: value.PostalAddress.County,
                        streetNumber: value.PostalAddress.StreetNumber
                    }
                    var point = new ngiWebMap.Point(value.Coordinate.x, value.Coordinate.y, value.PostalAddress.Street, "", "", address)
                    point.description = point.displayAddress;


                    points.push(point);
                })
            }
        }
        return {count: count, elements: points};

    }
    /**
     * formatting BND reverse geocoding response
     * @param response
     */
    this.formatRevGeoCodingResponse = function (response) {
        var count = 0;
        var points = [];
        if (typeof response == 'object') {
            var count = 0;
            var points = [];
            if (response.BND.Elements.count != null && response.BND.Elements.count != undefined) {
                //console.log(response);

                count = response.BND.Elements.count;

              //  console.log(response);
                var elements = response.BND.Elements.Element;

                elements.forEach(function (value, Index) {

                    var address = {
                        country: value.PostalAddress.Country,
                        city: value.PostalAddress.City,
                        postalCode: value.PostalAddress.PostalCode,
                        street: value.PostalAddress.Street,
                        district: value.PostalAddress.District,
                        countryCode: value.PostalAddress.CountryCode,
                        streetNumber: value.PostalAddress.StreetNumber
                    }
                    var point = new ngiWebMap.Point(value.Coordinate.x, value.Coordinate.y, value.PostalAddress.Street, "", "", address)
                    point.description = point.displayAddress;
                    points.push(point);
                })
            }
        }
        return {count: count, elements: points};
    }
    /**
     * formatting BND reverse geocoding response
     * @param response
     */
    this.formatRev4BatchResponse = function (response) {
        var count = 0;
        var points = [];
        if (typeof response == 'object') {
            var count = 0;

            var batchResults = [];
            if (response.BND.BatchResults != null && response.BND.BatchResults != undefined) {


                var bRes = response.BND.BatchResults;
                bRes.forEach(function (batch, index) {
                    var batchs = [];
                    var elements = batch.Elements.Element;
                    elements.forEach(function (value, Index) {

                        var address = {
                            country: value.PostalAddress.Country,
                            city: value.PostalAddress.City,
                            postalCode: value.PostalAddress.PostalCode,
                            street: value.PostalAddress.Street,
                            district: value.PostalAddress.District,
                            countryCode: value.PostalAddress.CountryCode,
                            streetNumber: value.PostalAddress.StreetNumber
                        }
                        var point = new ngiWebMap.Point(value.Coordinate.x, value.Coordinate.y, value.PostalAddress.Street, "", "", address)
                        point.description = point.displayAddress;
                        batchs.push(point);
                    })
                    batchResults.push(batchs);
                })
            }
        }
        return {count: batchResults.length, batchResults: batchResults};
    }
    /**
     * formatting BND Routing response
     * @param response
     */



    this.formatRoutingResponse = function (response) {
        var count = 0;
        var points = [];
        if (typeof response == 'object') {
            var count = 0;
            var points = [];
            if (response.BND.Routes.count != null && response.BND.Routes.count != undefined) {
                count = response.BND.Routes.count;
            }
            var routes = response.BND.Routes.Route;

            if (routes != undefined) {
                routes.forEach(function (value, Index) {

                    var road = {

                        type: value.Instructions.Type,
                        geoElementType: value.Instructions.geoElementType,
                        Coordinate: value.Instructions.Coordinate,
                        Duration: value.Instructions.Duration,
//                        Manoeuvre: value.Instructions.Manoeuvre,
                        Length: value.Instructions.Length,
                        Manoeuvre: value.Instructions.Manoeuvre,
                        ToName: value.Instructions.ToName,
                        ToOn: value.Instructions.ToOn,
                        ToRn: value.Instructions.ToRn,
                        ToSi: value.Instructions.ToSi


                    }
                    var point = new ngiWebMap.Point(value.Coordinate.x, value.Coordinate.y, value.Instructions.Length, "", "", Longueur)
                    point.description = point.displayLength;
                    points.push(point);

                })
            }
        }
        return {count: count, Routes: points};

    }
}

function processBNDError(error) {
    var formattedError = {};
    if (error != undefined) {
        if (error.errorCode == -1) {
            //erreur benomad
            if (error.errorDetails.BND.Exception._code == "ViaNotMatchException") {
                return {
                    errorCode: -1,
                    errorTitle: "Itinéraire indisponible",
                    errorDetails: "Itinéraire introuvable"
                }
            }
        } else if (error.errorCode == -2) {
            // erreur HTTP
            return {
                errorCode: -2,
                errorTitle: "Service indisponible",
                errorDetails: "Impossible de se connecter : Le service est temporairement indisponible. Veuillez réessayer dans quelques minutes."
            }
        } else {
            return {
                errorCode: -3,
                errorTitle: "Erreur inconnu",
                errorDetails: "erreur inconnu"
            }
        }
    }

}