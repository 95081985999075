var Meter = function(options){
    this.element = options.element || document.getElementById(options.id);
    this.type = options.type;
    this.unit = options.unit;
    this.title = options.title;
    this.lang = options.lang;
    this.terms = {
        fr : {
            "SPEED" : "Vitesse",
            "FUEL" : "Carburant",
            "ODO": "Kilomètrage",
            "ANGLE": "Angle",
            "ALTITUDE": "Altitude",
            "TEMPERATURE": "Température",
            "ON" : "ON",
            "OFF" : "OFF"
        },
        en: {
            "SPEED" : "Speed",
            "FUEL" : "Fuel",
            "ODO": "Distance",
            "ANGLE": "Angle",
            "ALTITUDE": "Altitude",
            "TEMPERATURE": "Temperature",
            "ON" : "ON",
            "OFF" : "OFF"
        }
    };

    this.lang = !this.lang ? 'en' : this.lang;


    options.value = typeof(options.value) === 'number' ?  options.value.toString() : options.value;
    options.max = typeof(options.max) === 'number' ?  options.max.toString() : options.max;
    options.min = typeof(options.min) === 'number' ?  options.min.toString() : options.min;

    this.value = this.element.dataset.value || options.value;
    this.max = this.element.dataset.max || options.max;
    this.min = this.element.dataset.min || options.min;
    if(!this.max || this.max === undefined){
        this.max = 100;
    }
    if(!this.min || this.min === undefined){
        this.min = 0;
    }

    this.show();

};

Meter.prototype.setValue = function (value) {
    value = typeof(value) === 'number' ?  value.toString() : value;
    this.value = value;

    this.show();
};

Meter.prototype.show = function(){
    var i = 0;
    var content = '';
    var percent = 0;
    var angle = 0;
    this.element.className = this.type;

    switch(this.type){
        case "odometer":
            if(!this.title){
                this.title = this.terms[this.lang]["ODO"];
            }
            if(this.value)
            for (i = 0; i < this.value.length; i++) {
                content += '<span class="num">' + this.value[i] + '</span>';
            }
            content += '<span class="unit">KM</span>';

            break;

        case "altimeter":
            percent = ((this.value - this.min) * 100) / (this.max - this.min);
            percent = percent > 100  ? 100 : percent;
            percent = percent < 0  ? 0 : percent;
            if(!this.title){
                this.title = this.terms[this.lang]["ALTITUDE"];
            }
            content += '<div class="meter">';
            for(i=0; i< 10; i++){
                if(i < percent/10){
                   if(i >= 7){
                       content += '<span class="altitude active"></span>';
                   }else if(i<7 && i>= 3){
                       content += '<span class="altitude active warning"></span>';
                   }else{
                       content += '<span class="altitude active danger"></span>';
                   }
                }else{
                    content += '<span class="altitude "></span>'
                }
            }
            content += '</div>';
            content += '<div class="info">';
            content += '<h2>'+this.title+'</h2>';
            content += '<h1>'+this.value+'<small>'+this.unit+'</small></h1>';
            content += '</div>';
            break;

        case "fuelmeter":
            percent = ((this.value - this.min) * 100) / (this.max - this.min);
            percent = percent > 100  ? 100 : percent;
            percent = percent < 0  ? 0 : percent;
            var margin = 100-percent;
            if(!this.title){
                this.title = this.terms[this.lang]["FUEL"];
            }
            content += '<div class="meter">';
            content += '<div class="barrel"><div class="droplet"></div><div class="wave -one" style="margin-top:'+margin+'px;"></div><div class="wave -two" style="margin-top:'+margin+'px;"></div><div class="wave -three" style="margin-top:'+margin+'px;"></div></div>';
            content += '</div>';
            content += '<div class="info">';
            content += '<h2>'+this.title+'</h2>';
            content += '<h1>'+this.value+'<small>'+this.unit+'</small></h1>';
            content += '</div>';
            break;

        case "thermometer":
            percent = ((this.value - this.min) * 100) / (this.max - this.min);
            if(!this.title){
                this.title = this.terms[this.lang]["TEMPERATURE"];
            }

             percent = percent > 100  ? 100 : percent;
             percent = percent < 0  ? 0 : percent;


            content += '<div class="meter">';
            content += '<div class="bg"><div class="circle"></div><div class="bar"><div class="indicator" style="height:'+percent+'%"></div></div></div>';
            content +='</div>';
            content += '<div class="info">';
            content += '<h2>'+this.title+'</h2>';
            content += '<h1>'+this.value+'<small>'+this.unit+'</small></h1>';
            content +='</div>';
            break;

        case "anglemeter":
            if(!this.title){
                this.title = this.terms[this.lang]["ANGLE"];
            }
            content += '<div class="meter">';
            content += '<div class="circle"><div class="indicator" style="transform:rotate('+(180-this.value)+'deg)"></div></div>';
            content += '</div>';
            content += '<div class="info">';
            content += '<h2>'+this.title+'</h2>';
            content += '<h1>' + Number(this.value).toFixed(2) + '<small>' + this.unit + '</small></h1>';
            content += '</div>';
            break;

        case "speedometer":
            if(!this.title){
                this.title = this.terms[this.lang]["SPEED"];
            }
        	if(this.value <=180){
                angle = (this.value * 1.5) - 122;
            }else{
                angle = (180 * 1.5) - 122;
            }

            content += '<div class="meter">';
            content += '<div class="slider"><span class="indicator" style="transform: rotate('+angle+'deg)"></span> <div class="info"> <h1 >'+this.value+'<small>'+this.unit+'</small></span></div></div>';
            content += '</div>';
            break;

        case "led":
            if(this.value)
            content = this.value.toString() === "true" ? '<div class="meter on"><span class="circle"></span><h2>' + this.terms[this.lang]['ON'] + '</h2></div>' : '<div class="meter off"><span class="circle"></span> <h2>' + this.terms[this.lang]['OFF'] + '</h2></div>';
            break;
    }

    this.element.innerHTML = content;

};