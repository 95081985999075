/**
 * Created by Mohamed Sadd on 11/05/2017.
 */


angular.module("app.services").factory('schedulingReportSrv', function ($http,$rootScope) {

    var service={};

    service.getSchedulingReportsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/scheduledReport/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getSchedulingReportsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/scheduledReport/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    service.getSchedulingReportById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/scheduledReport/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteSchedulingReportById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/scheduledReport/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response &&  response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.updateSchedulingReportById=function(id,report,success,error){
        $http.put($rootScope.config.API_URI+'/scheduledReport/'+id,report).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.addSchedulingReport=function(report,success,error){
        $http.post($rootScope.config.API_URI+'/scheduledReport/',report).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.GenerateThisReport=function(id,success,error){
        $http.post($rootScope.config.API_URI+'/scheduledReport/sendScheduledReport',{_id:id}).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    return service;

});
