angular.module('app')
    .controller('widgetEditCtrl', function ($scope, $rootScope,$timeout, dashboardSrv,assetSrv, mediaSrv) {

        $scope.widget = $scope.item;
        $scope.clearMapMarker = false;
        var index = $rootScope.currentDashboard._widgets.indexOf($scope.widget);
        $scope.assets=[];
        $scope.graphs = [
            "spline",
            "area",
            "areaspline",
            "column",
            "bar",
            "pie",
            "scatter",
            "gauge",
            "arearange",
            "areasplinerange",
            "columnrange"
        ];

        $scope.getInputs = function (device) {
            if(!device) return ;

            if(device && device.rt)
            var rt = Object.keys(device.rt);

            if(device && device.rt && device.rt.io)
            var io = Object.keys(device.rt.io);


            var filtered = [];
            //Removing _dt keys

            if(rt) {
                angular.forEach(rt, function (sensor) {
                    if (sensor !== 'io' && sensor !== 'uid') {
                        var parts = sensor.split('_');
                        var last = parts[parts.length - 1];
                        if (last !== "dt") {
                            if (sensor === 'loc') {
                                filtered.push({name: sensor.toUpperCase(), type: 'POSITION', src: 'rt.' + sensor});

                            } else {
                                filtered.push({
                                    name: sensor.toUpperCase(),
                                    type: typeof(device.rt[sensor]),
                                    src: 'rt.' + sensor
                                });

                            }
                        }
                    }
                });
            }

            if(io) {
                angular.forEach(io, function (sensor) {
                    var parts = sensor.split('_');
                    var last = parts[parts.length - 1];
                    if (last !== "dt") {
                        filtered.push({
                            name: sensor.toUpperCase(),
                            type: typeof(device.rt.io[sensor]),
                            src: 'rt.io.' + sensor
                        });
                    }
                });
            }

            return filtered;
        };

        $scope.styles = ["DARK", "LIGHT"];

        $scope.displays = ["POLYLINE", "HEATMAP", "CLUSTER"];
        $scope.periods = ["CUSTOM", "TODAY", "YESTERDAY","THIS_WEEK", "LAST_WEEK", "SEVEN_DAYS", "THIS_MONTH", "LAST_MONTH", "THIRTEEN_DAYS", "THIS_YEAR" ];


        $scope.inputs=[];
        if($scope.widget.type !== "IMAGE_MAP" && $scope.widget._assets && $scope.widget._assets.length>0){

            $scope.widget._assets.forEach(function(asset){

                if(asset.rt){

                    var inputs=$scope.getInputs(asset);
                    inputs.forEach(function(input){
                        if($scope.inputs.filter( function(elem){return elem.src === input.src}).length<=0){
                            $scope.inputs.push(input)
                        }
                    })
                }

            })
        }

        $scope.searchAsset=function($select){
            assetSrv.getAssetsList({query:{name:{'$regex' :$select.search, '$options' : 'i'}}},10,0,function(res) {
                if(res && res.success){
                    $scope.assets=res.result;

                }
            },function(err){
                console.log(err);
            })
        };

        if($scope.widget.type === "TABLE_HISTO" || $scope.widget.type === "GRAPH_HISTO" || $scope.widget.type === "MAP_HISTO"){
            var startDt = $scope.widget.extra.histo.startDt ? moment($scope.widget.extra.histo.startDt).clone() : moment().startOf("day").clone();
            var endDt = $scope.widget.extra.histo.endDt ? moment($scope.widget.extra.histo.endDt).clone() : moment().clone();
            $scope.pickerStart = {
                format : "DD/MM/YYYY HH:mm",
                init: startDt,
                position: "top left",
                autoTrans : false,
                enableTime : true,
                enableDate : true,
                enableMinutes : true,
                defaultView : "DATE",
                getDate : function (date) {
                    $scope.widget.extra.histo.startDt = date;
                    $scope.pickerEnd.minDate = date;
                }
            };

            $scope.pickerEnd = {
                format : "DD/MM/YYYY HH:mm",
                position: "top left",
                init: endDt,
                autoTrans : false,
                enableTime : true,
                enableDate : true,
                enableMinutes : true,
                defaultView : "DATE",
                getDate : function (date) {
                    $scope.widget.extra.histo.endDt = date;
                    $scope.pickerStart.maxDate = date;
                }
            };
        }

        $scope.setPeriod = function () {
           $rootScope.$emit('updatePeriod', $scope.widget.extra.histo.period);
        };


        $scope.input = $scope.inputs.filter(function (elem) {
            return elem.src === $scope.widget.input;
        })[0];

        $scope.setAsset = function () {

            if($scope.widget.type === 'IMAGE_MAP'){

                for(var i = 0; i < $scope.widget.extra.latlng.length; i++){
                    if($scope.widget._assets[i]){
                        $scope.widget.extra.latlng[i]._assetId = $scope.widget._assets[i]._id;
                    }
                }

              //  console.log( $scope.widget.extra.latlng)


            }else{
                $scope.inputs = [];
                if (!Array.isArray($scope.widget._assets)) {
                    $scope.inputs = $scope.getInputs($scope.widget._assets);
                } else if ($scope.widget._assets && $scope.widget._assets.length > 0) {
                    $scope.widget._assets.forEach(function (asset) {
                        var inputs = $scope.getInputs(asset);
                        inputs.forEach(function (input) {
                            if ($scope.inputs.filter(function (elem) {
                                    return elem.src === input.src
                                }).length <= 0) {
                                $scope.inputs.push(input)
                            }
                        })
                    })
                }
            }


        };

        $scope.setInput = function (input) {
            $scope.widget.input = input.src;
        };

        $scope.saveWidget = function () {

            if(!$scope.widget._id) $scope.widget._id=new ObjectId().toString();
            $rootScope.currentDashboard._widgets[index] = $scope.widget;
            //avoid populated data  without changing view
            var widgets=angular.copy($rootScope.currentDashboard._widgets);

            widgets= widgets.map(function(val){
                // check for populated asset
                if(!Array.isArray(val._assets)) {
                    val._assets=[val._assets]
                }
                if(val._assets && val._assets.length>0){
                    val._assets=val._assets.map(function(asset){
                        if(asset && asset._id) return asset._id;
                        else return  asset;
                    })

                }
                if(val._share && val._share.length>0){
                    val._share=val._share.map(function(share){
                        if(share && share._shared_by && share._shared_by._id) return share._shared_by._id;
                        else  return share._shared_by;
                    })

                }

                return  val;
            });
            dashboardSrv.updateDashboardById($rootScope.currentDashboard._id,{_widgets: widgets},function(res){
                if(res && res.success){

                    if($scope.opt && $scope.opt.onChange && typeof $scope.opt.onChange==='function'){
                        $scope.opt.onChange($rootScope.currentDashboard._widgets[index]);
                            $rootScope.$emit('updatePeriod', $scope.widget.extra.histo.period);

                    }

                }else{
                    console.error(res);

                }
            },function(err){
                console.error(err);

            })
            $scope.closeThisDialog();

        };

        $scope.uploadFiles = function(file, errFiles) {
            mediaSrv.uploadFiles([file], function (res) {
                if(res.success){
                    $scope.widget.extra = {};
                    $scope.widget.extra.image = res.result[0];

                    mediaSrv.downloadFile(res.result[0]._id, "test", function (response) {
                        var urlCreator = window.URL || window.webkitURL || window.mozURL;
                        $scope.imageUrl = urlCreator.createObjectURL(response.data);
                        var image = document.createElement('img');
                        image.src = $scope.imageUrl;
                        image.onload = function() {
                            var w = image.width;
                            var h = image.height;

                            var southWest = $scope.map.unproject([0, h], $scope.map.getMaxZoom()-1);
                            var northEast = $scope.map.unproject([w, 0], $scope.map.getMaxZoom()-1);
                            var bounds = new L.LatLngBounds(southWest, northEast);

                            if($scope.overlay)
                                $scope.map.removeLayer($scope.overlay);
                            else{
                                $scope.map.eachLayer(function (layer) {
                                    $scope.map.removeLayer(layer)
                                });
                            }

                            $scope.overlay = new L.imageOverlay($scope.imageUrl, bounds);
                            $scope.overlay.addTo($scope.map);
                            $scope.map.setMaxBounds(bounds);

                            if(!$scope.markers){
                                $scope.markers = new L.FeatureGroup();
                                $scope.markers.addTo($scope.map);
                                $scope.clearMapMarker = false;
                            }else{
                              $scope.markers.clearLayers();
                                $scope.clearMapMarker = false;
                            }


                            $scope.map.on('click', addMarker)
                        };
                    }, function (err) {
                        //console.log(err);
                    });

                }else{
                    console.log(res);
                }
            }, function (err) {
                console.log(err);
            }, function (progress) {

            });
        };


        $scope.clearMarker = function () {
            $scope.markers.clearLayers();
            $timeout(function () {
                $scope.clearMapMarker = false;
            });
            delete $scope.widget.extra.latlng;
            $scope.widget._assets = [];
        };

        $scope.getIndex = function (item) {
            return $scope.widget._assets.indexOf(item) + 1;
        };

        function addMarker(e) {
            if(!$scope.widget.extra.latlng){
                $scope.widget.extra.latlng = [];
            }
            $scope.widget.extra.latlng.push(e.latlng);
            var newMarker = new L.marker(e.latlng).bindTooltip('Marker '+($scope.markers.getLayers().length + 1), {permanent: true, interactive: false}).addTo($scope.markers);
            $scope.limit = $scope.markers.getLayers().length;

            $timeout(function () {
                $scope.clearMapMarker = true;
            });
        }

        $scope.cancelEdit = function () {
            console.log('exit');
            $scope.closeThisDialog();
        };

        if($scope.widget.type === "IMAGE_MAP"){

            $timeout(function () {

                // document.getElementById("mapDlg").outerHTML = "";
                var container = L.DomUtil.get("mapDlg");
                if (!container || container._leaflet_id) {
                    return;
                } else{

                    $scope.map = L.map("mapDlg", {
                        minZoom: 1,
                        maxZoom: 4,
                        center: [0, 0],
                        zoom: 2,
                        crs: L.CRS.Simple
                    });
            }
                if($scope.widget && $scope.widget.extra && $scope.widget.extra.image){
                    mediaSrv.downloadFile($scope.item.extra.image._id?$scope.item.extra.image._id:$scope.item.extra.image, "test", function (response) {
                        if(response && response.data) {
                            var urlCreator = window.URL || window.webkitURL || window.mozURL;
                            $scope.imageUrl = urlCreator.createObjectURL(response.data);
                            var image = document.createElement('img');
                            image.src = $scope.imageUrl;
                            image.onload = function () {
                                var w = image.width;
                                var h = image.height;


                                var southWest = $scope.map.unproject([0, h], $scope.map.getMaxZoom() - 1);
                                var northEast = $scope.map.unproject([w, 0], $scope.map.getMaxZoom() - 1);
                                var bounds = new L.LatLngBounds(southWest, northEast);


                                L.imageOverlay($scope.imageUrl, bounds).addTo($scope.map);
                                $scope.map.setMaxBounds(bounds);
                                $scope.markers = new L.LayerGroup();
                                $scope.markers.addTo($scope.map);


                                if ($scope.widget && $scope.widget.extra && $scope.widget.extra.latlng && $scope.widget.extra.latlng.length > 0) {
                                    $scope.limit = $scope.widget.extra.latlng.length;
                                    angular.forEach($scope.widget.extra.latlng, function (latLng) {
                                        if (latLng._assetId) {
                                            $scope.marker = new L.marker({
                                                lat: latLng.lat,
                                                lng: latLng.lng
                                            }).bindTooltip('<strong class="text-primary">' + ($scope.item._assets.filter(function (value) {
                                                    return value._id === latLng._assetId
                                                })[0] ? $scope.item._assets.filter(function (value) {
                                                    return value._id === latLng._assetId
                                                })[0].name : 'Unknown') + '</strong>', {
                                                permanent: true,
                                                interactive: false
                                            });
                                        } else {
                                            $scope.marker = new L.marker({
                                                lat: latLng.lat,
                                                lng: latLng.lng
                                            }).bindTooltip('<strong class="text-primary">Unknown</strong>', {
                                                permanent: true,
                                                interactive: false
                                            });
                                        }
                                        $scope.marker.addTo($scope.markers);
                                        $scope.map.panTo({lat: latLng.lat, lng: latLng.lng});
                                    });
                                    $scope.marker.addTo($scope.markers);
                                    $scope.clearMapMarker = true;
                                } else {
                                    $scope.widget.extra.latlng = [];
                                }

                                $scope.map.on('click', addMarker);

                            };
                        }else{
                            return;
                        }
                    }, function (err) {
                        console.log(err);

                    });
                }
            }, 500)
        }

    });