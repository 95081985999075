/**
 * Created by ngi02 on 12/02/2018.
 */

angular.module('app.directives').directive('histoDropdown', function ($rootScope) {

    return{
        templateUrl: "app/commons/directives/histoDropdown/histoDropdown.tpl.html",
        restrict: 'EA',
        replace:false,
        require :"^?widget",
        link: function (scope, element, attrs) {

            scope.style="top: 37px; left: -204px;position:absolute;z-index:99999";
           // scope.style="top: 37px; left: -245px";
            scope.start=null;
            scope.end=null;
            scope.dropOpen=false;
            var startDay = moment().startOf("day").clone();
            var now=  moment().clone();

            //  $(document).on('click.bs.dropdown.data-api', '.nav-link.dropdown-toggle', function (e) {


            scope.openCalendar= function () {


                var dpdown = element[0].querySelector('.dropdown');
                dpdown=$(dpdown);

               // console.log('click',dpdown,$(dpdown))
                if(scope.dropOpen==false)
                    dpdown.addClass('show'); // Opens the dropdown
                else if(scope.dropOpen==true)
                    dpdown.removeClass('show'); // close the dropdown

                scope.dropOpen=!scope.dropOpen;

            };


            scope.pickerStartM = {
                format : "DD/MM/YYYY HH:mm",
                init: null,
                position: "top left",
                autoTrans : false,
                enableTime : true,
                enableDate : true,
                enableMinutes : true,
                defaultView : "DATE",
                style:scope.style,
                getDate : function (date) {
                    scope.start = (date);
                    scope.pickerEndM.minDate = date;
                }
            };

            scope.pickerEndM = {
                format : "DD/MM/YYYY HH:mm",
                position: "top left",
                init: null,
                autoTrans : false,
                enableTime : true,
                enableDate : true,
                enableMinutes : true,
                defaultView : "DATE",
                style:scope.style,
                getDate : function (date) {
                    scope.end =  (date);
                    scope.pickerStartM.maxDate = date;
                }
            };

            scope.updateCalendar=function(item){
                if(scope.start !=null && scope.end !=null){

                    scope.item.extra.histo.period="CUSTOM";
                  //  console.log('=',moment(scope.endDt).diff(moment(scope.start), 'minute'))

                        scope.item.extra.histo.startDt=scope.start;
                        scope.item.extra.histo.endDt=scope.end;

                        scope.startDt = (scope.item.extra.histo.startDt!=null ? moment(scope.item.extra.histo.startDt).clone():startDay);
                        scope.endDt = (scope.item.extra.histo.endDt!=null ? moment(scope.item.extra.histo.endDt).clone():now);






                    $rootScope.$emit('updatePeriod',scope.item.extra.histo);
                    if(scope.opt && scope.opt.onChange && typeof scope.opt.onChange==='function'){
                        scope.opt.onChange(item);
                    }

                }

                //close dropdown
                $('.dropdown').removeClass('show');

                scope.dropOpen=!scope.dropOpen;


            }


        }
    }

});