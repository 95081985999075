/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

angular.module("app.services").factory('chargeSrv', function ($http,$rootScope,$translate) {

    var service={};

    service.getChargeList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/charge/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getChargeAgg1=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/charge/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getChargeById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/charge/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteChargeById=function(id,success,error){
        $http.delete($rootScope.config.API_URI+'/charge/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.updateChargeById=function(id,charge,success,error){
        $http.put($rootScope.config.API_URI+'/charge/'+id,charge).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data, status, headers, config) {
            error(data)

        };
    };
    service.add=function(driver,success,error){
        $http.post($rootScope.config.API_URI+'/charge/',driver).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.getChargeAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;



        $http.post($rootScope.config.API_URI+'/charge/aggregate',agg).then(function(response) {
            if (response && response.data){
                success(response.data);
            }
            else{
                error(response);
            }



        },function(data, status, headers, config) {
            error(data)

        });
    }





    service.updateMonthlyChart = function (id,title, subtitle,data, months) {
        var charOptions = {
            chart: {
                type: 'column'


            },
            title: {
                text:"<span class='ion-model-s'>"+" "+ title,
                useHTML: true


            },
            subtitle: {
                text:"<span class='ion-android-calendar'>"+" "+subtitle,
                useHTML: true
            },

            xAxis: {
                categories: months
            },

            yAxis: {
                min: 0,
                title: {
                    text: $translate.instant("TOTAL_PRICE")
                },
                stackLabels: {
                    enabled: true

                }

            },


            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal'

                }
            },
            series: data,

        };

        Highcharts.chart(id, charOptions);
    };


    service.updateTypeChart = function (id, title,subtitle,data, types) {
        var charOptions = {
            chart: {
                type: 'column'


            },
            title: {
                text:"<span class='ion-model-s'>"+" "+ title,

                useHTML: true


            },
            subtitle: {
                text:"<span class='ion-android-calendar'>"+" "+subtitle,
                useHTML: true
            },

            xAxis: {
                categories: types
            },

            yAxis: {
                min: 0,
                title: {
                    text: $translate.instant("TOTAL_PRICE")
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                    }
                }
            },


            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',

                }
            },
            series: data,

        };

        Highcharts.chart(id, charOptions);
    };

    service.initChart = function (id) {
        var charOptions = {
            title: {
                text: "",
                useHTML: true
            },

        };
        Highcharts.chart(id, charOptions);

    };


    return service;

});

