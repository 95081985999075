angular.module('app').controller('alertConfigEditCtrl', function ($scope, $rootScope,$timeout,assetSrv,alertConfigSrv,enumsSrv,$filter,$translate,Notification) {
    enumsSrv.getEnums(function (data) {
        if(data.success && data.result){
            $scope.ENUMS=data.result;
            $scope.CHECKERS=[];
            Object.keys($scope.ENUMS.ALERT_FUNCTION_MODEL).forEach(function(key){
                $scope.CHECKERS.push($scope.ENUMS.ALERT_FUNCTION_MODEL[key]);
            });
        } else {
            console.error(data.error)
        }
    }, function(err){
        console.error(err);
    });
    $scope.loadAlert = function (id) {
        var resultat={}
        var filteredData={}
        $timeout(function () {

            var query = {query: {'_id': id}, options: {populate: '_assetId'}};
            alertConfigSrv.getAlertConfigsList(query, 1, 0, function (res) {

                if (res.success && res.result && res.result.length > 0) {


                    resultat = res.result[0];


                    $scope.prepareFields(resultat._assetId);
                    //$scope.prepareConfig(resultat._id);



                    for(var i=0;i<resultat.config.length;i++) {

                        filteredData = $filter('filter')($scope.fields, resultat.config[i].data.field, true)
                        if (filteredData.length = 0) {

                            if (Array.isArray(resultat.config[i].data.value)){
                                $scope.fields.push({
                                    src: resultat.config[i].data.field,
                                    value_type: 'number'

                                })
                            }
                            else if ((typeof(resultat.config[i].data.value) === 'object')) {
                                $scope.fields.push({
                                    src: resultat.config[i].data.field,
                                    value_type: 'position'
                                })
                            } else {
                                $scope.fields.push({
                                    src: resultat.config[i].data.field,
                                    value_type: 'number'

                                })
                            }
                        }
                    }
                    $scope.alertConfg = res.result[0];
                    angular.forEach($scope.alertConfg.config,function (config) {
                        for(var i=0; i<$scope.fields.length; i++){
                            if(config.data.field === $scope.fields[i].src){
                                config.data.field = $scope.fields[i]
                            }
                        }
                    })
                    $scope.asset = angular.copy($scope.alertConfg._assetId);
                }

            }, function (err) {

            })
        },250);


    };
    $scope.searchAsset=function($select){
        assetSrv.getAssetsList({query:{name:{'$regex' :$select.search, '$options' : 'i'}}},10,0,function(res) {
            if(res && res.success){
                $scope.assets=res.result;
            }
        },function(err){
            console.log(err);
        })
    };
    $scope.loadAlert($scope.alertConfg._id)
    $scope.addCondition=function(){
        $scope.alertConfg.config.push({
        })
    };
    $scope. deleteConditionCfg=function(index){
        $scope.alertConfg.config.splice(index, 1);
    };
    $scope.updateChecker=function(config){
        if(config === undefined && config.data.field === undefined) {return}
        var filteredField = $filter('filter')($scope.CHECKERS, $scope.filterCheckerByFiledType(config.data.field.src),true);
        setTimeout(function(){
            if(filteredField && filteredField.length>0){
                if(filteredField.indexOf(config.checker)<=0){
                    config.checker=filteredField[0];
                    if(config.data.field==="UNDEFINED")
                        config.data={field:config.data.field,type:"SENSOR"};
                    else
                        config.data={field:config.data.field};
                }
            }else{
                console.log("NOT FOUND ", config ,filteredField[0] )
            }
            $scope.$apply();
        });
    };
    $scope.filterCheckerByFiledType = function (field) {
        return function (item) {
            if($scope.fields === undefined) {return}
            if($scope.fields && $scope.fields.length>0) {
                var valueType = $filter('filter')($scope.fields, {src: field}, true)[0].value_type;
                if (valueType === 'number') {
                    return (item.indexOf("GREATER") >= 0 || item.indexOf("LESS") >= 0 || item.indexOf("RANGE") >= 0)
                } else if (valueType === 'date') {
                    return (item.indexOf("TIME_INTERVAL") >= 0 )
                } else if (valueType === 'position') {
                    return ((item.indexOf("POLYGON") >= 0) || (item.indexOf("POI")>=0) || (item.indexOf("CORRIDOR")>=0))
                } else {
                    return true;
                }
            }else{
                return false;
            }
        }

    };
    $scope.prepareFields = function () {
        $scope.fields = [];
        if ($scope.ENUMS) {
            $scope.fields = $scope.ENUMS.ASSET_PROPERTIES;
        }
    };
    $scope.prepareConfig = function (item) {
        console.log('here')
        $scope.fields = []
        $scope.fields = $scope.ENUMS.ASSET_PROPERTIES;
        Object.keys(item.config).forEach(function (key) {
            if (key.field === 'loc') {
                $scope.fields.push({
                    name: "Location",
                    src: key,
                    value_type: "position"
                })
            }
            else if(key.field === 'io.spd' || key.field === 'io.alt' || key.field === 'io.ang' || key.field === 'io.con' || key.field === 'io.nb_sat' || key.field === 'io.dig1' || key.field === 'io.dig2' || key.field === 'io.dig3' || key.field === 'io.dig4' || key.field === 'io.odo' || key.field === 'odo' || key.field === 'UNDEFINED' ){
                $scope.fields.push({
                    name: "SPD",
                    src: key,
                    value_type: "number",
                })
            }

        })
    }
    $scope.addActionEmail=function(action,email){
        if(!action.data)
            action.data={}
        if(action.data && action.data.email)
            $scope.mails = action.data.email.split(";")
        else {
            action.data={email:""}
            $scope.mails = [];
        }
        if(validateEmail(email)) {

            if($scope.mails.indexOf(email)>=0){
                Notification.error($translate.instant("EMAIL_ALREADY_EXIST"));
            }else{
                $scope.mails.push(email);
                $scope.email = "";
                action.data.email = $scope.mails.join(';')
            }
        }else{
            Notification.error($translate.instant("NO_RECIPIENT"));
        }

    };
    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    $scope.removeMail = function (data,index) {
        var emails = data.split(';')
        emails.splice(index, 1);
        angular.forEach($scope.alertConfg.actions,function(action){
            if (action.type === "EMAIL"){
                action.data.email = emails.join(';')
            }
        })
    }
    $scope.removeActionEmail = function(action,mail){
        var index = action.data.email.indexOf(mail);
        console.log(index)
        if (index > -1) {
            action.data.email.splice(index, 1);
            action.data.email = action.data.email.join(';')
        }
        console.log(action)
    };
    $scope.saveAlert=function(){
        angular.forEach($scope.alertConfg.config,function(val){
            val.data.field = val.data.field.src
        });
        alertConfigSrv.updateAlertConfigById($scope.alertConfg._id,$scope.alertConfg,function(res){
            if(res && res.success){
                Notification.success($translate.instant("ALERT_CONFIG_UPDATED",{name: $scope.alertConfg.name}));
                $scope.closeThisDialog();
            }
            else{
                console.log('Alert error')
            }
        } ,function(err){
            console.log('error')
        })
    }
});