

angular.module("app").controller('deviceEditCtrl',function ($scope,$rootScope, $state,assetTypeSrv,assetSrv, deviceSrv,$stateParams,ngDialog,Notification,deviceTypeSrv,$translate) {
$scope.editAsset=false;
    $scope.sigfox={};
    $scope.NewAsset={};

    /*get device by id*/
    deviceSrv.getDevicesList({
        query:{_id:$stateParams.deviceId},
        options:{populate:["_asset"]}

    },1,0,function (data) {
        if (data.success) {
            $scope.device = angular.copy(data.result[0]);
            $scope.assets=$scope.device._asset;


           if($scope.assets && $scope.assets.length>0)
            $scope.asset=$scope.assets[0];
            else
               $scope.asset=null;

        } else {
            console.error(data.error)
        }

    }, function (data) {
        console.log(data)

    }) ;


    /* asset types */
    assetTypeSrv.getAssetTypesList({},
        100, 1,
        function (data) {
            if (data.success) {
                $scope.assetTypes= data.result;

                $scope.assetTypes=$scope.assetTypes.map(function(val){
                    return val._id
                });

            } else {
                console.error(data.error)
            }


        }, function (data) {
            console.log(data)

        })


    /* device types */

  deviceTypeSrv.getDeviceTypesList({},10000,0,function(data) {
      if (data && data.success) {
          $scope.deviceTypesList = data.result;

          $scope.deviceTypesList = $scope.deviceTypesList.map(function (val) {
              return val._id
          });
      } else {
          console.error(data.error)
      }
  }, function (data) {
      console.log(data)

  });



  /*update device*/
  $scope.UpdateDevice=function(){
    deviceSrv.updateDeviceById($stateParams.deviceId,$scope.device,function(data){
        if(data && data.success){



                         if($scope.asset && $scope.asset._id) {
                            assetSrv.updateAssetById($scope.asset._id, $scope.asset, function (data) {

                                if (data.success) {
                                    $state.go("app.device");
                                    Notification($translate.instant('DEVICE_UPDATED'));
                                    $scope.device._asset[0]=$scope.asset;
                                    $scope.dev=angular.copy($scope.device)

                                    $rootScope.$emit('deviceEdited', $scope.device)

                                } else {
                                    console.log(data.error)
                                }

                            });
                         }else if($scope.asset && !$scope.asset._id){

                           $scope.AddAssetToDevice($scope.asset);

                             $state.go("app.device");
                             Notification($translate.instant('DEVICE_UPDATED'));
}

        }else{
            console.log(data.error)
        }
    },function(error){
      if (error){
        console.log(error);
        $state.go("app.device")}
    })
  };


    $scope.editAssetMode=function(){
        $scope.editAsset = !$scope.editAsset

    };


    $scope.AddAssetToDevice = function (asset) {

        if(asset)
            $scope.NewAsset=asset;

        $scope.NewAsset._dev=$stateParams.deviceId;

        assetSrv.add($scope.NewAsset, function (data) {
            if (data.success) {

                $scope.device._asset[0]= angular.copy(data.result);
                $scope.asset=$scope.device._asset[0];

                deviceSrv.updateDeviceById($stateParams.deviceId,$scope.device,function(data){

                    if (data.success) {

                        if ($state.current.name === "app.device.edit") {
                           // Notification($translate.instant('ASSET_ADDED'));
                            $scope.device._asset[0]=$scope.asset;
                            $scope.dev=angular.copy($scope.device)

                            $rootScope.$emit('deviceEdited',$scope.device)
                           // $state.reload();
                        }

                    }else {
                        console.log(data.error)
                    }
                }, function (err) {

                })

            }else {
                console.log(data.error)
            }
        }, function (err) {

        })

    };


    $scope.deleteAssetFromDevice= function(index){
        $scope.assets.splice(index,1);
        $scope.device._assets=$scope.assets;
    };

    $scope.cancelDevice = function () {

        if ($state.current.name === "app.device.edit") {
            $state.go('app.device');
        }
        else {
            ngDialog.closeAll();
        }
    }

/* add TypeSigfox */

    $scope.ajouterTypeSigfox=function(){


        var modal=  ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/confirm.sigfox.dlg.html",
            controller: "sigfoxCtrl",
            scope: $scope

        });

        modal.closePromise.then(function(data) {
            if (data && data.value && data.value._id && data.value._id.length>0){

                $scope.deviceTypesList.push(data.value._id)
                $scope.device.model=data.value._id;
            }
        });



    }

});
