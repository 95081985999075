/**
 * Project: ngiTracking
 * Created by NGI team 21/04/2017.
 */
angular.module("app.services").factory('geoDataSrv', function ($http,$rootScope) {

    var service={};
    service.getGeoDataList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/geodata/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function (data) {
            error(data)


        });
    };
    service.findBulkNear=function(searchQuery,success,error){



        //$rootScope.config.API_URI
        $http.post($rootScope.config.API_URI+'/geodata/bulk_near',searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function (data) {
            error(data)


        });
    };

    service.getGeoDataAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/geodata/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.getGeoDataById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/geodata/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteGeoDataById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/geodata/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }).error(function(data) {
            error(data)

        });
    };

    service.updateGeoDataById=function(id,geodata,success,error){
        $http.put($rootScope.config.API_URI+'/geodata/'+id,geodata).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(geodata,success,error){
        $http.post($rootScope.config.API_URI+'/geodata/',geodata).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            //console.log(response)
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.import=function(data,success,error){
        $http.post($rootScope.config.API_URI+'/geodata/import',data).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            console.log(response)
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    return service;

});
