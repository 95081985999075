/**
 * Created by Hamdi on 12/01/2018.
 */
angular.module('app').controller('sidebarDeviceCtrl', function ($scope,$stateParams,$rootScope,deviceSrv,ngDialog,Notification,$translate) {

    $scope.deviceList=[];
    $scope.SearchedId='';

    $scope.nomoreresult = false;
    $scope.start = 0;

    $scope.loadMoreDevices = function() {

        $scope.start = $scope.start + 1;
        var query = {};

        var    options={
            populate: [
                {
                    path: '_asset'
                    // select: 'name'
                }
            ]
        }

        if($scope.SearchedId && $scope.SearchedId.length>0){

            query["_id"]={'$regex': '^'+$scope.SearchedId, '$options' : 'i'}

        }

        if(!$scope.nomoreresult){
            deviceSrv.getDevicesList({query: query, options: options },20, $scope.start,function (data) {
                    if (data.success && data.result && data.result.length>0) {

                        angular.forEach(data.result,function (element) {
                            $scope.deviceList.push(element)
                        })
                    } else {
                        $scope.nomoreresult = true
                    }


            }, function (err) {
                console.log(err)
            })
        }
    };


    $scope.showDevice=function(dev){
       // console.log('clicked')
        if(dev){
            $rootScope.$emit('deviceDetails',dev)
        }

    }

    $scope.filterDevices=function(){
      $scope.start=0;
        $scope.deviceList=[];
        $scope.nomoreresult = false;
        $scope.loadMoreDevices();


    }




    $scope.removeDevice=function(device,index){
        ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm/confirm.dlg.html',overlay:true ,showClose:false,controller: ['$scope', function($scope) {
            // controller logic
            $scope.title=$translate.instant("DEVICE_DELETE_CONFIRM_TITLE");
            $scope.message=$translate.instant("DEVICE_DELETE_CONFIRM_MSG",{device:device._id?device._id:null})
        }] }).then(function () {
            deviceSrv.deleteDeviceById(device._id,function(data){
                if(data.success) {
                    $scope.deviceList.splice(index,1);
                    Notification($translate.instant("DEVICE_DELETED", {device:device._id?device._id:null}));

                }else {
                    console.log(data.error)
                }
            },function(err){

            })
        }, function (reason) {

        });
    };






    $rootScope.$on('deviceEdited',function(event,data) {

          if(data)
        angular.forEach($scope.deviceList,function (element,index) {
           if(element._id==data._id){
               $scope.deviceList[index]=data
           }else{
               $scope.deviceList.push(data);
           }

        })

    });







});
