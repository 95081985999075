/**
 * Created by Kais CHAIBI on 16/02/2018.
 */
angular.module('app.directives').directive('popupActions', function (Notification,ngDialog) {
    return {

        scope: {
            parentSelector: "@"
        },
        link: function (scope, elem, attr) {
            angular.element(elem[0]).bind('click',  function(e){
                e.stopPropagation();
                angular.element(this).parent().toggleClass('open');



                var parentWidth=$(this).parent().outerWidth();
                var scrollHeight= $(this).parent().scrollTop();
                var leftPosition =$(this).parent().position().left;
                var topPostion =$(this).parent().offset().top + (($(this).parent().height() -$(this).height())/2 );


                $(this).next().css({left:leftPosition+parentWidth+"px",top:topPostion});
                angular.element(this).parent().siblings().removeClass('open');

            });

            angular.element(document).on('mouseup', function(e){
                if(angular.element(e.target).is('#toggleActions *')){
                    //angular.element(".dashboard-item").removeClass('open');
                }else{
                    angular.element(".dashboard-item").removeClass('open');
                }

            });
        }
    }
})