/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('companySrv', function ($http,$rootScope) {

    var service={};
    service.getCompaniesList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/company/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

          if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function (data) {
          error(data)


        });
    }
    service.getCompaniesAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/company/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    }

    service.getCompanyById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/company/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    }
    service.deleteCompanyById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/company/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }).error(function(data) {
            error(data)

        });
    }

    service.updateCompanyById=function(id,company,success,error){
        $http.put($rootScope.config.API_URI+'/company/'+id,company).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    }
    service.add=function(company,success,error){
        $http.post($rootScope.config.API_URI+'/company/',company).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    }
    return service;

});
