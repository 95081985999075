angular.module('app').controller('adminDashboardAddCtrl', function ($scope,$rootScope, $state,dashboardSrv, $translate, ngDialog, Notification) {
    $scope.dashboard = {};
    $scope.dashboard.enabled = true;
    /**
     * Save dashboard
     */
    $scope.save = function () {
        dashboardSrv.add($scope.dashboard, function (response) {
            if(response.success){
                Notification.success({
                    title : $translate.instant("ADD"),
                    message : $translate.instant("ALERT_DASHBOARD_ADDED",{first:$scope.dashboard.name})
                });
                $scope.loadDashboards();
                $state.go('app.admin.dashboard');
                $scope.dashboard  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.dashboard.name +"!"
                });
                $scope.dashboard  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })
    };


    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.dashboard = {};
        $state.go('app.admin.dashboard');
    }

});