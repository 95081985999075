angular.module('app.dialogs').controller('confirmDeleteDlgCtrl', function ($scope, $rootScope, ngDialog,dashboardSrv) {

    $scope.confirm = function () {
        $rootScope.currentDashboard._widgets.splice($rootScope.currentDashboard._widgets.indexOf($scope.item),1);
        dashboardSrv.updateDashboardById($rootScope.currentDashboard._id,{_widgets: $rootScope.currentDashboard._widgets},function(res){
            if(res && res.success){

                $scope.closeThisDialog();
            }else{
                console.error(res);
            }

        },function(err){
            console.error(err);
        })


    }

});