/**
 * Created by Ahmed Alaya on 03/08/2017.
 */

angular.module("app.services").factory('FileInputService',function ($q) {

    var service={};

    service.readFileAsync = function (file) {
        var deferred = $q.defer(),
            fileReader = new FileReader(),
            fileName = file.name,
            fileType = file.type,
            fileSize = file.size;
        lastModified = file.lastModified;
        lastModifiedDate = file.lastModifiedDate;
        fileReader.readAsText(file,'ISO-8859-4');


        fileReader.onload = function (e) {
            deferred.resolve(e.target.result);
        };
        return deferred.promise;
    };
    return service;

});