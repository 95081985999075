angular.module('app').controller('adminCtrl', function ($rootScope, $scope,companySrv,roleSrv) {
    roleSrv.getRolesList({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.roles = response.result;
        }
    });
    companySrv.getCompaniesList({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.companies = response.result;
        }
    });
});