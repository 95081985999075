/**
 * Project: ngiTracking
 * Created by NGI team 21/04/2017.
 */

angular.module("app.services").factory('geoDataTypeSrv',function ($http,$rootScope) {

    var service={};

    service.getGeoDataInputTypes = function () {
        return [
            {
                name:"TEXTFIELD",
                value: "textfield"
            },{
                name:"EMAIL",
                value: "email"
            },{
                name:"PASSWORD",
                value: "password"
            },{
                name:"RADIOBUTTONS",
                value: "radiobuttons"
            },{
                name:"DROPDOWNLIST",
                value: "dropdownlist"
            },{
                name:"DATE",
                value: "date"
            },{
                name:"GEOMETRY",
                value: "geometry"
            },{
                name:"TEXTAREA",
                value: "textarea"
            },{
                name:"CHECKBOX",
                value: "checkbox"
            },{
                name:"HIDDEN",
                value: "hidden"
            }
        ]
    };

    service.getGeoDataTypesList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/geodatatype/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getGeoDataTypesAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/geodatatype/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    service.getGeoDataTypeById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/geodatatype/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteGeoDataTypeById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/geodatatype/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.updateGeoDataTypeById=function(id,geodatatype,success,error){
        $http.put($rootScope.config.API_URI+'/geodatatype/'+id,geodatatype).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(geodatatype,success,error){
        $http.post($rootScope.config.API_URI+'/geodatatype/',geodatatype).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    return service;

});
