/**
 * Project: ngiTracking
 * Created by Mohamed Saad 15/06/2017.
 */
angular.module("app.services").factory('deviceConfigSrv', function ($http,$rootScope) {

    var service={};

    service.getDeviceConfigList=function(searchQuery,limit,page,success,error){
        var l=5;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/deviceConfig/query?limit='+l+'&page='+p,searchQuery).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            console.log('response',angular.copy(response))
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data, status, headers, config) {
            error(data)

        };
    }
    service.getDeviceConfigAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/deviceConfig/agg?limit='+l+'&page='+p,agg).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data, status, headers, config) {
            error(data)

        };
    }


    service.getDeviceConfigById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/deviceConfig/'+id).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data, status, headers, config) {
            error(data)

        };
    }
    service.deleteDeviceConfigById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/deviceConfig/'+id).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data, status, headers, config) {
            error(data)

        };
    }

    service.updateDeviceConfigById=function(id,device,success,error){
        $http.put($rootScope.config.API_URI+'/deviceConfig/'+id,device).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data, status, headers, config) {
            error(data)

        };
    }
    service.add=function(device,success,error){
        $http.post($rootScope.config.API_URI+'/deviceConfig/',device).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data, status, headers, config) {
            error(data)

        };
    }
    return service;

});
