angular.module('app.dialogs').controller('forgetDlgCtrl', function ($rootScope,$scope, $state,loginSrv,ngDialog,Notification) {

    /*Reset password*/
    $scope.sendMail = function () {
        var userData ={email:$scope.user.contact.email};
        loginSrv.forgetPassword(userData, function (data) {
            if (data.success) {
                Notification('Mail sent, Please check your email');
                ngDialog.closeAll();
            }else{
                Notification.error('Email is not sent, please check your information');
            }

        }), function (error) {
            if (error) {
                Notification('erreur');
                console.log(error);
            }
        };

    };

});