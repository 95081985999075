
    angular.module('app').controller('sidebarAdminCtrl', function ($scope, $rootScope, $state) {
        $scope.listMenu = [];
        $scope.loadMenu = function () {
            var tab = [

                {
                    name: "ACCOUNTS",
                    permission: $rootScope.checkPermission('COMPANY', 'R', 'X'),
                    route: 'app.admin.account',
                    icon: 'fa fa-users'
                },

                {
                    name: "USER",
                    permission: $rootScope.checkPermission('USER', 'R'),
                    route: 'app.admin.user',
                    icon: 'fa fa-user'
                },
                {
                    name: "DASHBOARD",
                    permission: $rootScope.checkPermission('DASHBOARD', 'R'),
                    route: 'app.admin.dashboard',
                    icon: 'fa fa-tachometer'
                }
            ];

            angular.forEach(tab, function (item) {
                if (item.permission) {
                    $scope.listMenu.push(item);
                }
            });
            if ($scope.listMenu && $scope.listMenu.length > 0) {
                $state.go($scope.listMenu[0].route);
            }
        };
        $scope.loadMenu();
    });

