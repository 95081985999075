angular.module('app.dialogs')
    .controller('shareDashboardDlg', function ($scope, $rootScope, userSrv, dashboardSrv, Notification) {

        $scope.share = {};
        $scope.users = [];

        $scope.searchUser=function($select){
            userSrv.getUsersList({query:{$or : [{'first_name':{'$regex' :$select.search, '$options' : 'i'}}, {'last_name':{'$regex' :$select.search, '$options' : 'i'}}]}},10,0,function(res) {
                if(res && res.success){
                    $scope.users=res.result;

                }
            },function(err){
                console.log(err);
            })
        };


        var now = moment().clone();

        $scope.share.share_begin_dt = now;
        $scope.pickerStart = {
            format : "DD/MM/YYYY HH:mm",
            position: "top left",
            init: now,
            autoTrans : false,
            enableTime : true,
            enableDate : true,
            enableMinutes : true,
            defaultView : "DATE",
            getDate : function (date) {
                $scope.share.share_begin_dt = date;
                $scope.pickerEnd.minDate = date;
            }
        };
        $scope.pickerEnd = {
            format : "DD/MM/YYYY HH:mm",
            position: "top left",
            init: null,
            autoTrans : false,
            enableTime : true,
            enableDate : true,
            enableMinutes : true,
            defaultView : "DATE",
            getDate : function (date) {
                $scope.share.share_end_dt = date;
                $scope.pickerStart.maxDate = date;
            }
        };

        $scope.save = function () {
            $scope.sharedDashboard._share.push($scope.share);
            dashboardSrv.updateDashboardById($scope.sharedDashboard._id, $scope.sharedDashboard, function (res) {
                if(res.success){
                    Notification.success({
                        title: "Sharing added",
                        message : "Dashboard sharing was added successfully !"
                    });
                    $scope.closeThisDialog();
                }else{
                    Notification.error({
                        title: "Sharing failed",
                        message : "An error occurred while sharing dashboard"
                    });
                    $scope.closeThisDialog();
                }
            }, function (err) {
                Notification.error({
                    title: "Sharing failed",
                    message : "An error occurred while sharing dashboard"
                });
                console.log(err);
                $scope.closeThisDialog();
            })

        }
    });