angular
    .module('app.dialogs')
    .controller('widgetDlgCtrl', function ($scope, $rootScope,$timeout, dashboardSrv, assetSrv,ObjectId, Upload, mediaSrv,$state) {

        $scope.clearMapMarker = false;
        $scope.inputs = [];
        $scope.graphs = [
            "spline",
            "area",
            "areaspline",
            "column",
            "bar",
            "pie",
            "scatter",
            "gauge",
            "arearange",
            "areasplinerange",
            "columnrange"
        ];
        $scope.styles = ["DARK", "LIGHT"];
        $scope.displays = ["POLYLINE", "HEATMAP", "CLUSTER"];
        $scope.periods = ["CUSTOM", "TODAY", "YESTERDAY","THIS_WEEK", "LAST_WEEK", "SEVEN_DAYS", "THIS_MONTH", "LAST_MONTH", "THIRTEEN_DAYS", "THIS_YEAR" ];

        $scope.style = "DARK";

        $scope.getInputs = function (device) {
            if (!device) return;

            if(device && device.rt)
                var rt = Object.keys(device.rt);

            if(device && device.rt && device.rt.io)
                var io = Object.keys(device.rt.io);

            var filtered = [];
            //Removing _dt keys
            angular.forEach(rt, function (sensor) {
                if (sensor !== 'io' && sensor !== 'uid') {
                    var parts = sensor.split('_');
                    var last = parts[parts.length - 1];
                    if (last !== "dt") {
                        if (sensor === 'loc') {
                            filtered.push({name: sensor.toUpperCase(), type: 'POSITION', src: 'rt.' + sensor});

                        } else {
                            filtered.push({
                                name: sensor.toUpperCase(),
                                type: typeof(device.rt[sensor]),
                                src: 'rt.' + sensor
                            });

                        }
                    }
                }
            });
            angular.forEach(io, function (sensor) {
                var parts = sensor.split('_');
                var last = parts[parts.length - 1];
                if (last !== "dt") {
                    filtered.push({
                        name: sensor.toUpperCase(),
                        type: typeof(device.rt.io[sensor]),
                        src: 'rt.io.' + sensor
                    });
                }
            });


            return filtered;
        };

        $scope.getIndex = function (item) {
            return $scope.widget._assets.indexOf(item) + 1;
        };

        $scope.setAsset = function () {

            if($scope.widget.type === 'IMAGE_MAP'){

                for(var i = 0; i < $scope.widget.extra.latlng.length; i++){
                    if($scope.widget._assets[i]){
                        $scope.widget.extra.latlng[i]._assetId = $scope.widget._assets[i]._id;
                    }
                }

                console.log( $scope.widget.extra.latlng)


            }else{
                $scope.inputs = [];
                if (!Array.isArray($scope.widget._assets)) {
                    $scope.inputs = $scope.getInputs($scope.widget._assets);
                } else if ($scope.widget._assets && $scope.widget._assets.length > 0) {
                    $scope.widget._assets.forEach(function (asset) {
                        var inputs = $scope.getInputs(asset);
                        inputs.forEach(function (input) {
                            if ($scope.inputs.filter(function (elem) {
                                    return elem.src === input.src
                                }).length <= 0) {
                                $scope.inputs.push(input)
                            }
                        })
                    })
                }
            }

        };

        if ($scope.widget.type === "SPEEDOMETER" || $scope.widget.type === "MAP" || $scope.widget.type === "IMAGE_MAP" || $scope.widget.type === "GRAPH" || $scope.widget.type === "TABLE_HISTO" || $scope.widget.type === "GRAPH_HISTO" || $scope.widget.type === "MAP_HISTO") {
            $scope.widget.properties={};
            $scope.widget.properties.sizeX = 2;
            $scope.widget.properties.sizeY = 2;
            $scope.widget.properties.minSizeX = 2;
            $scope.widget.properties.minSizeY = 2;
        }else{
            $scope.widget.properties={};
            $scope.widget.properties.sizeX = 2;
            $scope.widget.properties.sizeY = 1;
            $scope.widget.properties.minSizeX = 2;
            $scope.widget.properties.minSizeY = 1;
        }

        if($scope.widget.type === 'STRING' || $scope.widget.type === "IMAGE_MAP" || $scope.widget.type === 'ALTIMETER' || $scope.widget.type === 'THERMOMETER' || $scope.widget.type === 'FUEL' || $scope.widget.type === 'GRAPH'){
            $scope.widget.extra = {};
        }

        $scope.setInput = function (input) {
            $scope.widget.input = input.src;
        };

        $scope.searchAsset = function ($select) {
            assetSrv.getAssetsList({
                query: {
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                if (res && res.success) {
                    $scope.assets = res.result;
                }
            }, function (err) {
            })
        };

        $scope.saveWidget = function () {



            if(!$scope.widget._id) $scope.widget._id=new ObjectId().toString();
            $scope.dropTo._widgets.push($scope.widget);
            dashboardSrv.updateDashboardById($scope.dropTo._id, {_widgets: $scope.dropTo._widgets}, function (res) {
                if (res && res.success) {

                    $scope.widget = {};
                    $scope.dropTo = {};

                } else {
                    console.error(res);
                }

            }, function (err) {
                console.error(err);
            })


            $scope.closeThisDialog();

        };

        $scope.cancelEdit = function () {
            $scope.closeThisDialog();
        };
        $scope.clearMarker = function () {
            $scope.markers.clearLayers();
            $timeout(function () {
                $scope.clearMapMarker = false;
            });
            delete $scope.widget.extra.latlng;
            $scope.widget._assets = [];
        };

        function addMarker(e) {
            $scope.widget.extra.latlng.push(e.latlng);
            var newMarker = new L.marker(e.latlng).bindTooltip('Marker '+($scope.markers.getLayers().length + 1), {permanent: true, interactive: false}).addTo($scope.markers);
            $scope.limit = $scope.markers.getLayers().length;
            $timeout(function () {
                $scope.clearMapMarker = true;
            });
        }

        if($scope.widget.type === "MAP_HISTO" || $scope.widget.type === "GRAPH_HISTO" || $scope.widget.type === "TABLE_HISTO"){
            $scope.widget.extra = {};
            $scope.widget.extra.histo = {};

            var startDt = $scope.widget.extra.histo.startDt ? moment($scope.widget.extra.histo.startDt).clone() : moment().startOf('day').clone();
            var endDt = $scope.widget.extra.histo.endDt ? moment($scope.widget.extra.histo.endDt).clone() : moment().clone();
            $scope.pickerStart = {
                format : "DD/MM/YYYY HH:mm",
                init: startDt,
                position: "top left",
                autoTrans : false,
                enableTime : true,
                enableDate : true,
                enableMinutes : true,
                defaultView : "DATE",
                getDate : function (date) {
                    $scope.widget.extra.histo.startDt = (date!=null?date:startDt);
                    $scope.pickerEnd.minDate = date;
                }
            };

            $scope.pickerEnd = {
                format : "DD/MM/YYYY HH:mm",
                position: "top left",
                init: endDt,
                autoTrans : false,
                enableTime : true,
                enableDate : true,
                enableMinutes : true,
                defaultView : "DATE",
                getDate : function (date) {
                    $scope.widget.extra.histo.endDt =  (date!=null?date:endDt);
                    $scope.pickerStart.maxDate = date;
                }
            };
        }

        if($scope.widget.type === "MAP_HISTO"){
            $scope.widget.extra.histo.displayType = "POLYLINE";
        }



        $scope.uploadFiles = function(file, errFiles) {
            mediaSrv.uploadFiles([file], function (res) {
                if(res.success){
                    $scope.widget.extra = {};
                    $scope.widget.extra.image = res.result[0];

                    mediaSrv.downloadFile(res.result[0]._id, "test", function (response) {
                        var urlCreator = window.URL || window.webkitURL || window.mozURL;
                        $scope.imageUrl = urlCreator.createObjectURL(response.data);
                        var image = document.createElement('img');
                        image.src = $scope.imageUrl;
                        image.onload = function() {
                            var w = image.width;
                            var h = image.height;

                            var southWest = $scope.map.unproject([0, h], $scope.map.getMaxZoom()-1);
                            var northEast = $scope.map.unproject([w, 0], $scope.map.getMaxZoom()-1);
                            var bounds = new L.LatLngBounds(southWest, northEast);
                            $scope.map.removeLayer($scope.overlay);
                            $scope.overlay = new L.imageOverlay($scope.imageUrl, bounds);
                            $scope.overlay.addTo($scope.map);
                            $scope.map.setMaxBounds(bounds);
                            if(!$scope.markers){
                                $scope.markers = new L.layerGroup();
                                $scope.markers.addTo($scope.map);
                                $scope.clearMapMarker = false;
                            }else{
                                $scope.markers.clearLayers();
                                $scope.clearMapMarker = false;
                            }
                            $scope.widget.extra.latlng = [];

                            $scope.map.on('click', addMarker)

                             };
                    }, function (err) {
                        //console.log(err);
                    });

                }else{
                    console.log(res);
                }
            }, function (err) {
                console.log(err);
            }, function (progress) {

            });
        };

        if($scope.widget.type === "IMAGE_MAP"){
            $timeout(function () {
                $scope.map = L.map("mapDlg", {
                    minZoom: 1,
                    maxZoom: 4,
                    center: [0, 0],
                    zoom: 2,
                    crs: L.CRS.Simple
                });

                var w = 600;
                var h = 400;

                var southWest = $scope.map.unproject([0, h], $scope.map.getMaxZoom()-1);
                var northEast = $scope.map.unproject([w, 0], $scope.map.getMaxZoom()-1);
                var bounds = new L.LatLngBounds(southWest, northEast);

                $scope.overlay = new L.imageOverlay("app/assets/images/placeholder.png", bounds);
                $scope.overlay.addTo($scope.map);
                $scope.map.setMaxBounds(bounds);
            }, 1000)
        }


    });