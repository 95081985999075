angular.module('app.widgets').directive('speedometerWdg', function ($rootScope) {
    return{
        templateUrl: "app/commons/widgets/speedometer/speedometer.tpl.html",
        scope : {
            item : "=",
            options : "="
        },
        link: function (scope, elem, attr) {

            function getValue(device, input) {
                var val = "";

                if(input)
                var parts = input.split('.');

                if(device && device.rt && parts && parts.length === 2){
                    val = device.rt[parts[1]];
                }else {
                    if(device && device.rt && device.rt.io && parts)
                    val = device.rt.io[parts[2]];
                }
                return val;

            }

           if(scope.item && scope.item._assets && scope.item._assets.length>0 )
            scope.val = getValue(scope.item._assets[0], scope.item.input);

            var speedometer=null;
            angular.element(document).ready(function () {
                  speedometer = new Meter({element: elem.children()[0], lang: 'fr', type: 'speedometer', unit: 'km/h'});
            })

            /**
             * Function to be called from outside directive to update widget
             */
            angular.extend(scope.options,{onChange:function(cfg){


                if(cfg && cfg._id){
                    scope.item=cfg;
                    if(scope.item._assets && scope.item._assets.length>0) {
                        scope.val = getValue(scope.item._assets[0], scope.item.input);
                        if(speedometer)
                            speedometer.setValue(scope.val);
                    }
                }


            }});
            /**
             * listen  for  newMsg and  update value
             * @type {function(): void|*|function()}
             */
            var listener =$rootScope.$on("newMsg",function(event,args){
                if(args && args._id){
                    if(scope.item._assets && scope.item._assets.length>0 && scope.item._assets[0]._id==args._id) {
                        scope.item._assets[0] = args;
                        scope.val = getValue(scope.item._assets[0], scope.item.input);
                        if(speedometer)
                            speedometer.setValue(scope.val);
                    }
                }
            })
            /**
             * remove listener on destroy
             */
            scope.$on('$destroy', function() {
                listener();
            });
        }
    }
});