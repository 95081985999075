/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

    angular.module("app.services").factory('dataSrv',function ($window,$rootScope,$timeout,$parse) {
    //the service Object
    var service = {};

    service.selectedAssets = [];

    return service;

});
