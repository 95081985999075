/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('alertConfigSrv', function ($http,$rootScope) {

    var service={};

    service.getAlertConfigsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/alertconfig/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getAlertConfigsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/alertconfig/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }), function(data) {
            error(data)

        };
    };


    service.getAlertConfigById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/alertconfig/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }), function(data) {
            error(data)

        };
    };
    service.deleteAlertConfigById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/alertconfig/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }), function(data) {
            error(data)

        };
    };

    service.updateAlertConfigById=function(id,alertconfig,success,error){
        $http.put($rootScope.config.API_URI+'/alertconfig/'+id,alertconfig).then(function(response) {
           if (response && response.data &&  response.data.success){
                success(response.data);
            }
            else{
                error(data);
            }

        }),function(data) {
            error(data)

        };
    };
    service.add=function(alertconfig,success,error){
        $http.post($rootScope.config.API_URI+'/alertconfig/',alertconfig).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response &&  response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }) , function(data) {
            error(data)

        };
    };

    service.create=function(alertConfigList,success,error){
        $http.post($rootScope.config.API_URI+'/alertconfig/create',{elems: alertConfigList}).then(function(response) {
            if (response &&  response.data && response.data.success){
                success(response.data);
            }
            else{

                error(data);
            }



        }) , function(data) {
            error(data)
        };
    };

    return service;

});
