angular.module('app.directives').directive('widget', function ($rootScope, ngDialog) {

    return{
        templateUrl: "app/commons/directives/widget/widget.tpl.html",
        scope : {
            item : "="
        },
        link : function (scope, elem, attr) {
            scope.opt={};
            scope.style="top: 37px; left: -204px;position:absolute;z-index:99999";

            var startDay = moment().startOf("day").clone();
            var now=  moment().clone();

            if(scope.item.type === 'GRAPH_HISTO' || scope.item.type === 'TABLE_HISTO' || scope.item.type === 'MAP_HISTO'){
                scope.item.isHistoric = true;

                if(scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.period){
                    switch (scope.item.extra.histo.period){
                        case "TODAY" :
                            scope.startDt = moment().clone().startOf('day');
                            scope.endDt = moment().clone();
                            break;
                        case "YESTERDAY":
                            scope.startDt = moment().clone().subtract(1, "day").startOf("day");
                            scope.endDt = moment().clone().subtract(1, "day").endOf("day");
                            break;
                        case "THIS_WEEK":
                            scope.startDt = moment().clone().startOf("week");
                            scope.endDt = moment().clone();
                            break;
                        case "LAST_WEEK":
                            scope.startDt = moment().clone().subtract(1, 'weeks').startOf("week");
                            scope.endDt = moment().clone().subtract(1, 'weeks').endOf("week");
                            break;
                        case "SEVEN_DAYS":
                            scope.startDt = moment().clone().subtract(7, 'days').startOf("day");
                            scope.endDt = moment().clone();
                            break;
                        case "THIS_MONTH":
                            scope.startDt = moment().clone().startOf("month");
                            scope.endDt = moment().clone();
                            break;
                        case "LAST_MONTH":
                            scope.startDt = moment().clone().subtract(1, "months").startOf("month");
                            scope.endDt = moment().clone().subtract(1, "months").endOf("month");
                            break;
                        case "THIRTEEN_DAYS":
                            scope.startDt = moment().clone().subtract(30, "days").startOf("day");
                            scope.endDt = moment().clone();
                            break;
                        case "THIS_YEAR":
                            scope.startDt = moment().clone().startOf("year");
                            scope.endDt = moment().clone();
                            break;
                        default:
                           // scope.startDt = moment(scope.item.extra.histo.startDt).clone();
                           // scope.endDt = moment(scope.item.extra.histo.endDt).clone();
                            scope.startDt = (scope.item &&  scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.startDt!=null ? moment(scope.item.extra.histo.startDt).clone():startDay);
                            scope.endDt = (scope.item &&  scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.endDt!=null ? moment(scope.item.extra.histo.endDt).clone():now);

                            break;
                    }
                }
            }

            scope.configWidget = function () {
                ngDialog.open({
                    template: 'app/commons/dialogs/widget/widget.edit.tpl.html',
                    controller: 'widgetEditCtrl',
                    scope: scope,
                    className: "ngdialog-theme-default custom_big_dialog"
                })
            };

            $rootScope.$on('updatePeriod', function (e, period) {

              //  console.log('period updated')
                switch (period){
                    case "TODAY" :
                        scope.startDt = moment().clone().startOf('day');
                        scope.endDt = moment().clone();
                        break;
                    case "YESTERDAY":
                        scope.startDt = moment().clone().subtract(1, "day").startOf("day");
                        scope.endDt = moment().clone().subtract(1, "day").endOf("day");
                        break;
                    case "THIS_WEEK":
                        scope.startDt = moment().clone().startOf("week");
                        scope.endDt = moment().clone();
                        break;
                    case "LAST_WEEK":
                        scope.startDt = moment().clone().subtract(1, 'weeks').startOf("week");
                        scope.endDt = moment().clone().subtract(1, 'weeks').endOf("week");
                        break;
                    case "SEVEN_DAYS":
                        scope.startDt = moment().clone().subtract(7, 'days').startOf("day");
                        scope.endDt = moment().clone();
                        break;
                    case "THIS_MONTH":
                        scope.startDt = moment().clone().startOf("month");
                        scope.endDt = moment().clone();
                        break;
                    case "LAST_MONTH":
                        scope.startDt = moment().clone().subtract(1, "months").startOf("month");
                        scope.endDt = moment().clone().subtract(1, "months").endOf("month");
                        break;
                    case "THIRTEEN_DAYS":
                        scope.startDt = moment().clone().subtract(30, "days").startOf("day");
                        scope.endDt = moment().clone();
                        break;
                    case "THIS_YEAR":
                        scope.startDt = moment().clone().startOf("year");
                        scope.endDt = moment().clone();
                        break;
                    default:
                       // scope.startDt = moment(scope.item.extra.histo.startDt).clone();
                       // scope.endDt = moment(scope.item.extra.histo.endDt).clone();
                        scope.startDt = (scope.item &&  scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.startDt!=null ? moment(scope.item.extra.histo.startDt).clone():startDay);
                        scope.endDt = (scope.item &&  scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.endDt!=null ? moment(scope.item.extra.histo.endDt).clone():now);
                        break;
                }

            });


            scope.deleteWidget = function (item) {
                ngDialog.open({
                    template: 'app/commons/dialogs/confirm/confirm.delete.dlg.tpl.html',
                    controller: 'confirmDeleteDlgCtrl',
                    scope: scope
                })
            };
            if($rootScope.currentDashboard){
                if($rootScope.currentDashboard.isEditable===true)
                     scope.isEditable=true;
                if($rootScope.currentDashboard.isRemovable===true)
                     scope.isRemovable=true;
            }

        }
    }

});