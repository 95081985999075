/**
 * Created by Ahmed Alaya on 15/02/2017.
 */

angular.module("app").controller('adminAccountAddCtrl', function ($rootScope,$scope, $state, companySrv, ngDialog,$translate,countrySrv,$filter,Notification) {

    $scope.countries = countrySrv.getCountryList();


    $scope.save = function (account) {
        account._ctry = $scope.account._ctry.code
        companySrv.add(account, function (data) {
            if (data.success) {
                $state.go('app.admin.account');
                Notification($translate.instant("ALERT_ACCOUNT_ADDED", {name: $scope.account.name}));
                $scope.loadAccounts()
            }else{
                console.log(data.error)
            }
        }, function (err) {

        })
    };

    $scope.cancelAccount = function () {
        if ($state.current.name === "app.admin.account.add") {
            $state.go('app.admin.account');
        }
        else {
            ngDialog.closeAll();
        }
    };

});
