/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('fleetSrv', function ($http,$rootScope) {

    var service={};

    service.getFleetsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/fleet/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response &&  response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function(data) {
            error(data)

        });
    };
    service.getFleetsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/fleet/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function(data) {
            error(data)

        });
    };
    service.getFleetAssets=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/fleet/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data &&  response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function(data) {
            error(data)

        });
    };
    service.getFleetDrivers=function(assetIds,success,error){
        /*  var l=10;
         var p= 1;
         if (page) p=page;
         if (limit) l=limit;*/


        $http.post($rootScope.config.API_URI+'/assetdriver/assets_rt',{assetIds:assetIds}).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            //console.log(response);
            if (response && response.data &&  response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function(data) {
            error(data)

        });
    };

    service.getFleetById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/fleet/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteFleetById=function(id,success,error){
  $http.delete($rootScope.config.API_URI+'/fleet/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.updateFleetById=function(id,fleet,success,error){
        $http.put($rootScope.config.API_URI+'/fleet/'+id,fleet).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(fleet,success,error){
        $http.post($rootScope.config.API_URI+'/fleet/',fleet).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    return service;

});
