angular.module('app.dialogs').controller('subscribeDlgCtrl', function ($rootScope,$scope, $window, $state,$cookieStore,$translate, ngDialog ,loginSrv, userSrv, countrySrv, companySrv, $auth, Notification, provider, response, $timeout) {

    $scope.USER_ADD = {};
    $scope.optionRequired = true;
    $scope.user = {};
    $scope.user.contact = {};
    $scope.account={};
    $scope.countries = countrySrv.getCountryList();


    $scope.save = function () {
        var account = angular.copy($scope.account);
        if($scope.account._ctry){
            account._ctry = $scope.account._ctry.code;
        }
        loginSrv.register($scope.user,account,null,null, function (data) {
            if (data.success) {
                $state.go('app.home');
            }else{
                console.log(data.error)
            }
        }, function (err) {

        })
        $scope.closeThisDialog();
    };


    $timeout(function () {
        $scope.subscribe(provider);
    },1000);


    $scope.subscribe = function(provider) {
        loginSrv[provider+"Register"](response,function(res){
            if(res){
                $scope.user.contact.mail = res.email;
                $scope.user.authentication_type = provider.toUpperCase();
                $scope.user.login = res.email.substring(0, res.email.lastIndexOf("@"));
                $scope.account.name = res.name;
                $scope.optionRequired = false;
                $scope.user.password = '';
                $scope.USER_ADD.confirm_password = '';
                if(provider === 'facebook'){
                    $scope.user.first_name = res.first_name;
                    $scope.user.last_name = res.last_name;
                }else if(provider === 'google'){
                    $scope.user.first_name = res.given_name ;
                    $scope.user.last_name = res.family_name;
                }
            }
        },function(err){
            if(err.error){
                if(err.error.errorCode){
                    $scope.message=$translate.instant(err.error.errorCode);
                }else{
                    $scope.message=err.error
                }
            }

            Notification.error({title: $translate.instant("LOGIN_ERROR"), message: $scope.message,replaceMessage: true});
        })


    };


    $scope.cancel = function () {
        $scope.user = {};
        $scope.closeThisDialog();
    };



});