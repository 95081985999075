angular.module('app.directives').directive('alertDrv', function (Notification,ngDialog) {
    return{
        templateUrl: "app/commons/directives/alerts/alerts.tpl.html",
        scope: {
            alerts : "="
        },
        link: function (scope, elem, attr ) {
            scope.showDetails = function (item) {
                if (item) {
                    if (item.loc) {
                        var marker = {
                            fuelPosition: false,
                            coordinates: item.loc.coordinates,
                            title: 'Alert Location',
                            asset: item._assetId
                        }
                    }
                    marker.message = item.name;

                    if (item.start_dt && item._assetId.name) {

                        marker.startDate = item.start_dt;

                        marker.assetName = item._assetId.name;
                        marker.status = item.status;

                    }
                    if (item.end_dt) {
                        marker.endDate = item.end_dt;
                    }

                    if (item.alert_data) {
                        marker.alertCondition = [];
                        Object.keys(item.alert_data).forEach(function (key) {

                            marker.alertCondition.push({type: key, data: item.alert_data[key]});
                        });
                    }

                    ngDialog.openConfirm({
                        template: 'app/commons/dialogs/alert/alert.dlg.html',
                        overlay: true,
                        showClose: false,
                        className: "ngdialog-theme-default ngdialog-alert",
                        controller: ['$scope', function ($scope) {
                            // controller logic
                            if (marker.startDate && marker.assetName) {
                                $scope.infos = true;
                                $scope.st = marker.startDate;
                                $scope.asset = marker.assetName;
                                $scope.message = marker.message;
                                $scope.status = marker.status;
                                $scope.alertCondition = marker.alertCondition
                                if (marker.endDate) {
                                    $scope.end = marker.endDate;
                                }

                            }
                        }]
                    })
                } else {
                    console.log('error')
                }
            };
            scope.addAlert = function () {
                ngDialog.openConfirm({
                    template: 'app/commons/dialogs/alert/alert.add.dlg.html',
                    overlay: true,
                    showClose: false,
                    className: "ngdialog-theme-default",
                    controller: 'alertConfigAddCtrl',
                    scope: scope,
                    width: '800px'
                })
            };
            scope.editAlert = function (alertConfg) {
                scope.alertConfg = alertConfg;
                ngDialog.openConfirm({
                    template: 'app/commons/dialogs/alert/alert.edit.dlg.html',
                    overlay: true,
                    showClose: false,
                    className: "ngdialog-theme-default",
                    controller: 'alertConfigEditCtrl',
                    scope: scope,
                    width: '800px'
                })
            };
        }
    }
});