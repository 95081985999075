angular.module("app.dialogs").controller('mapDlgCtrl', function ($scope, $rootScope, deviceMsgSrv,leafletData) {
    var now = moment().clone();
    var dates = "gps_dt";
    var src="loc";

    $scope.position = [];


    $scope.map = {
        option: 'polyline'
    };

    $scope.startRes3 = moment().startOf("day").clone();
    $scope.endRes3 = now;


    $scope.pickerStart3 = {
        format : "DD/MM/YYYY HH:mm",
        init: moment().startOf("day").clone(),
        position: "top left",
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.startRes3 = date;
            $scope.pickerEnd3.minDate = date;
            getData3();
        }
    };

    $scope.pickerEnd3 = {
        format : "DD/MM/YYYY HH:mm",
        position: "top left",
        init: now,
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.endRes3 = date;
            $scope.pickerStart3.maxDate = date;
            getData3();
        }
    };


    $scope.openCalendar = function (e, picker) {
        $scope[picker].open = true;
    };

    var getData3 = function () {


        if(src){

            $scope.loadDataDeviceMsgForMap(src, dates);
        }

    };


    $scope.loadDataDeviceMsgForMap = function (item) {
        $scope.position = [];
        $scope.resetMap();

        if ($scope.asset && $scope.asset._id) {


            query = {
                query: {
                    _asset: $scope.asset._id

                },
                options: {
                    sortBy:{gps_dt:1}
                }
            };
            if ($scope.query) {
                query.query = $scope.query
            }
            query.query._asset = $scope.asset._id;

            if (item) {

                query.options.columns = '' + item; // + ' ' + dateSrc
            }

            query.query["gps_dt"] = {$gte: $scope.startRes3, $lte: $scope.endRes3};
            deviceMsgSrv.getDeviceMsgList(query, Number.MAX_SAFE_INTEGER, 0, function (data) {
               // console.log('data',data)

                if (data && data.success) {
                    $scope.datas = data.result;
                    $scope.fieldsList = [];
                    $scope.filter = [];
                    var heatValue = 1;
                    var heatMax = 1;
                    for (var i = 0; i < $scope.datas.length; i++) {
                        if ($scope.datas[i]) {

                                $scope.field = 'loc';

                            var value = getValueItem($scope.datas[i], $scope.field);
                            if (value && value.coordinates && value.coordinates.length > 1) {
                                $scope.position.push({
                                    lat: value.coordinates[1],
                                    lng: value.coordinates[0],
                                    value: heatValue/heatMax,
                                    id: $scope.datas[i]._id
                                })
                            }
                        }


                    }
                    if($scope.position && $scope.position.length>0){


                        $scope.renderMap();

                    }

                } else {
                    console.error(data.error)
                }
            }, function (data) {
                console.log(data)
            })
        }
    };

    var getValueItem = function (msg, src) {
        var splitSrc = src.split('.');
        var obj = msg;
        var itemValue = undefined;
        splitSrc.forEach(function (value, index) {
            if (obj[value] != undefined) {
                obj = obj[value];
                if (index == splitSrc.length - 1) {
                    itemValue = obj;
                }
            } else {
                return;
            }
        });
        return itemValue;
    };

    /* ********** Render Map **********  */
    $scope.renderMap = function(){
        $scope.resetMap();
        if($scope.map.option == 'clusters'){
            $scope.showMarkers(true)

        }else if($scope.map.option == 'polyline'){
            $scope.showPolyline()

        }
    };



    /* ********** Show Markers or Show Clusters **********  */
    $scope.showMarkers = function (withCluster){
        var content = '';
        var data = {};
        var markersList = [];
        var marker = {};
        $scope.paths = {};
        if ($scope.position) {
            var angle = 0;
            for(var i = 0; i<$scope.position.length; i++){
                data = {
                    'lng': 0,
                    'lat': 0
                };
                data.lng = $scope.position[i].lng;
                data.lat = $scope.position[i].lat;
                data.id = $scope.position[i].id;
                if (data.lng != 0 && data.lat != 0) {
                    content = '<div class="map-popup">'
                        + '<div  class="fa fa fa-map-marker"><b> LONGITUDE:</b></div> ' + data.lng + '<br/>'
                        + '<div  class="fa fa fa-map-marker"><b> LATITUDE:</b></div> ' + data.lat + '<br/>'
                        + '</div>'
                        + '</div>'

                        marker = {
                            layer: withCluster? 'location' : undefined,
                            lng: data.lng,
                            lat: data.lat,
                            id: data.id,
                            iconAngle: angle,
                            message: content,
                            label: {
                                message: data.lat,
                                options: {
                                    noHide: false,
                                    className: "marker-label"
                                }
                            },
                            icon: {
                                type: 'extraMarker',
                                icon: 'fa-map-marker',
                                markerColor: 'blue',
                                prefix: 'fa',
                                shape: 'circle'
                            }
                        };

                    markersList.push(marker)
                }
            }
        }
        var alertLatLngs = [];
        $scope.markers = {};
        for (var i = 0; i < markersList.length; i++) {
            $scope.markers[markersList[i].id]=markersList[i]
            var latLng = new L.LatLng(markersList[i].lat, markersList[i].lng);
            alertLatLngs.push(latLng);
        }
        if (alertLatLngs.length > 0) {
            leafletData.getMap("mapping").then(function (map) {
                var bounds = new L.LatLngBounds(alertLatLngs);
                map.fitBounds(bounds);
            });
        }
    };


    $scope.showPolyline = function(){
        $scope.paths['Collection'] = {
            color: '#0099cc',
            weight: 5,
            latlngs: $scope.position,
            opacity: 5
        };
        if ($scope.position.length > 0) {
            leafletData.getMap("mapping").then(function (map) {
                var bounds = new L.LatLngBounds($scope.position);
                map.fitBounds(bounds);
            });
        }
    };



    /* ********** Init Mapping **********  */
    angular.extend($scope, {
        events: {
            map: {
                enable: ['click', 'drag', 'blur', 'touchstart'],
                logic: 'emit'
            }
        },
        defaults: {
            layerControl: true,

            map: {}
          },
        center: {
            lat: 36.843602,
            lng: 10.206953,
            zoom: 5
        },
        layers: {
            baselayers: {
                NGI: {
                    name: 'NGI',
                    url: $rootScope.config.LBS_WMS,
                    type: 'wms',
                    layerOptions: {
                        attribution: '© Powered by NGI WebMap'
                    }

                },
                googleTerrain: {
                    name: 'Google Terrain',
                    type: 'xyz',

                    url:'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
                    layerType: 'TERRAIN',
                    layerOptions: {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        continuousWorld: true
                    }

                },
                googleHybrid: {
                    name: 'Google Hybrid',
                    type: 'xyz',

                    url:'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
                    layerType: 'HYBRID',
                    layerOptions: {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        continuousWorld: true
                    }
                },
                googleRoadmap: {
                    name: 'Google Streets',
                    type: 'xyz',

                    url:'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
                    layerType: 'ROADMAP',
                    layerOptions: {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        continuousWorld: true
                    }
                },
                osm: {
                    name: 'OpenStreetMap',
                    type: 'xyz',
                    url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    layerOptions: {
                        subdomains: ['a', 'b', 'c'],
                        continuousWorld: true
                    }


            }


        },
            overlays:{
                location: {
                    name: 'Location',
                    type: 'markercluster',
                    visible: true
                }
            }
        },
        paths: {}
        ,
        markers: {},
        controls: {},

        tiles: { }
    });

    $scope.resetMap = function (){
        $scope.markers = {};
        $scope.paths = {};
        leafletData.getMap('mapping').then(function(map){

                map.removeLayer();

        });
    };


    /* ********** Add Scale To Map **********  */
    $scope.scaleControl = L.control.scale({
        position: 'bottomleft',
        imperial: false,
        maxWidth: 150,
        updateWhenIdle: true
    });
    leafletData.getMap('mapping').then(function (map) {
        map.addControl($scope.scaleControl);
    });


});


