angular.module('app').controller('adminUserEditCtrl', function ($scope, $rootScope, $state, $stateParams, $translate, userSrv, roleSrv, ngDialog, Notification) {
    $scope._ADMIN_EDIT_ADD = {};
    $scope.user = {};
    var query = {
        query: {
            _id: $stateParams.userId
        },
        options: {
            populate: [
                {
                    path: '_company_owner',
                    select: 'name'
                }
            ]

        }
    };
    userSrv.getUsersList(query, 1, 1, function (response) {
        $scope.user = response.result[0];
        $scope.getRole($scope.user._role);
    }, function (err) {
        console.log(err);
    });

    /**
     * Getting role
     */
    $scope.getRole = function (role) {
        roleSrv.getRoleById($scope.user._role, function (response) {
            if (response.success) {
                $scope.user._role = response.result;
            }
        });
    };

    /**
     * Save user
     */
    $scope.save = function () {
        if ($scope._ADMIN_EDIT_ADD.password && $scope._ADMIN_EDIT_ADD.password !== $scope._ADMIN_EDIT_ADD.confirm_password) {
            ngDialog.openConfirm({
                template: 'app/commons/dialogs/error.dlg.html',
                overlay: true,
                showClose: false,
                controller: ['$scope', function ($scope) {
                    // controller logic
                    $scope.title = $translate.instant("CHECK_YOUR_PASSWORD_TITLE");
                    $scope.message = $translate.instant("CHECK_YOUR_PASSWORD_MESSAGE");
                }]
            });
            return;
        }

        if ($scope._ADMIN_EDIT_ADD.password) {
            $scope.user.password = $scope._ADMIN_EDIT_ADD.password;
        }
        userSrv.updateUserById($scope.user._id, $scope.user, function (response) {
            if (response.success) {
                Notification.success({
                    title: "User updated",
                    message: "User " + $scope.user.login + " was updated successfully!"
                });
                $state.go('app.admin.user');
                $scope.user = {};
            } else {
                Notification.error({
                    title: "Erreur",
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.user.login + "!"
                });
                $scope.mode = "VIEW";
                $scope.user = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message: msg
            });
        });
    };

    /**
     * Removing user
     * @param user
     */
    $scope.remove = function (user) {
        $scope.user = user;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/user.delete.dlg.tpl.html",
            controller: "confirmDeleteUserDlgCtrl",
            scope: $scope
        });
    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.user = {};
        $state.go('app.admin.user');
    };
});
