

angular.module("app").controller('deviceAddCtrl', function ($scope,$rootScope, $state,assetTypeSrv,assetSrv, deviceSrv, ngDialog, Notification,deviceTypeSrv,$translate) {

    $scope.sigfox={};
    $scope.device={};
    $scope.asset={};
    $scope.device._asset=[];
    $scope.device._asset[0]={};






    /*get  AssetTypesList*/

    assetTypeSrv.getAssetTypesList({},
        100, 1,
        function (data) {
            if (data.success) {
                $scope.assetTypes= data.result;


            } else {
                console.error(data.error)
            }


        }, function (data) {
            console.log(data)

        })

    /*get  DeviceTypesList*/

    deviceTypeSrv.getDeviceTypesList({},10000,0,function(data) {
        if (data && data.success) {
            $scope.deviceTypesList = data.result;

            $scope.deviceTypesList = $scope.deviceTypesList.map(function (val) {
                return val._id
            });
        } else {
            console.error(data.error)
        }
    }, function (data) {
        console.log(data)

    });

    /* add device */
    $scope.addDevice = function () {

        $scope.device.type='TRACKING';
        $scope.asset._dev=$scope.device._id;
        assetSrv.add($scope.asset, function (data) {
        if (data.success) {
            $scope.device._asset[0]= {"_id":data.result._id};
            var newAsset=data.result;

                  deviceSrv.add($scope.device, function (data) {

                  if (data.success) {
                      if ($state.current.name === "app.device.add") {
                          $scope.dev = angular.copy($scope.device);
                          $scope.device._asset[0]=newAsset;
                          $rootScope.$emit('deviceEdited', $scope.device)

                          $state.go('app.device');
                          Notification($translate.instant('DEVICE_ADDED'));
                        //  $rootScope.$emit('deviceListUpdated')


                      }

                  }else {
                      console.log(data.error)
                  }
              }, function (err) {

              })

        }else {
            console.log(data.error)
        }
      }, function (err) {

      })

    };
    /* add sigfox model*/

    $scope.ajouterTypeSigfox=function(){


      var modal=  ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/confirm.sigfox.dlg.html",
            controller: "sigfoxCtrl",
            scope: $scope

        });

        modal.closePromise.then(function(data) {
            if (data && data.value && data.value._id && data.value._id.length>0){

                $scope.deviceTypesList.push(data.value)
            }
        });



    }






    /*cancel add device*/
    $scope.cancelDevice = function () {

      if ($state.current.name === "app.device.add") {
        $state.go('app.device');
      }
      else {
        ngDialog.closeAll();
      }
    }

  });
