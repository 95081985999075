/**
 * Created by Hamdi on 15/01/2018.
 */
angular.module('app.dialogs').controller('sigfoxCtrl', function ($scope, Notification, deviceTypeSrv,$translate) {
try {
    $scope.deviceType = {
        "config": {
            "PARSER_SENSIT": {
                "src": "io.data"
            },
            "PARSER_PAYLOAD": {
                "src": "io.data",
                "payLoad": ""
            }
        },
        "processor": "SigFoxProcessor",
        "manufacture": "SIGFOX"
    };


    $scope.confirmSigfox = function () {

        var deviceNewType = angular.copy($scope.deviceType)
        if ($scope.sensit === true) {

            delete deviceNewType.config.PARSER_PAYLOAD
        } else {
            delete deviceNewType.config.PARSER_SENSIT

        }
        deviceTypeSrv.add(deviceNewType, function (data) {
          //  console.log(data)
            if (data.success) {
                $scope.closeThisDialog({"_id":data.result._id});

                Notification($translate.instant("DEVICE_TYPE_ADDED"));
            } else {
                console.log(data.error)

            }
        },function(data){

                if(data.error && data.error.details && data.error.details.code==11000)
                    Notification($translate.instant("ERROR_DEVICE_TYPE_EXIST"));
            }

        );


    }
}catch(e){
    console.log('error',e)
}
});