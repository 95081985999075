angular.module('app.services').factory('interceptorNgProgress', [
    'ngProgressFactory', function (ngProgressFactory) {

        var complete_progress, getNgProgress, ng_progress, working;
        ng_progress = null;
        working = false;

        getNgProgress = function() {

            if(!ng_progress) {

                ng_progress = ngProgressFactory.createInstance();
                ng_progress.setColor('#005188');

                return ng_progress;
            }

            return ng_progress;
        };

        complete_progress = function() {
            var ngProgress;
            if (working) {
                ngProgress = getNgProgress();
                ngProgress.complete();
                return working = false;
            }
        };

        return {
            request: function(request) {
                var ngProgress;
                ngProgress = getNgProgress();
                if (request.url.indexOf('.html') > 0) {
                    return request;
                }
                if (!working && (request.url.indexOf("NOLOAD") == -1)) {
                    ngProgress.reset();
                    ngProgress.start();

                    working = true;
                }
                return request;
            },
            requestError: function(request) {
                complete_progress();
                return request;
            },
            response: function(response) {
                complete_progress();
                return response;
            },
            responseError: function(response) {
                complete_progress();
                return response;
            }
        }
    }])
    .config(function ($httpProvider) {
        $httpProvider.interceptors.push('interceptorNgProgress');
    });