/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('fuelFilterSrv', ['$filter','$http','$rootScope','$window','CONFIG', function($filter,$http,$rootScope,$window,CONFIG) {
    //the service Object
    var service = {};

    var parameters = {};
    var detectedData = [];
    var signal_time = [];
    var signal_min = 0;
    var signal_max = 0;
    var value_min = 0;
    var value_max = 0;

    //les donnÃ©es filtrÃ©es
    var filtered_data = [];

    //a dessiner OUTPUT
    var second_pass_filtered_data = [];

    //le nombre de transitions
    var numberOfTransitions = 0;

    //le nombre de transitions
    var numberOfGoodTransitions = 0;

    //OUTPUT
    var analog_transition = [];

    //PARAMETERS
    //zero litres
    //litres: seuil bas du signal au dessous duquel le signal est considÃ©rÃ© nul
    var fuel_zero_threshold = 2.0;

    //median filter
    var median_filter_window_size = 20;

    //level change
    //des poids pour scales_thresholds a chaque echelle
    var scales_weights = [1, 1, 1, 1];

    //double[] scales_thresholds = {.1, .2, .3, .4};
    var scales_thresholds = [.01, .02, .04, .08]; //[1, 2, 4, 8];

    var mean_window_size = 25;
    var gradient_threshold = 2.0;

    //transition aberrantes
    //index
    var successive_transitions_index_diff_threshold = 5;

    //litres: niveau de carburant tres proche de fuel_zero_threshold
    var fuel_transition_zero_diff = 0.1;

    //litres si rav commence a zero mais superieure a cette valeur alors bonne transition
    var fuel_rav_zero_threshold = 5;

    //litres: pour des transition trop petite inferieure Ã  ce seuil
    var fuel_transition_rate_threshold = 2.5;

    //seconds: transition successives dans un temps trop court
    var successive_transitions_time_interval_threshold = 180;

    //%: pour supprimer les transition impultionnelles
    var second_pass_transition_percent_diff_threshold = 90.0;

    //moyenneur
    var mean_filter_left_window_size = 70; //OK
    var mean_filter_right_window_size = 70; //OK

    ///new parameters
    var median_filter_small_window_size = 10;
    var mean_filter_small_left_window_size = 15;
    var mean_filter_small_right_window_size = 15;
    var rav_window_size = 50;

    service.setParameters = function(param) {
        parameters = param;
    };

    service.processData = function(dataArray,dateArray){

        if(dateArray){
            signal_time = new Array(dateArray.length);
            for(i=0;i<dateArray.length;i++){
                signal_time[i] = dateArray[i];
            }
        }

        var data_brut = [];
        var N = 0;
        var firstValue = null;
        var lastValue = null;
        var isFirst = true;

        if (dataArray != null && dataArray.length > 0) {

            N = dataArray.length;
            data_brut =new Array(N) ;
            for (var i = 0; i < N; i++) {

                if (dataArray[i] != null) {
                    if (firstValue == null)
                        firstValue = dataArray[i];
                    lastValue = dataArray[i];
                }
            }

            for (var i = 0; i < N; i++) {

                if (dataArray[i] != null) {
                    data_brut[i] = dataArray[i];
                    isFirst = false;
                } else {
                    if (isFirst) {
                        data_brut[i] = firstValue;
                    } else {
                        data_brut[i] = lastValue;
                    }

                }
            }

            // console.log('data_brut: ',data_brut);
        }

        numberOfTransitions = 0;

        // simple filter
        /*parameters = {
            mean_filter_left_window_size :1,
            mean_filter_right_window_size :1,
            second_pass_transition_percent_diff_threshold:80.0,
            successive_transitions_time_interval_threshold:1500,
            fuel_transition_zero_diff:0.1,
            fuel_rav_zero_threshold:15,
            fuel_transition_rate_threshold:1.5,
            successive_transitions_index_diff_threshold:5,
            gradient_threshold:400.0,
            fuel_zero_threshold:2.0,
            scales_weights:[1,1,1,1],
            scales_thresholds:[0.01,0.02,0.04,0.08],
            median_filter_window_size:70,
            mean_window_size:30,
            ANALOG_SENSOR_MIN:0.0,
            ANALOG_SENSOR_MAX:100.0,
            ANALOG_SENSOR_MIN_VALUE:0.0,
            ANALOG_SENSOR_MAX_VALUE:125,
            median_filter_small_window_size:5,
            mean_filter_small_left_window_size:5,
            mean_filter_small_right_window_size:20,
            rav_window_size:50
        };*/

        // fuel-level-ref
        /*parameters = {
            mean_filter_left_window_size :70,
            mean_filter_right_window_size :70,
            second_pass_transition_percent_diff_threshold:80.0,
            successive_transitions_time_interval_threshold:1500,
            fuel_transition_zero_diff:0.1,
            fuel_rav_zero_threshold:15,
            fuel_transition_rate_threshold:1.5,
            successive_transitions_index_diff_threshold:5,
            gradient_threshold:400.0,
            fuel_zero_threshold:2.0,
            scales_weights:[1,1,1,1],
            scales_thresholds:[0.01,0.02,0.04,0.08],
            median_filter_window_size:70,
            mean_window_size:30,
            ANALOG_SENSOR_MIN:0.0,
            ANALOG_SENSOR_MAX:100.0,
            ANALOG_SENSOR_MIN_VALUE:0.0,
            ANALOG_SENSOR_MAX_VALUE:125,
            median_filter_small_window_size:5,
            mean_filter_small_left_window_size:5,
            mean_filter_small_right_window_size:20,
            rav_window_size:50
        };*/


        // fuel-level-ref-fll
        parameters = {
            mean_filter_left_window_size :70,
            mean_filter_right_window_size :70,
            second_pass_transition_percent_diff_threshold:90.0,
            successive_transitions_time_interval_threshold:180,
            fuel_transition_zero_diff:0.1,
            fuel_rav_zero_threshold:5,
            fuel_transition_rate_threshold:2.5,
            successive_transitions_index_diff_threshold:5,
            gradient_threshold:2.0,
            fuel_zero_threshold:2.0,
            scales_weights:[1,1,1,1],
            scales_thresholds:[0.01,0.02,0.04,0.08],
            median_filter_window_size:20,
            mean_window_size:25,
            ANALOG_SENSOR_MIN:0.0,
            ANALOG_SENSOR_MAX:100.0,
            ANALOG_SENSOR_MIN_VALUE:0.0,
            ANALOG_SENSOR_MAX_VALUE:125,
            median_filter_small_window_size:10,
            mean_filter_small_left_window_size:15,
            mean_filter_small_right_window_size:10,
            rav_window_size:50
        };

        // In use Thursday 20 July
       /* parameters = {
            mean_filter_left_window_size :70,
            mean_filter_right_window_size :70,
            second_pass_transition_percent_diff_threshold:90.0,
            successive_transitions_time_interval_threshold:180,
            fuel_transition_zero_diff:0.1,
            fuel_rav_zero_threshold:5,
            fuel_transition_rate_threshold:2.5,
            successive_transitions_index_diff_threshold:5,
            gradient_threshold:2.0,
            fuel_zero_threshold:2.0,
            scales_weights:[1,1,1,1],
            scales_thresholds:[0.01,0.02,0.04,0.08],
            median_filter_window_size:20,
            mean_window_size:25,
            ANALOG_SENSOR_MIN:0.0,
            ANALOG_SENSOR_MAX:100.0,
            ANALOG_SENSOR_MIN_VALUE:0.0,
            ANALOG_SENSOR_MAX_VALUE:125,
            median_filter_small_window_size:10,
            mean_filter_small_left_window_size:15,
            mean_filter_small_right_window_size:10,
            rav_window_size:50
        };*/

        if (parameters != null && parameters.mean_filter_left_window_size != null) {
            mean_filter_left_window_size = parseInt(parameters.mean_filter_left_window_size);
            //log.info("mean_filter_left_window_size= " + mean_filter_left_window_size);
        }

        if (parameters != null && parameters.mean_filter_right_window_size != null) {
            mean_filter_right_window_size = parseInt(parameters.mean_filter_right_window_size);
            //log.info("mean_filter_right_window_size= " + mean_filter_right_window_size);
        }

        if (parameters != null && parameters.second_pass_transition_percent_diff_threshold != null) {
            second_pass_transition_percent_diff_threshold = parseFloat(parameters.second_pass_transition_percent_diff_threshold);
            //log.info("second_pass_transition_percent_diff_threshold= " + second_pass_transition_percent_diff_threshold);
        }

        if (parameters != null && parameters.successive_transitions_time_interval_threshold != null) {
            successive_transitions_time_interval_threshold = parseFloat(parameters.successive_transitions_time_interval_threshold);
            //log.info("successive_transitions_time_interval_threshold= " + successive_transitions_time_interval_threshold);
        }

        if (parameters != null && parameters.fuel_transition_zero_diff != null) {
            fuel_transition_zero_diff = parseFloat(parameters.fuel_transition_zero_diff);
            //log.info("fuel_rav_zero_threshold= " + fuel_rav_zero_threshold);
        }

        if (parameters != null && parameters.fuel_rav_zero_threshold != null) {
            fuel_rav_zero_threshold = parseFloat(parameters.fuel_rav_zero_threshold);
            //log.info("fuel_rav_zero_threshold= " + fuel_rav_zero_threshold);
        }

        if (parameters != null && parameters.fuel_transition_rate_threshold != null) {
            fuel_transition_rate_threshold = parseFloat(parameters.fuel_transition_rate_threshold);
            //log.info("fuel_transition_rate_threshold= " + fuel_transition_rate_threshold);
        }

        if (parameters != null && parameters.successive_transitions_index_diff_threshold != null) {
            successive_transitions_index_diff_threshold = parseInt(parameters.successive_transitions_index_diff_threshold);
            //log.info("successive_transitions_index_diff_threshold= " + successive_transitions_index_diff_threshold);
        }

        if (parameters != null && parameters.gradient_threshold != null) {
            gradient_threshold = parseFloat(parameters.gradient_threshold);
            //log.info("gradient_threshold= " + gradient_threshold);
        }

        if (parameters != null && parameters.fuel_zero_threshold != null) {
            fuel_zero_threshold = parseFloat(parameters.fuel_zero_threshold);
            //log.info("fuel_zero_threshold= " + fuel_zero_threshold);
        }

        if (parameters != null && parameters.scales_weights != null) {
            var scales = parameters.scales_weights.toString().split(",");
            scales_weights = new Array(scales.length);
            for (var i = 0; i < scales.length; i++) {
                scales_weights[i] = parseInt(scales[i]);
                //log.info("scales_weights[+"+i+"]=" + scales_weights[i]);
            }
        }

        if (parameters != null && parameters.scales_thresholds != null) {
            var scales = parameters.scales_thresholds.toString().split(",");

            scales_thresholds = new Array(scales.length);
            for (var i = 0; i < scales.length; i++) {
                scales_thresholds[i] = parseFloat(scales[i]);
                // console.log("sssssssssscales_thresholds["+i+"]=" + scales_thresholds[i]);
            }
        }

        if (parameters != null && parameters.median_filter_window_size != null) {
            median_filter_window_size = parseInt(parameters.median_filter_window_size.toString());
            //log.info("median_filter_window_size= " + median_filter_window_size);
        }

        if (parameters != null && parameters.mean_window_size != null) {
            mean_window_size = parseInt(parameters.mean_window_size.toString());
            //log.info("mean_window_size= " + mean_window_size);
        }

        if (parameters != null && parameters.ANALOG_SENSOR_MIN != null) {
            signal_min = parseFloat(parameters.ANALOG_SENSOR_MIN.toString());
            //log.info("signal_min= " + signal_min);
        }

        if (parameters != null && parameters.ANALOG_SENSOR_MAX != null) {
            signal_max = parseFloat(parameters.ANALOG_SENSOR_MAX.toString());
            //log.info("signal_max= " + signal_max);
        }

        if (parameters != null && parameters.ANALOG_SENSOR_MIN_VALUE != null) {
            value_min = parseFloat(parameters.ANALOG_SENSOR_MIN_VALUE.toString());
            //log.info("value_min= " + value_min);
        }

        if (parameters != null && parameters.ANALOG_SENSOR_MAX_VALUE != null) {
            value_max = parseFloat(parameters.ANALOG_SENSOR_MAX_VALUE.toString());
            //log.info("value_max= " + value_max);
        }

        if (parameters != null && parameters.median_filter_small_window_size != null) {
            median_filter_small_window_size = parseInt(parameters.median_filter_small_window_size.toString());
            //log.info("median_filter_small_window_size= " + median_filter_small_window_size);
        }

        if (parameters != null && parameters.mean_filter_small_left_window_size != null) {
            mean_filter_small_left_window_size = parseInt(parameters.mean_filter_small_left_window_size.toString());
            //log.info("mean_filter_small_left_window_size= " + mean_filter_small_left_window_size);
        }

        if (parameters != null && parameters.mean_filter_small_right_window_size != null) {
            mean_filter_small_right_window_size = parseInt(parameters.mean_filter_small_right_window_size.toString());
            //log.info("mean_filter_small_right_window_size= " + mean_filter_small_right_window_size);
        }

        if (parameters != null && parameters.rav_window_size != null) {
            rav_window_size = parseInt(parameters.rav_window_size.toString());
            //log.info("rav_window_size= " + rav_window_size);
        }

        // console.log('data_brut: ',data_brut);

        var transitions =  findTransitions(data_brut, signal_time, signal_min, signal_max, value_min, value_max);

        console.log('transitions length: ',transitions.length);
        console.log('transitions: ',transitions);

        return transitions;
    }

    function rescaleValue(inputValue, signal_min, signal_max, value_min, value_max) {
        var rescaledValue = 0.0;
        var a, b;

        a = (value_max - value_min) / (signal_max - signal_min);
        b = value_max - a * signal_max;

        rescaledValue = a * inputValue + b;

        return rescaledValue;
    }

    function rescaleVariation (inputVariation, signal_min, signal_max, value_min, value_max) {
        var rescaledVariation = 0.0;

        var a;
        a = (value_max - value_min) / (signal_max - signal_min);
        rescaledVariation = a * inputVariation;

        return rescaledVariation;
    }

    function rescaleVariationWithInputs(inputValue1, inputValue2, signal_min, signal_max, value_min, value_max) {
        var rescaledVariation = 0.0;
        var a;
        a = (value_max - value_min) / (signal_max - signal_min);
        rescaledVariation = a * (inputValue2 - inputValue1);

        return rescaledVariation;
    }

    function GaussianKernel1D(scale, deriv, width) {
        var sigma = parseFloat(scale);
        sigma = scale;
        var range =new Array(2*width*parseInt(Math.ceil(sigma))+1);

        var derivs = new Array(5);
        derivs[0] = new Array(2*width*parseInt(Math.ceil(sigma))+1);
        derivs[1] = new Array(2*width*parseInt(Math.ceil(sigma))+1);
        derivs[2] = new Array(2*width*parseInt(Math.ceil(sigma))+1);
        derivs[3] = new Array(2*width*parseInt(Math.ceil(sigma))+1);
        derivs[4] = new Array(2*width*parseInt(Math.ceil(sigma))+1);

        var kernel = new Array(2*width*parseInt(Math.ceil(sigma))+1);

        for (var i = 0; i < 2*width*parseInt(Math.ceil(sigma))+1; i++) {
            range[i] = i + 1;
            // console.log('range: ' + range[i]);
        }

        var center = range[width * parseInt(Math.ceil(sigma))];

        for (var i = 0; i < 2*width*parseInt(Math.ceil(sigma))+1; i++) {
            derivs[0][i] = 1.0;
            derivs[1][i] = -((range[i] - center) / (sigma * sigma));
            derivs[2][i] = (Math.pow(range[i] - center, 2.0) - Math.pow(sigma, 2.0)) / Math.pow(sigma, 4.0);
            derivs[3][i] = -(Math.pow(range[i] - center, 3.0) - 3 * (sigma * sigma) * (range[i] - center)) / Math.pow(sigma, 6.0);
            derivs[4][i] = (Math.pow(range[i] - center, 4.0) - 6 * (sigma * sigma) * (range[i] - center) * (range[i] - center) + 3 * Math.pow(sigma, 4.0)) / Math.pow(sigma, 8.0);
        }

        //console.log('derivs: ' + derivs);

        for (var i = 0; i < 2 * width * parseInt(Math.ceil(sigma)) + 1; i++) {
            kernel[i] = (1.0 / (sigma * Math.sqrt(2 * Math.PI))) * Math.exp(-((range[i] - center) * (range[i] - center)) / (2.0 * sigma * sigma));
            kernel[i] = kernel[i] * derivs[deriv][i];
        }

        return kernel;
    }

    var conv = function(signal, h) {

        var fsignal = new Array(signal.length);
        var i, j;
        for (var i = 0; i < fsignal.length; i++)
            fsignal[i] = 0;

        var sum = 0.0;
        for (var i = 0; i < fsignal.length; i++) {
            sum = 0.0;
            for (var j = 0; j < h.length; j++) {

                if ((i - j) >= 0)
                    sum = sum + signal[i - j] * h[j];
            }
            fsignal[i] = sum;
        }

        return fsignal;
    };

    function median_filter(signal, n) {

        var fsignal = new Array(signal.length);

        var i, j, index1, index2;
        for (var i = 0; i < fsignal.length; i++)
            fsignal[i] = 0;

        for (var i = 0; i < fsignal.length; i++) {
            index1 = i - (n / 2);
            index2 = i + (n / 2) - 1;
            if (index1 < 0) index1 = 0;
            if (index2 > fsignal.length - 1)
                index2 = fsignal.length - 1;

            fsignal[i] = median_double(signal, index1, index2);
        }

        return fsignal;
    }

    function butterworth_filter(signal, a) {
        var fsignal = [];
        var x, lastY = 0.0,
            y = 0.0;
        var i;

        for (i = 0; i < signal.length; i++)
            fsignal[i] = 0; // consider using "Push" method

        for (i = 0; i < signal.length; i++) {
            fsignal[i] = signal[i] - a * lastY;
            lastY = fsignal[i];
        }

        return fsignal;
    }

    function mean_filter(signal, N, M) {
        var fsignal = new Array(signal.length);
        var m;
        var i, j, debut, fin, total = 0;

        for (i = 0; i < fsignal.length; i++)
            fsignal[i] = 0;

        for (i = 0; i < fsignal.length; i++) {

            debut = i - N;
            if (debut < 0) debut = 0;
            fin = i + M;
            if (fin > fsignal.length - 1)
                fin = fsignal.length - 1;

            total = fin - debut + 1;
            m = 0.0;

            for (j = debut; j < fin + 1; j++) {
                m = m + signal[j];
            }

            fsignal[i] = m / total;

        };

        return fsignal;
    }

    function maxDouble(array) {
        var max = array[0];
        for (var i = 0; i < array.length; i++) {
            if (array[i] > max) max = array[i];
        }
        return max;
    }

    function maxDoubleWithIndex(array, index1, index2) {
        if (index1 < 0) index1 = 0;
        if (index2 > array.length - 1) index2 = array.length - 1;
        var max = array[index1];
        for (var i = index1; i < index2 + 1; i++) {
            if (array[i] > max) max = array[i];
        }
        return max;
    }

    function meanDouble(array, index1, index2) {
        if (index1 < 0) index1 = 0;
        if (index2 > array.length - 1) index2 = array.length - 1;
        var mean = 0.0;
        for (var i = index1; i < index2 + 1; i++) {
            mean = mean + array[i];
        }
        return (mean / parseFloat(index2 - index1 + 1));
    }

    function minDouble(array) {
        var min = array[0];
        for (var i = 0; i < array.length; i++) {
            if (array[i] < min) min = array[i];
        }
        return min;
    }

    function median_double(array, index1, index2) {
        var median = 0.0;
        var dataLength = index2 - index1 + 1;
        var data = new Array(dataLength);
        for (var i = 0; i < data.length; i++)
            data[i] = array[i + index1];

        data.sort(function(a, b) {
            return a - b
        });
        median = data[parseInt(dataLength / 2)];

        return median;
    }

    function FindLocalExtrema(data, threshold, scale) {

        var i, ii;

        var rdataMax = new Array(data.length);
        var rdataMin = new Array(data.length);
        var winmax = new Array(data.length);
        var winmin = new Array(data.length);
        var maxima = new Array(data.length);
        var minima = new Array(data.length);
        var extrema = new Array(data.length);

        var maxdata = maxDouble(data);
        var mindata = minDouble(data);

        for (i = 0; i < data.length; i++) {
            rdataMax[i] = data[i] / maxdata;
            rdataMin[i] = data[i] / mindata;
            winmax[i] = 0.0;
            winmin[i] = 0.0;
        }

        for (i = scale; i < data.length - scale; i++) {
            winmax[i] = maxDoubleWithIndex(rdataMax, i - scale, i + scale);
            winmin[i] = maxDoubleWithIndex(rdataMin, i - scale, i + scale);
            ii = 0;
        }

        for (i = 0; i < data.length; i++) {
            maxima[i] = 0;
            minima[i] = 0;
        }

        for (ii = scale; ii < data.length - scale; ii++) {
            if ((rdataMax[ii] >= threshold) && (rdataMax[ii] >= winmax[ii]))
                maxima[ii] = 1;
            if ((rdataMin[ii] >= threshold) && (rdataMin[ii] >= winmin[ii]))
                minima[ii] = 1;
        }

        for (i = 0; i < data.length; i++) {
            extrema[i] = maxima[i] + minima[i];
        }

        return extrema;
    }


    function FindLocalExtremaWithRegions(data, threshold, scale, regions) {

        var i, ii;

        var rdataMax = new Array(data.length);
        var rdataMin = new Array(data.length);
        var winmax = new Array(data.length);
        var winmin = new Array(data.length);
        var maxima = new Array(data.length);
        var minima = new Array(data.length);
        var extrema = new Array(data.length);

        var maxdata = maxDouble(data);
        var mindata = minDouble(data);

        for (i = 0; i < data.length; i++) {
            rdataMax[i] = data[i] / maxdata;
            rdataMin[i] = data[i] / mindata;
            winmax[i] = 0.0;
            winmin[i] = 0.0;
        }

        for (i = scale; i < data.length - scale; i++) {
            winmax[i] = maxDoubleWithIndex(rdataMax, i - scale, i + scale);
            winmin[i] = maxDoubleWithIndex(rdataMin, i - scale, i + scale);
            ii = 0;
        }

        for (i = 0; i < data.length; i++) {
            maxima[i] = 0;
            minima[i] = 0;
        }

        for (i = 0; i < regions.length; i++) {
            for (ii = Math.max(scale, regions[i] - scale); ii <= Math.min(data.length - scale, regions[i] + scale); ii++) {
                if ((rdataMax[ii] >= threshold) && (rdataMax[ii] >= winmax[ii]))
                    maxima[ii] = 1;
                if ((rdataMin[ii] >= threshold) && (rdataMin[ii] >= winmin[ii]))
                    minima[ii] = 1;
            }
        }

        for (i = 0; i < data.length; i++) {
            extrema[i] = maxima[i] + minima[i];
        }

        return extrema;
    }

    function findTransitions(data_brut, signal_time, signal_min, signal_max, value_min, value_max) {

        // La procédure est signal_brut-->filtre median-->detection de changements de niveaux -->filtre passe bas (moyenneur)
        var GaussianKernel_width = 3;
        var scales = [1, 2, 4, 8];
        var mean_offset = 2;

        /////////////////////////////////

        //FIN PARAMETRES
        /////////////////////////////////

        //Phase 0: suppression des valeurs nulles

        var i, j, jj;
        var litres;

        if (data_brut.length > 9) {

            var max_signal = data_brut[0];

            for (i = 0; i < 10; i++) {
                if (data_brut[i] > max_signal)
                    max_signal = data_brut[i];
            }
            if (max_signal > signal_max)
                max_signal = signal_max;

            for (i = 0; i < 10; i++) {
                if (data_brut[i] < (max_signal / 2.0))
                    data_brut[i] = max_signal;
            }
            //////
            max_signal = data_brut[data_brut.length - 10];
            for (i = 0; i < 10; i++) {
                if (data_brut[i + data_brut.length - 10] > max_signal)
                    max_signal = data_brut[i + data_brut.length - 10];
            }

            if (max_signal > signal_max)
                max_signal = signal_max;

            for (i = 0; i < 10; i++) {
                if (data_brut[i + data_brut.length - 10] < (max_signal / 2.0))
                    data_brut[i + data_brut.length - 10] = max_signal;
            }

        }
        //////

        //------------------------

        //Phase 0: suppresssion de valeurs négatives
        var compteur = 0;
        var ndata_brut = null;
        var nsignal_time = null;
        var indexes = null;
        var masque = null;

        if (data_brut != null) {

            for (i = 0; i < data_brut.length; i++) {

                litres = rescaleValue(data_brut[i], signal_min, signal_max, value_min, value_max);

                if (litres >= fuel_zero_threshold)
                    compteur = compteur + 1;
            }

            ndata_brut = new Array(data_brut.length);
            nsignal_time = new Array(data_brut.length);
            indexes = new Array(data_brut.length);
            masque = new Array(data_brut.length);

            var c = -1;
            if (compteur != 0) {
                ndata_brut = new Array(compteur);
                nsignal_time = new Array(compteur);
                indexes = new Array(compteur);
                for (i = 0; i < data_brut.length; i++) {
                    masque[i] = -1;
                    litres = rescaleValue(data_brut[i], signal_min, signal_max, value_min, value_max);

                    if (litres >= fuel_zero_threshold) {
                        c = c + 1;
                        ndata_brut[c] = data_brut[i];
                        nsignal_time[c] = signal_time[i];
                        indexes[c] = i;
                        masque[i] = c;
                    }

                }

            } else //ttes les valeurs sont < seuil (-->nulles)
            {
                ndata_brut = new Array(data_brut.length);
                nsignal_time = new Array(data_brut.length);
                indexes = new Array(data_brut.length);
                for (i = 0; i < data_brut.length; i++) {
                    ndata_brut[i] = data_brut[i];
                    nsignal_time[i] = signal_time[i];
                    indexes[i] = i;
                    masque[i] = -1;
                }

            }
        }

        //*********************
        //Phase 1: filtre median du signal brut
        var data = new Array(ndata_brut.length);
        data = median_filter(ndata_brut, median_filter_window_size);

        //phase 1 bis: suppression des valeurs nulles:
        for (i = 0; i < data.length; i++) {
            litres = rescaleValue(data[i], signal_min, signal_max, value_min, value_max);
            if (litres < fuel_zero_threshold)
                data[i] = rescaleValue(fuel_zero_threshold, value_min, value_max, signal_min, signal_max);
        }

        //Phase 2: détection des changement de niveaux

        //variables locales
        var r1, r2;
        var scale;
        var g = [];
        var padData = [];
        var fData = [];

        var dData = new Array(data.length);
        for(var i=0;i<data.length;i++){
            dData[i] = new Array(scales.length);
        }

        var minmax = new Array(data.length);
        var tmp = new Array(data.length);
        var count = 0;
        var k = 0;
        var minmaxIdx = [];

        for (i = 0; i < scales.length; i++) {

            scale = scales[i];
            var gLength = 2 * GaussianKernel_width * scale + 1;
            var g = new Array(gLength);
            padData = new Array(data.length + g.length + g.length);
            fData = new Array(data.length + g.length + g.length);

            //% Find the gaussian kernel, convolve
            g = GaussianKernel1D(scale, 1, GaussianKernel_width);

            for (j = 0; j < g.length; j++)
                padData[j] = data[0];
            for (j = 0; j < data.length; j++)
                padData[j + g.length] = data[j];
            for (j = data.length + g.length; j < padData.length; j++)
                padData[j] = data[data.length - 1];

            fData = conv(padData, g);

            var offsett = parseInt((padData.length + g.length - 1 - data.length) / 2);

            for (var j = 0; j < data.length; j++) {
                if (!Array.isArray(dData[j]))
                    dData[j] = [];
                dData[j][i] = fData[offsett + j - 1];
            }

        }

        /*% Find the position of local minima and maxima of the most coarse scale
         minmax = FindLocalExtrema(dData(:, end), thresholds(end), scales(end));
         minmaxIdx = find(minmax);*/

        for (i = 0; i < data.length; i++){
            tmp[i] = dData[i][scales.length-1];
        }

        minmax = FindLocalExtrema(tmp,scales_thresholds[scales_thresholds.length - 1],scales[scales.length - 1]);

        count = 0;

        for (jj = 0; jj < minmax.length; jj++) {
            if (minmax[jj] > 0)
                count = count + 1;
        }

        minmaxIdx = new Array(count);
        k = 0;

        for (jj = 0; jj < minmax.length; jj++) {
            if (minmax[jj] > 0) {
                minmaxIdx[k] = jj;
                k = k + 1;
            }
        }

        /*% Refine min/max positions through scale space
         for i = size(scales)-1:-1:1
         minmax = FindLocalExtrema(dData(:,i), thresholds(i), scales(i), minmaxIdx);
         minmaxIdx = find(minmax);
         end*/
        for (i = scales.length - 2; i >= 0; i--) {
            for (j = 0; j < data.length; j++)
                tmp[j] = dData[j][i];

            minmax = FindLocalExtremaWithRegions(tmp,scales_thresholds[i],scales[i],minmaxIdx);

            count = 0;
            for (jj = 0; jj < minmax.length; jj++) {
                if (minmax[jj] > 0)
                    count = count + 1;
            }

            minmaxIdx = new Array(count);

            k = 0;
            for (jj = 0; jj < minmax.length; jj++) {

                if (minmax[jj] > 0) {
                    minmaxIdx[k] = jj;
                    k = k + 1;
                }
            }

        }

        //find variations
        var index;
        var gradient;
        var variations = new Array(minmaxIdx.length);
        var mean1, mean2;
        var count_transitions = 0;

        for (i = 0; i < minmaxIdx.length; i++) {
            index = minmaxIdx[i];
            gradient = scales_weights[0] * Math.abs((dData[index])[0]) + scales_weights[1] * Math.abs((dData[index])[1]) + scales_weights[2] * Math.abs((dData[index])[2]) + scales_weights[3] * Math.abs((dData[index])[3]);

            if ((index > 0) && (index < data.length - 1) && (gradient > gradient_threshold)) {
                count_transitions = count_transitions + 1;
            }
        }

        numberOfTransitions = count_transitions;
        if (numberOfTransitions > 0) {
            for (i = 0; i < numberOfTransitions; i++)
                analog_transition.push({});
        }
        count_transitions = 0;

        for (i = 0; i < minmaxIdx.length; i++) {
            index = minmaxIdx[i];
            gradient = scales_weights[0] * Math.abs((dData[index])[0]) + scales_weights[1] * Math.abs((dData[index])[1]) + scales_weights[2] * Math.abs((dData[index])[2]) + scales_weights[3] * Math.abs((dData[index])[3]);
            if ((index > 0) && (index < data.length - 1) && (gradient > gradient_threshold)) {

                mean1 = meanDouble(data, index - mean_offset - mean_window_size, index - mean_offset);
                mean2 = meanDouble(data, index + mean_offset, index + mean_offset + mean_window_size);
                variations[i] = mean2 - mean1;

                //System.out.println("index= " + index + " avant=" + mean1 + " après=" + mean2 + " variation=" + variations[i] + " deriv=" + dData[index][0] );

                var scale_transition_rate = rescaleVariation(variations[i], signal_min, signal_max, value_min, value_max);

                var scaled_value_before = rescaleValue(mean1, signal_min, signal_max, value_min, value_max);

                var scaled_value_after = rescaleValue(mean2, signal_min, signal_max, value_min, value_max);

                analog_transition[count_transitions] = {
                    TransitionIndex: index,
                    TransitionRate: variations[i],
                    TransitionDate: nsignal_time[index],
                    ValueBefore: mean1,
                    ValueAfter: mean2,
                    goodTransition: 1,
                    ScaledTransitionRate: scale_transition_rate,
                    ScaledValueBefore: scaled_value_before,
                    ScaledValueAfter: scaled_value_after
                };

                count_transitions = count_transitions + 1;
            }
        }

        ///clean transitions
        if (numberOfTransitions > 0) {
            numberOfGoodTransitions = 0;

            for (i = 0; i < numberOfTransitions; i++) {
                r1 = parseFloat(analog_transition[i].ScaledTransitionRate);

                //transition trop petite
                if (Math.abs(r1) < fuel_transition_rate_threshold) {
                    analog_transition[i].goodTransition = 0;
                }

                //transition successives
                if ((i + 1) < numberOfTransitions) {

                    if(analog_transition[i].TransitionIndex && analog_transition[i + 1].TransitionIndex){
                        if ((parseInt(analog_transition[i + 1].TransitionIndex.toString()) - parseInt(analog_transition[i].TransitionIndex.toString())) < successive_transitions_index_diff_threshold) {
                            //log.info("############# BAD Detection successive_transitions_index_diff_threshold:"+successive_transitions_index_diff_threshold);
                            var a, b;
                            a = parseInt(analog_transition[i + 1].TransitionIndex.toString());
                            b = parseInt(analog_transition[i].TransitionIndex.toString());
                            //log.info("#############"+  (a-b) );
                            analog_transition[i].goodTransition = 0;
                        }
                        // System.out.println("!!!transition successives: " + analog_transition[i+1].TransitionIndex);
                    }

                }

                //siphonage qui fini a zero
                if (r1 < 0) {
                    if (parseFloat(analog_transition[i].ScaledValueAfter) < (fuel_transition_zero_diff + fuel_zero_threshold)) {
                        // analog_transition[i].goodTransition = 0;
                        analog_transition[i].goodTransition = 0;
                    }
                }

                // rav qui commence a zero
                if (r1 > 0) {
                    if (parseFloat(analog_transition[i].ScaledValueBefore) < (fuel_transition_zero_diff + fuel_zero_threshold)) {
                        if (r1 < fuel_rav_zero_threshold) {
                            analog_transition[i].goodTransition = 0;
                        }
                    }
                }

                if ((i + 1) < numberOfTransitions) {

                    r2 = parseFloat(analog_transition[i + 1].ScaledTransitionRate);

                    //ravitaillement/siphonage dans un temps trop court
                    // Breakpoint
                    if (((r1 > 0) && (r2 < 0)) || ((r1 < 0) && (r2 > 0))) {

                        var dateStart = new Date();
                        var dateStop = new Date();

                        try {
                            if(analog_transition[i].TransitionDate){
                                dateStart = analog_transition[i].TransitionDate.toString();
                            }
                            if(analog_transition[i + 1].TransitionDate){
                                dateStop = analog_transition[i + 1].TransitionDate.toString();
                            }
                            /*$filter('date')(dateStart, "yyyy-MM-dd HH:mm:ss");
                            $filter('date')(dateStop, "yyyy-MM-dd HH:mm:ss");*/

                            dateStart = moment(dateStart, "yyyy-MM-dd HH:mm:ss");
                            dateStop = moment(dateStop, "yyyy-MM-dd HH:mm:ss");

                            var tdiff = (dateStop.getTime() - dateStart.getTime()) / 1000;

                            if (tdiff < successive_transitions_time_interval_threshold) {
                                analog_transition[i].goodTransition = 0;
                                analog_transition[i + 1].goodTransition = 0;
                            }

                        } catch (err) {

                        }

                    };

                }

                if (analog_transition[i].goodTransition && parseInt(analog_transition[i].goodTransition.toString()) == 1)
                    numberOfGoodTransitions = numberOfGoodTransitions + 1;
            }
        }

        //transition filter
        filtered_data = new Array(data.length);
        var debut, fin;
        k = 0;
        var total = 0;
        var m = 0.0;
        var sous_signal = [];
        var sous_signalf = [];
        var s = 0;

        if (numberOfGoodTransitions > 0) {
            debut = 0;
            for (i = 0; i < numberOfTransitions; i++) {

                if (analog_transition[i].goodTransition && parseInt(analog_transition[i].goodTransition.toString()) == 1) {
                    k = k + 1;

                    if(analog_transition[i].TransitionIndex){
                        filtered_data[parseInt(analog_transition[i].TransitionIndex.toString()) - 1] = parseFloat(analog_transition[i].ValueBefore);
                        filtered_data[parseInt(analog_transition[i].TransitionIndex.toString())] = parseFloat(analog_transition[i].ValueAfter);

                        fin = parseInt(analog_transition[i].TransitionIndex.toString()) - 2;

                        if (fin <= debut)
                            fin = debut + 1;

                        //System.out.println("debut  = " + debut + ", fin = " + fin);

                        //interpollation entre debut et fin
                        total = fin - debut + 1;
                        sous_signal = new Array(total);
                        sous_signalf = new Array(total);

                        for (s = 0; s < total; s++)
                            sous_signal[s] = data[debut + s];

                        sous_signalf = mean_filter(sous_signal, mean_filter_left_window_size, mean_filter_right_window_size);

                        for (s = 0; s < total; s++)
                            filtered_data[debut + s] = sous_signalf[s];

                        debut = parseInt(analog_transition[i].TransitionIndex.toString()) + 1;
                    }

                }
            }

            fin = data.length - 1;
            if (fin <= debut)
                fin = debut + 1;

            //System.out.println("debut  = " + debut + ", fin = " + fin);
            total = fin - debut + 1;
            sous_signal = new Array(total);
            sous_signalf = new Array(total);

            for (s = 0; s < total; s++)
                sous_signal[s] = data[debut + s];

            sous_signalf = mean_filter(sous_signal, mean_filter_left_window_size, mean_filter_right_window_size);

            for (s = 0; s < total; s++)
                filtered_data[debut + s] = sous_signalf[s];

        } else {
            filtered_data = mean_filter(data, mean_filter_left_window_size, mean_filter_right_window_size);
        }

        ////END filter according to transitions;

        //Second pass filter
        second_pass_filtered_data = new Array(filtered_data.length);
        second_pass_filtered_data = median_filter(filtered_data, median_filter_window_size);

        //enlever les transitions impultionnelles

        if (numberOfGoodTransitions > 0) {
            for (i = 0; i < numberOfTransitions; i++) {
                if (analog_transition[i].goodTransition && analog_transition[i].TransitionIndex && parseInt(analog_transition[i].goodTransition.toString()) == 1) {
                    index = parseInt(analog_transition[i].TransitionIndex.toString());
                    r1 = Math.abs(parseFloat(analog_transition[i].ScaledTransitionRate));
                    r2 = rescaleValue(second_pass_filtered_data[index - 1], signal_min, signal_max, value_min, value_max) - rescaleValue(second_pass_filtered_data[index], signal_min, signal_max, value_min, value_max);
                    r2 = Math.abs(r2);

                    var diff = Math.abs(r2 - r1);

                    diff = 100.0 * (diff / Math.abs(parseFloat(analog_transition[i].ScaledTransitionRate)));

                    if (diff > second_pass_transition_percent_diff_threshold) {
                        analog_transition[i].goodTransition = 0;
                        numberOfGoodTransitions = numberOfGoodTransitions - 1;
                    }

                }
            }
        } //if


        //affinement autour des ravitaillements en utilisant les paramètres
        // int median_filter_small_window_size = 5;
        //int mean_filter_small_window_size = 5;
        // int rav_window_size=40;
        if (numberOfGoodTransitions > 0) {
            for (i = 0; i < numberOfTransitions; i++) {

                if (analog_transition[i].goodTransition && parseInt(analog_transition[i].goodTransition.toString()) == 1) {
                    if (analog_transition[i].TransitionIndex && analog_transition[i].TransitionRate && parseFloat(analog_transition[i].TransitionRate.toString()) > 0) {
                        debut = parseInt(analog_transition[i].TransitionIndex.toString());
                        fin = parseInt(analog_transition[i].TransitionIndex.toString()) + rav_window_size;

                        if (fin >= data.length - 1)
                            fin = data.length - 2;

                        total = fin - debut + 1;
                        sous_signal = new Array(total);
                        sous_signalf = new Array(total);

                        // System.out.println("debut = " + debut + " -- fin = " + fin);

                        for (j = debut; j <= fin; j++)
                            sous_signal[j - debut] = ndata_brut[j];

                        sous_signalf = median_filter(sous_signal, median_filter_small_window_size);
                        sous_signalf = mean_filter(sous_signalf, mean_filter_small_left_window_size, mean_filter_small_right_window_size);

                        var index1 = debut;
                        var index2 = fin + 1;

                        var value1 = sous_signalf[0];

                        if (value1 < second_pass_filtered_data[debut])
                            value1 = second_pass_filtered_data[debut];

                        var value2 = second_pass_filtered_data[index2];

                        if (value2 > value1)
                            value1 = value2;

                        var a = (value2 - value1) / (index2 - index1);
                        var b = value2 - a * index2;

                        for (j = index1; j < index2 + 1; j++)
                            second_pass_filtered_data[j] = a * j + b;

                        //Avant ravitaillement
                        debut = parseInt(analog_transition[i].TransitionIndex.toString()) - rav_window_size;
                        fin = parseInt(analog_transition[i].TransitionIndex.toString()) - 1;

                        if (debut < 0)
                            debut = 1;

                        total = fin - debut + 1;
                        sous_signal = new Array(total);
                        sous_signalf = new Array(total);

                        for (j = debut; j <= fin; j++)
                            sous_signal[j - debut] = ndata_brut[j];

                        sous_signalf = median_filter(sous_signal, median_filter_small_window_size);
                        sous_signalf = mean_filter(sous_signalf, mean_filter_small_left_window_size, mean_filter_small_right_window_size);

                        index1 = debut;
                        index2 = fin;

                        value1 = second_pass_filtered_data[index1];

                        if (value1 < second_pass_filtered_data[debut])
                            value1 = second_pass_filtered_data[debut];

                        value2 = sous_signalf[sous_signalf.length - 1];

                        if (value2 > value1)
                            value2=value1;

                        a = (value2 - value1) / (index2 - index1);
                        b = value2 - a * index2;

                        for (j = index1; j < index2 + 1; j++)
                            second_pass_filtered_data[j] = a * j + b;

                        analog_transition[i] = {
                            ValueBefore: second_pass_filtered_data[parseInt(analog_transition[i].TransitionIndex.toString()) - 1],
                            ValueAfter: second_pass_filtered_data[parseInt(analog_transition[i].TransitionIndex.toString())],
                            TransitionRate: parseFloat(analog_transition[i].ValueAfter) - parseFloat(analog_transition[i].ValueBefore),
                            ScaledValueBefore: rescaleValue(parseFloat(analog_transition[i].ValueBefore), signal_min, signal_max, value_min, value_max),
                            ScaledValueAfter: rescaleValue(parseFloat(analog_transition[i].ValueAfter), signal_min, signal_max, value_min, value_max),
                            ScaledTransitionRate: rescaleVariation(parseFloat(analog_transition[i].TransitionRate), signal_min, signal_max, value_min, value_max)
                        };

                    }

                }
            }
        }
        /////fin affinement

        ////Post process revenir au signal original si compteur !=0
        if (compteur != 0) {
            //les index des transitions
            for (i = 0; i < numberOfTransitions; i++) {
                if(analog_transition[i].TransitionIndex){
                    analog_transition[i].TransitionIndex = indexes[parseInt(analog_transition[i].TransitionIndex.toString())];
                }
            }

            //le signal filtré
            var tmpsignal = new Array(data_brut.length);
            for (i = 0; i < data_brut.length; i++) {
                if (masque[i] != -1)
                    tmpsignal[i] = filtered_data[masque[i]];
                else
                    tmpsignal[i] = rescaleValue(fuel_zero_threshold, value_min, value_max, signal_min, signal_max);
            }

            filtered_data = new Array(data_brut.length);
            for (i = 0; i < data_brut.length; i++) {
                filtered_data[i] = tmpsignal[i];
            }

            //le signal filtré final
            //le signal filtré
            tmpsignal = new Array(data_brut.length);
            for (i = 0; i < data_brut.length; i++) {
                if (masque[i] != -1)
                    tmpsignal[i] = second_pass_filtered_data[masque[i]];
                else
                    tmpsignal[i] = rescaleValue(fuel_zero_threshold, value_min, value_max, signal_min, signal_max);
            }

            second_pass_filtered_data = new Array(data_brut.length);
            for (i = 0; i < data_brut.length; i++) {
                second_pass_filtered_data[i] = tmpsignal[i];
            }

            //nettoyage final
            for (i = 0; i < indexes.length - 1; i++) {
                var idebut = indexes[i];
                var ifin = indexes[i + 1];

                count = ifin - idebut - 1;
                if (count > 0) {
                    for (j = (idebut + 1); j < ifin; j++) {
                        filtered_data[j] = filtered_data[idebut];
                        second_pass_filtered_data[j] = second_pass_filtered_data[idebut];

                    }
                }
            }

        }

        detectedData = [];
        if (numberOfGoodTransitions > 0) {
            //	            System.out.println("........Transitions finales.......");
            for (i = 0; i < numberOfTransitions; i++) {

                if (analog_transition[i].goodTransition && parseInt(analog_transition[i].goodTransition.toString()) == 1)

                {
                    var detected = {
                        index: analog_transition[i].TransitionIndex,
                        delta: analog_transition[i].TransitionRate,
                        val1: analog_transition[i].ValueBefore,
                        val2: analog_transition[i].ValueAfter
                    };

                    if (parseFloat(analog_transition[i].TransitionRate) > 0) {
                        detected.ROCType = "ROCUP";
                    } else
                        detected.ROCType = "ROCDOWN";

                    detectedData.push(detected);
                }
            }
        }

        j = 0;
        var outSig = [];
        for (i = 0; i < second_pass_filtered_data.length; i++) {
            outSig.push(parseFloat(second_pass_filtered_data[i]));
        }

        /*console.log('Close findTransitions: ' + '\n',
                    'outSig: '   + JSON.stringify(outSig));*/

        return outSig;

    }

    service.login = function (user, success, error) {
        $http.post(CONFIG.API_URI + '/total/Sessions/Create',user,{'withCredentials':true}).then(function(response) {
            if (response && response.status == 200){
                success(response);
            }else{
                error()
            }
        }, function(response) {
            error(data);
            console.log(response)
        });
    };


    service.getTransactionsList = function(searchQuery,dateRange,limit,page,success,error){
        var l=10;
        var p= 0;
        if (page) p=page;
        if (limit) l=limit;
        if($rootScope.totalUser && $rootScope.totalUser.data){
            try{
                $http.post(CONFIG.API_URI + '/total/Operations?idClient='+$rootScope.totalUser.data.userInfo.ClientsIds[0]+'&idCarte='+searchQuery+dateRange+'&typeOp=transaction&limit='+l+'&start='+p,{},{withCredentials : true}).then(function successCallback(response) {
                    if (response && response.status == 200){
                        success(response);
                    }else if(response && response.status == 401) {
                        error(response);
                    }else{
                        error(response);
                    }
                },function errorCallback (data, status, headers, config) {
                    error(data)
                });
            }catch(e){
                console.log('ERROR',e)
            }
        }
    };


    return service;

}]);
