/**
 * Created by NGI Team on 05/04/2017.
 * Project : TC-Fleet
 */

angular.module("app.services").factory('assetDriverSrv', function ($http,$rootScope) {

    var service = {};
    service.getAssetDriver =function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/assetdriver/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{
                error(data);
            }



        },function(data) {
            error(data)

        });
    };

    service.add=function(assetdriver,success,error){
        $http.post($rootScope.config.API_URI+'/assetdriver/',assetdriver).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.getRelationById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/assetdriver/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.updateRelationById=function(id,assetdriver,success,error){
        $http.put($rootScope.config.API_URI+'/assetdriver/'+id,assetdriver).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.deleteRelationById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/assetdriver/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response  && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    return service;
});