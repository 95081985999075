angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/login");

    $stateProvider
        .state('authEndpoint', {
            url: '/auth-endpoint',
            template:"<div>Authentication success from oAuth </div>"
        }).state('login', {
            url: '/login',
            templateUrl: 'app/pages/login/login.tpl.html',
            controller: 'loginCtrl'
        })
        .state('subscribe', {
            url: '/subscribe',
            templateUrl: 'app/pages/subscribe/subscribe.tpl.html',
            controller: 'subscribeCtrl'
        })
        .state('app', {
            url: '/app',
            templateUrl: 'app/pages/main.tpl.html',
            controller : 'mainCtrl',
            abstract: true
        }).state('app.home', {
            url: '/home',
            ///:dashId
            views: {
                "sidebar" : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"


                },
                "content" : {
                   // templateUrl: "app/pages/home/home.tpl.html",
                    template: "<ui-view></ui-view>",
                    controller: "homeCtrl"
                }

            }

        }).state('app.home.dash', {
            url: '/:dashId',
            templateUrl: "app/pages/home/home.tpl.html",
            controller: "homeCtrl"




        })
        .state('app.device', {
            url: '/device',
            views: {
                "sidebar" : {
                    templateUrl: "app/pages/device/sidebar/sidebar.tpl.html",
                    controller: "sidebarDeviceCtrl"
                },
                "content" : {
                    templateUrl: "app/pages/device/device.tpl.html",
                    controller: "deviceCtrl"
                },
            }

        })
        .state('app.device.add', {
            url: '/add',
            templateUrl: "app/pages/device/device.add.tpl.html",
            controller: "deviceAddCtrl"

        }).state('app.device.edit', {
            url: '/edit/:deviceId',
            templateUrl: "app/pages/device/device.edit.tpl.html",
            controller: "deviceEditCtrl"


        })
        .state('app.admin', {
            url: '/admin',
            views: {
                sidebar: {
                    templateUrl: "app/pages/admin/sidebar/sidebar.tpl.html",
                    controller: "sidebarAdminCtrl"
                },
                content: {
                    templateUrl: "app/pages/admin/admin.tpl.html",
                    controller: "adminCtrl"
                }
            }

        }).state('app.admin.dashboard', {
        url: '/dashboard',
        templateUrl: 'app/pages/admin/dashboard/dashboard.tpl.html',
        controller: "adminDashboardCtrl"
    }).state('app.admin.dashboard.add', {
        url: '/add',
        templateUrl: 'app/pages/admin/dashboard/dashboard.add.tpl.html',
        controller: "adminDashboardAddCtrl"
    }).state('app.admin.dashboard.edit', {
        url: '/edit/:dashboardId',
        templateUrl: 'app/pages/admin/dashboard/dashboard.edit.tpl.html',
        controller: "adminDashboardEditCtrl"
    }).state('app.admin.user', {
        url: '/user',
        templateUrl: 'app/pages/admin/user/user.tpl.html',
        controller: "adminUserCtrl"
    }).state('app.admin.user.add', {
        url: '/add',
        templateUrl: 'app/pages/admin/user/user.add.tpl.html',
        controller: "adminUserAddCtrl"
    }).state('app.admin.user.edit', {
        url: '/edit/:userId',
        templateUrl: 'app/pages/admin/user/user.edit.tpl.html',
        controller: "adminUserEditCtrl"
    }).state('app.admin.account', {
            url: '/account',
            templateUrl: 'app/pages/admin/account/account.tpl.html',
            controller: "adminAccountCtrl"
    }).state('app.admin.account.add', {
        url: '/add',
        templateUrl: 'app/pages/admin/account/account.add.tpl.html',
        controller: "adminAccountAddCtrl"
    }).state('app.admin.account.edit', {
        url: '/edit/:accountId',
        templateUrl: 'app/pages/admin/account/account.edit.tpl.html',
        controller: "adminAccountEditCtrl"
    })


}
