/**
 * Created by Kais CHAIBI on 28/12/2016.
 */
angular.module("app.directives").directive('numericSelector', [ function ($rootScope) {
    return {
        restrict:"EA",

            scope: {
            map: '@',
                config:'=',
                isInterval:"=",
                conditionIndex:"@"

            },
        templateUrl: 'app/commons/directives/alerts/numericSelector.tpl.html',
        link:function (scope) {

            scope.time = {};
            scope.$watch('isInterval', function (val) {
                setTimeout(function(){
                if (val) {
                    if (scope.config.data.value && !scope.config.data.value[0]) {
                        scope.config.data.value = [];
                    } else if(!scope.config.data.value && scope.config.data.length===undefined) {
                         scope.config.data.value = [];
                    }
                } else {
                   if(scope.config.checker.indexOf('TIME_OF_CHANGE')<0){
                       if(!scope.config.data.value ||  scope.config.data.value.length) {
                           scope.config.data.value = 0;
                       }
                   }else{
                       if (!scope.config.data.value) {
                           scope.config.data.value = {valueThreshold:0,timeThreshold:0};
                       }

                       scope.time.timeThresholdHours = moment.duration(scope.config.data.value.timeThreshold*1000).hours();
                       scope.time.timeThresholdMinutes = moment.duration(scope.config.data.value.timeThreshold*1000).minutes();

                       scope.config.data.value.timeThreshold = moment.duration({hours: scope.time.timeThresholdHours,minutes: scope.time.timeThresholdMinutes}).asSeconds();
                   }

                }
                scope.$apply();
            })

         })

            scope.$watch('time.timeThresholdHours', function (newValue,oldValue) {
                if (newValue != oldValue) {
                    scope.config.data.value.timeThreshold = moment.duration({hours: newValue,minutes: scope.time.timeThresholdMinutes}).asSeconds();
                }
            })

            scope.$watch('time.timeThresholdMinutes', function (newValue,oldValue) {
                if (oldValue != newValue) {
                    scope.config.data.value.timeThreshold = moment.duration({hours: scope.time.timeThresholdHours,minutes: newValue}).asSeconds();
                }
            })

        }
    }
}]);
