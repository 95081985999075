angular.module('app').controller('mainCtrl', function ($scope, $rootScope,$timeout,$filter, alertSrv,alertConfigSrv, Notification) {
    $scope.alertToggle = false;
    $rootScope.alertConfigToggle = false;
    $scope.alerts = [];
    $rootScope.nomoreresult = false;
    $rootScope.nomoreconfig = false;
    $scope.start = 0;
    $scope.start2 = 0;
    /**
     * Toggling alerts sidebar
     */
    $rootScope.$on('toggleAlerts', function (event) {
        $scope.alertToggle = !$scope.alertToggle;
        $scope.alertConfigToggle = !$scope.alertConfigToggle
    });


    $rootScope.$on("newAlert",function(event,data){
        var query = {
            _id : data._id
        };
        var options  = {
            populate : ["_assetId _alertConfig"]
        };
        alertSrv.getAlertsList({query : query, options: options}, 1, 0, function (res) {
                    $scope.alerts.splice(0,0,res.result[0])
        }, function (err) {
            console.log(err)
        })
        Notification.error({message: 'Nouvelle alert  '+data.name});
    });
    $rootScope.$on("updateAlert",function(event,data){
        var filterList =  $filter('filter')($scope.alerts, {_id:data._id },true);
        if(filterList.length >= 0){
            filterList[0].status = data.status;
            filterList[0].end_dt = data.end_dt;
        }
        Notification.success({message: 'mise à jour alert '+data.name});
    });
    $rootScope.loadMoreResults = function() {
        $scope.start = $scope.start + 1;
        var query = {
            _company_owner : $rootScope.SESSION.user._company_owner._id
            //status : 1
        };
        var options  = {
            populate : ["_assetId _alertConfig"],
            sortBy:{start_dt:-1}
        };
        if(!$rootScope.nomoreresult){
            alertSrv.getAlertsList({query : query, options: options}, 15, $scope.start, function (res) {
                if(res.success && res.result.length > 0){
                    angular.forEach(res.result,function (element) {
                        $scope.alerts.push(element)
                    })
                }
                else {
                    $rootScope.nomoreresult = true
                }
            }, function (err) {
                console.log(err)
            })
        }
    };
    $rootScope.loadMoreConfig=function(){
        $scope.start2 = $scope.start2 + 1;
        var query = {
            _company_owner : $rootScope.SESSION.user._company_owner._id
            //status : 1
        };
        var options  = {
            populate : ["_assetId _company_owner"],
            sortBy:{start_dt:-1}
        };
        if(!$rootScope.nomoreconfig){

        alertConfigSrv.getAlertConfigsList({query : query, options: options}, 15, $scope.start2,function(res){
            if(res.success && res.result && res.result.length > 0) {
                $rootScope.alertConfig = res.result;

            }
            else {
                $rootScope.nomoreconfig = true
            }
        },function(err){

        })
        }
    }

});