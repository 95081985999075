/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('dUtilsSrv',function ($http,$rootScope,$q,$translate,geoServiceProxy) {

    var service={};

  service.getAddressList=function(coordinatesList){

    var defer = $q.defer();
    var coordinates = "";
    angular.forEach(coordinatesList, function (elem) {
        if (coordinates)
          coordinates += "&xy=" + elem[0] + "," + elem[1];
        else
          coordinates = "&xy=" + elem[0] + "," + elem[1]
    });
    result = [];

    $http({
      method: 'POST',
      url: $rootScope.config.BND_URI + '?geoserver=default&version=1.0.0&action=revgeoBatch&radius=500&language=fr&options=polyline&format=json',
      headers: {'Content-Type': 'application/json'},
      data: coordinates
    }).then(function(response) {
      if (response && response.data && response.data.BND && response.data.BND.BatchResults){
        if (response.data.BND.BatchResults.length > 0) {
          response.data.BND.BatchResults.forEach(function (value) {
            if (value.Elements.count > 0) {
              var element = value.Elements.Element[0];
              var address = service.formatAddress(element);
              result.push(address)
            }
            else
              result.push($translate.instant("NOT_AVAILABLE"))
          })
        }
      }
      defer.resolve(result);
    }, function(error) {
      defer.reject(error);
    });
    return defer.promise;


  };

  service.formatAddress=function(address){
    var addr="";
    if(address && address.PostalAddress){
      if(address.PostalAddress.StreetNumber){
        addr+= address.PostalAddress.StreetNumber;
      }
      if(address.PostalAddress.Street){
        addr+= " " +address.PostalAddress.Street;

      }
      if(address.PostalAddress.District){
        addr+=" " +address.PostalAddress.District;
      }
      if(address.PostalAddress.City){
        addr+=" " +address.PostalAddress.City;
      }
      if(address.PostalAddress.Country){
        if(addr)
          addr += ", " + address.PostalAddress.Country;
        else
          addr +=  address.PostalAddress.Country;
      }
    }
    return  addr;
  };

  service.getAllAddress = function (assets) {
    var coordinates = [];
    angular.forEach(assets, function (asset) {
      if (asset.rt && asset.rt.loc && asset.rt.loc.coordinates && asset.rt.loc.coordinates[1]  && asset.rt.loc.coordinates[0]) {
        coordinates.push([asset.rt.loc.coordinates[0] ,asset.rt.loc.coordinates[1] ])
      }
      else {
        coordinates.push([0, 0])

      }
    });
    if (coordinates.length>0) {
      service.getAddressList(coordinates).then(function (res) {
        var i= 0;
        angular.forEach(assets, function (asset) {
          if (asset.rt && asset.rt.loc && asset.rt.loc.coordinates && asset.rt.loc.coordinates[1] && asset.rt.loc.coordinates[1] !== 0 && asset.rt.loc.coordinates[0] && asset.rt.loc.coordinates[0] !== 0)
            asset.address = res[i];
          i++;
        })

      }, function (error) {
        console.log(error);
      })

    }

    return assets;

  };




  service.setAllAddress = function (assets) {
    var coordinates = [];
    angular.forEach(assets, function (asset) {
      if (asset.rt && asset.rt.loc && asset.rt.loc.coordinates && asset.rt.loc.coordinates[1] && asset.rt.loc.coordinates[0]) {
        coordinates.push([asset.rt.loc.coordinates[0], asset.rt.loc.coordinates[1]])
      }
      else {
        coordinates.push([0, 0])

      }
    });
    if (coordinates.length > 0) {
      service.getAddressList(coordinates).then(function (res) {
        var i = 0;
        angular.forEach(assets, function (asset) {
          if (asset.rt && asset.rt.loc && asset.rt.loc.coordinates && asset.rt.loc.coordinates[1] && asset.rt.loc.coordinates[1] !== 0 && asset.rt.loc.coordinates[0] && asset.rt.loc.coordinates[0] !== 0)
            asset.address = res[i];
          if (i === assets.length - 1) {
            $rootScope.$emit('updateRealTimeData', assets);
          }
          i++;
        })

      }, function (error) {
        console.log(error);
      })

    }
  };
    return service;

});
