/**
 * Created by Hamdi on 15/01/2018.
 */
angular.module('app.dialogs').controller('chartCtrl', function ($scope, Notification,deviceMsgSrv, deviceTypeSrv,$translate) {
try {
    var now = moment().clone();
    var dates = "gps_dt";

    $scope.listSeries = [];

    $scope.startRes2 = moment().startOf("day").clone();
    $scope.endRes2 = now;


    $scope.pickerStart2 = {
        format : "DD/MM/YYYY HH:mm",
        init: moment().startOf("day").clone(),
        position: "top left",
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.startRes2 = date;
            $scope.pickerEnd2.minDate = date;
            getData2();
        }
    };

    $scope.pickerEnd2 = {
        format : "DD/MM/YYYY HH:mm",
        position: "top left",
        init: now,
        autoTrans : false,
        enableTime : true,
        enableDate : true,
        enableMinutes : true,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.endRes2 = date;
            $scope.pickerStart2.maxDate = date;
            getData2();
        }
    };



    $scope.openCalendar = function (e, picker) {
        $scope[picker].open = true;
    };

    var getData2 = function () {



        $scope.listSeries=[];

        if($scope.checkedSrc){


            $scope.loadDataDeviceMsg($scope.checkedSrc, dates);
        }


    };



    $scope.loadDataDeviceMsg = function (item, dateSrc) {

        var datas = {};
        $scope.xAxis = [];
        if ($scope.asset && $scope.asset._id) {

            if (!$scope.listSeries) {
                $scope.listSeries = [];
            }

            query = {
                query: {
                    _asset: $scope.asset._id

                },
                options: {

                    sortBy:{gps_dt:1}


                }
            };
            if ($scope.query) {
                query.query = $scope.query
            }
            query.query._asset = $scope.asset._id;

            if (item) {

                query.options.columns = '' + item + ' ' + dateSrc
            }

            query.query[dateSrc] = {$gte: $scope.startRes2, $lte: $scope.endRes2};
            deviceMsgSrv.getDeviceMsgList(query, Number.MAX_SAFE_INTEGER, 0, function (data) {

                if (data && data.success) {
                    var messages = data.result;
                    if (item) {
                        $scope.listSeries = [];

                        for (var i = 0; i < messages.length; i++) {

                            $scope.date = getValueItem(messages[i],dates);
                            if (datas[item] && getValueItem(messages[i], item)) {
                                datas[item].push([moment( $scope.date).valueOf(),getValueItem(messages[i], item)])
                            } else if(getValueItem(messages[i], item)) {
                                datas[item] = [[moment( $scope.date).valueOf(),(getValueItem(messages[i], item))]]
                            }

                            $scope.xAxis.push(moment($scope.date).format('DD/MM/YYYY HH:ss'));
                        }
                        $scope.listSeries.push({name: item, data: datas[item]})

                    }

                    $scope.chartLoad ();

                } else {
                    console.error(data.error)
                }
            }, function (data) {
                console.log(data)
            })
        }
    };

    $scope.chartLoad = function () {

         var src=$scope.checkedSrc.slice($scope.checkedSrc.lastIndexOf('.') + 1);


        Highcharts.chart('containerChart', {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: $scope.asset.name
            },

            subtitle: {
                text: $scope.dev._id
            },
            yAxis: {
                title: {
                    text:$translate.instant(src.toUpperCase())


    }
            }, xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: 'Date'
                }
            },

            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
            },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2010
                }
            },

            series: $scope.listSeries,

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        });

    };



    var getValueItem = function (msg, src) {
        var splitSrc = src.split('.');
        var obj = msg;
        var itemValue = undefined;
        splitSrc.forEach(function (value, index) {
            if (obj[value] != undefined) {
                obj = obj[value];
                if (index == splitSrc.length - 1) {
                    itemValue = obj;
                }
            } else {
                return;
            }
        });
        return itemValue;
    };
}catch(e){
    console.log('error',e)
}
});