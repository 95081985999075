angular.module('app.widgets').directive('imageMapWdg', function ($timeout, $rootScope, mediaSrv,$interval) {
    return {
        templateUrl : "app/commons/widgets/imageMap/imageMap.tpl.html",
        scope: {
            item : "=",
            options : "="
        },
        link: function (scope, elem, attr) {
            angular.element(document).ready(function () {
                scope.imageUrl = "";

                var container = L.DomUtil.get('imageMap_'+scope.item._id);
                if (!container || container._leaflet_id) {
                    return;
                } else{

                    var id = 'imageMap_'+scope.item._id;
                    scope.map = L.map(id, {
                        minZoom: 1,
                        maxZoom: 4,
                        center: [0, 0],
                        zoom: 2,
                        crs: L.CRS.Simple
                    });
                }



                if(scope.item && scope.item.extra && scope.item.extra.image){
                    mediaSrv.downloadFile(scope.item.extra.image._id?scope.item.extra.image._id:scope.item.extra.image, "test", function (response) {
                        var urlCreator = window.URL || window.webkitURL || window.mozURL;
                        scope.imageUrl = urlCreator.createObjectURL(response.data);
                        var image = document.createElement('img');
                        image.src = scope.imageUrl;
                        image.onload = function() {
                            var w = image.width;
                            var h = image.height;

                            var southWest = scope.map.unproject([0, h], scope.map.getMaxZoom()-1);
                            var northEast = scope.map.unproject([w, 0], scope.map.getMaxZoom()-1);
                            var bounds = new L.LatLngBounds(southWest, northEast);


                            scope.overlay = new L.imageOverlay(scope.imageUrl, bounds);
                            scope.overlay.addTo(scope.map);
                            scope.map.setMaxBounds(bounds);


                            scope.markers = new L.FeatureGroup();
                            scope.markers.addTo(scope.map);

                            if(scope.item && scope.item.extra && scope.item.extra.latlng &&  scope.item.extra.latlng.length > 0){
                               angular.forEach(scope.item.extra.latlng, function (latLng) {
                                   if(latLng._assetId){
                                       scope.marker = new L.marker({lat : latLng.lat, lng : latLng.lng}).bindTooltip('<strong class="text-primary">'+(scope.item._assets.filter(function (value) { return value._id === latLng._assetId })[0]?scope.item._assets.filter(function (value) { return value._id === latLng._assetId })[0].name:'Unknown')+'</strong>',  { permanent: true, interactive: false });
                                   }else{
                                       scope.marker = new L.marker({lat : latLng.lat, lng : latLng.lng}).bindTooltip('<strong class="text-primary">Unknown</strong>',  { permanent: true, interactive: false });
                                   }
                                   scope.marker.addTo(scope.markers);
                               })

                            $timeout(function () {
                                elem.children().css({
                                    width: elem.parent().width(),
                                    height: elem.parent().height()
                                });
                                $timeout(function () {
                                    scope.map.setMaxBounds(bounds);

                                    scope.map.fitBounds(scope.markers.getBounds());

                                },400);

                                scope.map.invalidateSize();

                               // scope.map._resetView(scope.map.getCenter(), scope.map.getZoom(), true);
                            });
                            }
                        };

                    }, function (err) {
                      //  console.log(err);
                    });


                }






                /**
                 * Function to be called from outside directive to update widget
                 */
                angular.extend(scope.options,{onChange:function(cfg){
                       console.log('onchange call',cfg);
                        if(cfg && cfg._id){
                            scope.item=cfg;

                            if(scope.item && scope.item.extra && scope.item.extra.image){
                                mediaSrv.downloadFile(scope.item.extra.image._id?scope.item.extra.image._id:scope.item.extra.image, "test", function (response) {
                                    var urlCreator = window.URL || window.webkitURL || window.mozURL;
                                    scope.imageUrl = urlCreator.createObjectURL(response.data);
                                    var image = document.createElement('img');
                                    image.src = scope.imageUrl;
                                    image.onload = function() {
                                        var w = image.width;
                                        var h = image.height;

                                        var southWest = scope.map.unproject([0, h], scope.map.getMaxZoom()-1);
                                        var northEast = scope.map.unproject([w, 0], scope.map.getMaxZoom()-1);
                                        var bounds = new L.LatLngBounds(southWest, northEast);


                                        L.imageOverlay(scope.imageUrl, bounds).addTo(scope.map);
                                        scope.map.setMaxBounds(bounds);

                                        scope.markers.clearLayers();

                                        if(scope.item && scope.item.extra && scope.item.extra.latlng && scope.item.extra.latlng.length > 0){
                                            angular.forEach(scope.item.extra.latlng, function (latLng) {
                                                if(latLng && latLng._assetId && scope.item &&  scope.item._assets &&  scope.item._assets.filter){
                                                    scope.marker = new L.marker({lat : latLng.lat, lng : latLng.lng}).bindTooltip('<strong class="text-primary">'+(scope.item._assets.filter(function (value) { return value._id === latLng._assetId })[0]?scope.item._assets.filter(function (value) { return value._id === latLng._assetId })[0].name:'Unknown')+'</strong>',  { permanent: true, interactive: false });
                                                }else{
                                                    scope.marker = new L.marker({lat : latLng.lat, lng : latLng.lng}).bindTooltip('<strong class="text-primary">Unknown</strong>',  { permanent: true, interactive: false });
                                                }
                                                scope.marker.addTo(scope.markers);
                                            })

                                        $timeout(function () {
                                            elem.children().css({
                                                width: elem.parent().width(),
                                                height: elem.parent().height()
                                            });
                                            $timeout(function () {
                                                scope.map.setMaxBounds(bounds);
                                                scope.map.fitBounds(scope.markers.getBounds());
                                            });

                                            scope.map.invalidateSize();
                                        },500);
                                        }
                                    };


                                }, function (err) {
                                    //console.log(err);
                                });
                            }

                        }


                    }});
                /**
                 * listen  for  newMsg and  update value
                 * @type {function(): void|*|function()}
                 */
                var listener =$rootScope.$on("newMsg",function(event,args){
                    if(args && args._id){

                    }
                });
                /**
                 * remove listener on destroy
                 */
                scope.$on('$destroy', function() {
                    listener();
                });

                $rootScope.$on('widgetResized', function (event, resizedId) {
                    $timeout(function () {
                        if(id === resizedId){

                            elem.parent().css({
                                overflow : "hidden"
                            });
                            elem.children().css({
                                width: elem.parent().width(),
                                height: elem.parent().height()
                            });
                            scope.map.invalidateSize();
                        }
                    }, 500)
                });
            });


        }
    }
});