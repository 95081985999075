angular.module('app').controller('loginCtrl', function ($rootScope,$scope, $window, $state,$cookieStore,$translate, ngDialog ,loginSrv, Notification,$auth) {

    $scope.user={};
    $scope.rememberMe=  $cookieStore.get('rememberme');
    $scope.user.login=  $cookieStore.get('login');
    $scope.user.password=  $cookieStore.get('password');

    $scope.socials = [
        {
            icon : "facebook",
            url : "https://www.facebook.com/NGI.GEO.SOLUTIONS"
        },
        {
            icon : "twitter",
            url : "https://twitter.com/ngi_maghreb"
        },
        {
            icon : "google-plus",
            url : "https://plus.google.com/+NGIMaghreb"
        },
        {
            icon : "linkedin",
            url : "https://www.linkedin.com/company/1371452/"
        }
    ];

    if ($cookieStore.get('lang')==='fr'){
        $scope.language= "fr";
    }
    else if ($cookieStore.get('lang')==='en'){
        $scope.language= "en";
    }
    else{
        $scope.language= "fr";
    }

    $scope.changeLanguage = function(langKey){
        if(langKey==="en"){
            $scope.language= "en";
            $cookieStore.put('lang','en');
            $translate.use($scope.language);
        }
        else if (langKey==="fr"){
            $scope.language= "fr";
            $cookieStore.put('lang','fr');
            $translate.use($scope.language);
        }
    };
    $scope.signIn=function() {
        var signedIn = angular.isDefined($rootScope.SESSION);

        if(signedIn){
            loginSrv.logout( function(){
                $rootScope.appData={};
                $rootScope.maplayers={};
                console.debug("logout success");
            });
        }

        if ($scope.rememberMe){

            $cookieStore.put('login',$scope.user.login);
            $cookieStore.put('password',$scope.user.password);
            $cookieStore.put('lang',$scope.language);
        }else{
            $cookieStore.remove('rememberme');
            $cookieStore.remove('login');
            $cookieStore.remove('password');
            $cookieStore.remove('lang');
        }
        loginSrv.login( $scope.user,function(){
            console.debug("login success");
            $state.go("app.home");
        },function(err){
            if(err.error){
                if(err.error.errorCode){
                    $scope.message=$translate.instant(err.error.errorCode);
                }else{
                    $scope.message=err.error
                }
            }

            Notification.error({title: $translate.instant("LOGIN_ERROR"), message: $scope.message,replaceMessage: true});

        })
    };


    $scope.remember=function(){
        if ($scope.rememberMe){
            $cookieStore.put('rememberme',true);
            $cookieStore.put('login',$scope.user.login);
            $cookieStore.put('password',$scope.user.password);
        }else{
            $cookieStore.remove('rememberme');
            $cookieStore.remove('login');
            $cookieStore.remove('password');
        }
    };

    /*Open dialog window for reset password*/
    $scope.recoverPassword = function () {
        ngDialog.open({
            template: 'app/commons/dialogs/forget/forget.dlg.tpl.html',
            controller: 'forgetDlgCtrl',
            scope: $scope
        })
    };
    $rootScope.$on("sessionExpired", function(){
        Notification.error({message:"Session expirée",replaceMessage: true});
        $state.go('login');


    })

    /*Open page for subscribe*/
    $scope.signup = function () {
        $state.go("subscribe")
    };

    $scope.authenticate = function(provider) {
        $auth.authenticate(provider).then(function(response) {
            loginSrv[provider+"Login"](response,function(res){
                $state.go("app.home")
            },function(err){
                if(err.error){
                    if(err.error.errorCode){
                        $scope.message=$translate.instant(err.error.errorCode);
                    }else{
                        $scope.message=err.error
                    }
                }

                Notification.error({title: $translate.instant("LOGIN_ERROR"), message: $scope.message,replaceMessage: true});
                ngDialog.open({
                    template: 'app/commons/dialogs/subscribe/subscribe.dlg.tpl.html',
                    controller: 'subscribeDlgCtrl',
                    scope: $scope,
                    width: '1000px',
                    resolve: {
                        provider: function () {
                            return provider;
                        },
                        response: function () {
                            return response;
                        }
                    }
                })
            })
        })
            .catch(function(response) {
                // Something went wrong.
                console.log(response)
            });;
    };
});