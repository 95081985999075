/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular.module("app.services").factory('reportingSrv', function ($http,$rootScope) {

    var service={};

    service.getReportsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/report/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.getReportsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/report/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    service.getReportById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/report/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteReportById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/report/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response &&  response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.updateReportById=function(id,report,success,error){
        $http.put($rootScope.config.API_URI+'/report/'+id,report).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(report,success,error){
        $http.post($rootScope.config.API_URI+'/report/',report).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{
                error(data);
            }

        }),function(data) {
            error(data)

        };
    };
    service.getTplByName=function(tpl,success,error){
        $http.get( $rootScope.config.API_URI + '/report/getReportTpl/'+tpl).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success!==false){

                success(response.data);
            }
            else{

                error(data);
            }



        },function(data) {
            error(data)

        });
    };
    service.getTplByReportId=function(reportId,success,error){
        $http.get( $rootScope.config.API_URI + '/report/getTplByReportId/'+reportId).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.getDataSource=function(ReportParams,reportId,success,error){
        var  tz= moment().utcOffset();
        $http.post($rootScope.config.API_URI + '/report/getDataSource'+"?tz=" +tz,{params:ReportParams,_id:reportId}).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    return service;

});
