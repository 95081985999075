angular.module('app').controller('adminDashboardEditCtrl', function ($scope,$rootScope,$stateParams, $state,dashboardSrv, $translate, ngDialog, Notification) {
    $scope.driver = {};
    var query = {
        query: {
            _id: $stateParams.dashboardId
        },
        options: {

        }
    };
    dashboardSrv.getDashboardList(query,1,1, function (response) {
        $scope.dashboard = response.result[0];
    }, function (err) {
        console.log(err);
    });
    /**
     * Save dashboard
     */
    $scope.save = function () {
        dashboardSrv.updateDashboardById($stateParams.dashboardId,$scope.dashboard, function (response) {
            if(response.success){
                Notification.success({
                    title : $translate.instant("EDIT"),
                    message : $translate.instant("ALERT_DASHBOARD_UPDATED",{name:$scope.dashboard.name})
                });
                $scope.loadDashboards()
                $state.go('app.admin.dashboard');
                $scope.dashboard  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.dashboard.name +"!"
                });
                $scope.dashboard  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })
    };


    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.dashboard = {};
        $state.go('app.admin.dashboard');
    }

});