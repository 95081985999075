/**
 * Project: ngiTracking
 * Created by NGI team 06/03/2017.
 */
angular.module("app.services").factory('histoUtilsSrv', function ($rootScope, leafletData) {

    var service = {};
    var stopIcon = L.icon({
        'iconUrl': 'app/assets/images/historic/Stop.png',
        'iconSize': [24, 24], // size of the icon
        'iconAnchor': [10, 5] // point of the icon which will correspond to marker's location
        //   'shadowAnchor': [4, 62],  // the same for the shadow
    });


    /**
     * Calculate paths
     * @param data
     * @param filter_drive
     * @param filter_stop
     * @returns {Array}
     */
    service.calculateTrajets = function (data, sensorOdo, dates, filter_drive, filter_stop) {
        var trajets = [];
        var lastStop = [];

        var previouslatlang = {}
        //init params
        var countDrive = 0, countStops = 0, duree = 0, trajet = {}, time = 0;
        var i = 0, j = 0, index = 1, endTrajet = false, latlang = "";
        if (data.length > 0) {
            index = 1;

            while (i < data.length) { // first while
                if (data[i].io !== undefined && data[i].io.con !== undefined && data[i].gps_dt !== undefined && data[i].loc !== undefined && data[i].loc.coordinates.length > 0) {

                    //INIT trajet
                    trajet = {};
                    trajet.coordinates = [];
                    //Order Trajet
                    trajet.index = index;
                    index++;
                    //create Start
                    trajet.Start = data[i];
                    trajet.paths = [];

                    // check state trajet

                    if (data[i].io.con === 1) {
                        trajet.state = 'drive';
                        // filter = filter_drive;
                        trajet.order = countDrive; //count number trajet drive
                        countDrive++;
                        latlang = {lat: trajet.Start.loc.coordinates[1], lng: trajet.Start.loc.coordinates[0]};
                        if (lastStop.length > 0) {
                            previouslatlang = {lat: lastStop[0], lng: lastStop[1]};
                            if (calculDistance(latlang, previouslatlang)) {
                                trajet.coordinates.push(previouslatlang); // add lat/lang to cordiantes Liste
                            }

                        }
                        trajet.paths.push(trajet.Start);
                        trajet.coordinates.push(latlang); // add lat/lang to cordiantes Liste
                        lastStop = [];

                    }
                    else {
                        trajet.state = 'stop';
                        trajet.paths.push(trajet.Start);

                        //filter = filter_stop;
                        trajet.order = countStops; //count number stops
                        latlang = {lat: trajet.Start.loc.coordinates[1], lng: trajet.Start.loc.coordinates[0]};

                        trajet.coordinates.push(latlang); // add lat/lang to cordiantes Liste
                        countStops++;
                        lastStop = [trajet.Start.loc.coordinates[1], trajet.Start.loc.coordinates[0]];
                    }
                    //init params to calculate paths trajet
                    j = i + 1;
                    endTrajet = false;
                    while (j < data.length && !endTrajet) { // while 2

                        if (data[j].io !== undefined && data[j].io.con !== undefined && data[j].gps_dt !== undefined && data[j].loc !== undefined && data[j].loc.coordinates.length > 0) {

                            if (j < data.length - 1) {
                                //filter time
                                duree = dateDiff(trajet.Start.gps_dt, data[j].gps_dt);
                                time = dateDiffInSeconds(trajet.Start.gps_dt, data[j].gps_dt);
                                if ((data[j].io.con === trajet.Start.io.con)) {// add to path
                                    trajet.paths.push(data[j]);
                                    latlang = {
                                        lat: data[j].loc.coordinates[1],
                                        lng: data[j].loc.coordinates[0]
                                    };
                                    trajet.coordinates.push(latlang);
                                    j++;
                                } else {//end trajet
                                    trajet.paths.push(data[j]);
                                    trajet.End = data[j];
                                    latlang = {
                                        lat: data[j].loc.coordinates[1],
                                        lng: data[j].loc.coordinates[0]
                                    };
                                    trajet.coordinates.push(latlang);
                                    endTrajet = true;
                                }


                                //---------------------------end split trajet----------------------------------
                            } else {// end of Liste
                                if (data[j].io.con === trajet.Start.io.con) {
                                    trajet.paths.push(data[j]);
                                    latlang = {
                                        lat: data[j].loc.coordinates[1],
                                        lng: data[j].loc.coordinates[0]
                                    };
                                    trajet.coordinates.push(latlang);

                                    trajet.End = data[j];
                                    endTrajet = true;
                                    j++;
                                } else {


                                    trajet.paths.push(data[j]);
                                    trajet.End = data[j - 1];
                                    latlang = {
                                        lat: data[j].loc.coordinates[1],
                                        lng: data[j].loc.coordinates[0]
                                    };
                                    trajet.coordinates.push(latlang);
                                    endTrajet = true;
                                }
                            }
                        }
                        else {
                            if (j === data.length - 1) {//end trajet j-1
                                trajet.End = data[j];
                                latlang = {
                                    lat: trajet.End.loc.coordinates[1],
                                    lng: trajet.End.loc.coordinates[0]
                                };
                                trajet.coordinates.push(latlang);
                                endTrajet = true;
                            }
                            else
                                j++;
                        }
                    }//end while  2
                    if (trajet.Start && trajet.End) {

                        trajet.duree = dateDiff(trajet.Start.gps_dt, trajet.End.gps_dt);
                        trajet.time = dateDiffInSeconds(trajet.Start.gps_dt, trajet.End.gps_dt);
                        if (trajet.state === 'drive') {
                            trajet.distance = calculDistanceTrajet(trajet, sensorOdo);
                            var speedValues = calculSpeedTrajet(trajet);
                            trajet.avgSpeed = speedValues.avgSpeed;
                            trajet.maxSpeed = speedValues.maxSpeed;

                        }
                        else {
                            trajet.distance = null;
                        }

                        trajets.push(trajet);

                    }
                    else if (trajet.Start) {
                        var edDate = dates.endDate
                        trajet.End = {gps_dt: edDate}
                        trajet.duree = dateDiff(trajet.Start.gps_dt, edDate);
                        trajet.time = dateDiffInSeconds(trajet.Start.gps_dt, edDate);

                        trajet.distance = 0;
                        trajet.isLast = true;
                        trajets.push(trajet);
                        endTrajet = true;
                    }

                    i = j;

                } else
                    i++;

            }//end while 1

        }//end if length


        if (filter_drive > 0) {
            trajets = filterPaths(trajets, 'drive', filter_drive, sensorOdo);
              trajets = orderPaths(trajets,sensorOdo);
        }


        if (filter_stop > 0) {
            trajets = filterPaths(trajets, 'stop', filter_stop, sensorOdo);
            trajets = orderPaths(trajets,sensorOdo);
        }


        return trajets;
    };
    function filterPaths(trajets, status, filterPath, sensorOdo) {
        var filterTrajets = [];
        if (trajets.length > 0) {
            angular.forEach(trajets, function (elem, index) {

                var newTrajet = elem;
                if (index > 0) {
                    if ((trajets[index].state === status) && trajets[index].time <= filterPath) {
                        if(trajets[index - 1].state==='drive') {
                            newTrajet = trajets[index - 1];
                            newTrajet.duree = dateDiff(trajets[index - 1].Start.gps_dt, trajets[index].End.gps_dt);
                            newTrajet.time = dateDiffInSeconds(trajets[index - 1].Start.gps_dt, trajets[index].End.gps_dt);
                            newTrajet.Start = trajets[index - 1].Start;
                            newTrajet.End = trajets[index].End;
                            var coordinates = trajets[index - 1].coordinates.concat(trajets[index].coordinates);
                            newTrajet.coordinates = coordinates;
                            var paths = trajets[index - 1].paths.concat(trajets[index].paths);
                            newTrajet.paths = paths;

                            newTrajet.distance = calculDistanceTrajet(newTrajet, sensorOdo);
                            var speedValues = calculSpeedTrajet(newTrajet);
                            newTrajet.avgSpeed = speedValues.avgSpeed;
                            newTrajet.maxSpeed = speedValues.maxSpeed;
                        }

                        else{
                          //  newTrajet = trajets[index - 1];
                            newTrajet.duree = dateDiff(trajets[index - 1].Start.gps_dt, trajets[index].End.gps_dt);
                            newTrajet.time = dateDiffInSeconds(trajets[index - 1].Start.gps_dt, trajets[index].End.gps_dt);
                            newTrajet.Start = trajets[index - 1].Start;
                        }
                        filterTrajets.pop()
                    }

                }
                else if (index === 0) {

                    if ((trajets[index].state === status) && trajets[index].time <= filterPath) {
                        if (trajets.length > 1 && trajets[index + 1]) {
                            newTrajet = trajets[index];
                            newTrajet.duree = dateDiff(trajets[index].Start.gps_dt, trajets[index + 1].End.gps_dt);
                            newTrajet.Start = trajets[index].Start;
                            newTrajet.End = trajets[index + 1].End;
                            var coordinates = trajets[index].coordinates.concat(trajets[index + 1].coordinates);
                            newTrajet.coordinates = coordinates;
                            var paths = trajets[index].paths.concat(trajets[index + 1].paths);
                            newTrajet.paths = paths;
                            newTrajet.distance = calculDistanceTrajet(newTrajet, sensorOdo);
                            var speedValues = calculSpeedTrajet(newTrajet);
                            newTrajet.avgSpeed = speedValues.avgSpeed;
                            newTrajet.maxSpeed = speedValues.maxSpeed;
                        }

                    }


                }
                filterTrajets.push(newTrajet);

            })

        }
        return filterTrajets;
    }

    function orderPaths(filterTrajets, sensorOdo) {
        var finalTrajets = [];
        var number = 0, drCount = 0, stpCount = 0;
        angular.forEach(filterTrajets, function (elem, index) {
            var newTrajet = elem;
            if (index > 0) {
                if (filterTrajets[index].state === filterTrajets[index - 1].state) {
                   if(filterTrajets[index].state ==="drive") {
                        newTrajet = filterTrajets[index - 1]
                        newTrajet.duree = dateDiff(filterTrajets[index - 1].Start.gps_dt, filterTrajets[index].End.gps_dt);
                        newTrajet.Start = filterTrajets[index - 1].Start;
                        newTrajet.End = filterTrajets[index].End;
                        var coordinates = filterTrajets[index - 1].coordinates.concat(filterTrajets[index].coordinates);
                        newTrajet.coordinates = coordinates;
                        var paths = filterTrajets[index - 1].paths.concat(filterTrajets[index].paths);
                        newTrajet.paths = paths;
                        newTrajet.distance = calculDistanceTrajet(newTrajet, sensorOdo);
                        var speedValues = calculSpeedTrajet(newTrajet);
                        newTrajet.avgSpeed = speedValues.avgSpeed;
                        newTrajet.maxSpeed = speedValues.maxSpeed;
                    }
                    else{
                        newTrajet = filterTrajets[index - 1]
                       newTrajet.duree = dateDiff(filterTrajets[index - 1].Start.gps_dt, filterTrajets[index].End.gps_dt);
                       newTrajet.Start = filterTrajets[index - 1].Start;
                        newTrajet.End = filterTrajets[index].End;
                   }

                    finalTrajets.pop()

                }



            }
            finalTrajets.push(newTrajet);

        })

        angular.forEach(finalTrajets, function (path, prIndex) {
            path.index = prIndex + 1;
            if (path.state === "drive") {
    /*           if(prIndex>0){
                       var coordinates = finalTrajets[prIndex - 1].coordinates.concat(finalTrajets[prIndex].coordinates);
                       path.coordinates = coordinates;
                       path.start = finalTrajets[prIndex - 1].End

                }*/
                drCount++;
                path.order = drCount;
                    }
            else {
                stpCount++;
                path.order = stpCount;
            }
        })


        return finalTrajets;

    }

    var hoverStyle = {
        weight: 6
    };
    var outStyle = {
        weight: 3

    };

    /**
     * show Global path
     * @param mapId
     * @param trajets
     * @param layer
     */
    service.showPaths = function (mapId, trajets, layer) {
        var startdate = "";
        var Endate = "";
        var content = "";
        var iconStop = "";
        var idstart = "";
        var idstop = "";
        var duree = "", latlang = {};
        var coordinates = [];
        if (trajets.length > 0) {
            var oldState;
            angular.forEach(trajets, function (item, key) {
                startdate = moment.utc(item.Start.gps_dt).format('DD/MM/YYYY HH:mm:ss');
                Endate = moment.utc(item.End.gps_dt).format('DD/MM/YYYY HH:mm:ss');
                duree = "";
                if (item.duree.day)
                    duree += item.duree.day + "day - ";
                duree += item.duree.hour + ":" + item.duree.min + ":" + item.duree.sec;

                if (item.state === 'drive') {
                    content = '<div class="map-popup"><div class="popup-heading"><img src="app/assets/images/historic/road_global.png" style="width: 17px;height: 17px"/> ' + 'T/S ' + (key + 1) + '/' + trajets.length + '</div><div class="popup-details">'
                        + '<div><img src="app/assets/images/historic/icon-time.png" style="width: 16px;height: 16px"/><b> Date Départ</b> ' + startdate + '</div>'
                        + '<div><img src="app/assets/images/historic/time_end.png" style="width: 16px;height: 16px"/><b> Date Arrivée</b> ' + Endate + '</div>'
                        + '</div>';

                    //add Path last End
                    if (key > 0) {
                        latlang = {
                            lat: trajets[key - 1].End.loc.coordinates[1],
                            lng: trajets[key - 1].End.loc.coordinates[0]
                        };
                        item.coordinates.splice(0, 0, latlang);//push in first position
                        var start = trajets[key - 1].End;
                        item.paths.splice(0, 0, trajets[key - 1].End);//push in last position


                    }
                    if (trajets[key + 1]) {
                        latlang = {
                            lat: trajets[key + 1].Start.loc.coordinates[1],
                            lng: trajets[key + 1].Start.loc.coordinates[0]
                        };
                        item.coordinates.splice(item.paths.length + 1, 0, latlang);//push in last position
                        item.paths.push(trajets[key + 1].Start);//push in last position
                    }
                    var polyline = L.polyline(item.coordinates, {
                        color: '#0044FA',
                        weight: 3,
                        myId: "drive_" + (key + 1)
                    }).addTo(layer);
                    polyline.bindPopup(content);
                    polyline.bindTooltip('T/S ' + (key + 1) + '/' + trajets.length, {
                        permanent: false,
                        interactive: true,
                        className: 'tooltip-marker'
                    })

                    polyline.on('mouseover', function () {
                        polyline.setStyle(hoverStyle);
                    });
                    polyline.on('click', function () {
                        $rootScope.$broadcast('showSelectedPath', {path: item, index: 0});


                    });
                    polyline.on('mouseout', function () {
                        polyline.setStyle(outStyle);

                    });

                    coordinates.push(item.coordinates)
                } else {// Stop

                    content = '<div class="map-popup"><div class="popup-heading"><img src="app/assets/images/historic/stop-higchart.png"  style="width: 24px;height: 24px; vertical-align: middle;"/><b>  Arrêt ' + 'T/S ' + (key + 1) + '/' + trajets.length + ' </b>' + '</div><div class="popup-details">'
                        + '<div><img src="app/assets/images/historic/icon-time.png" style="width: 16px;height: 16px"/> Date<b> ' + startdate + '</b></div>'
                        + '<div ><img src="app/assets/images/historic/duree.png" style="width: 16px;height: 16px"/> Durée<b>  ' + duree + '</b></div>'
                        + '</div>';

                    idstart = "stop_" + (key + 1);
                    var marker = L.marker([item.Start.loc.coordinates[1], item.Start.loc.coordinates[0]], {icon: stopIcon});
                    marker._leaflet_id = idstart;

                    marker.bindPopup(content);
                    marker.bindTooltip('T/S ' + (key + 1) + '/' + trajets.length, {
                        permanent: false,
                        interactive: true,
                        className: 'tooltip-marker'
                    })

                    marker.addTo(layer)
                    marker.on('click', function () {
                        $rootScope.$broadcast('showSelectedPath', {path: item, index: 0});


                    });
                }
                oldState = item.state;
            });
            leafletData.getMap(mapId).then(function (map) {
                var bounds = new L.LatLngBounds(coordinates);
                map.fitBounds(bounds);
            });


        }
    };
    service.ChangeSelectedPath = function (layerId, layer) {
        layer.eachLayer(function (elem) {
            if (elem.options["myId"] === layerId) {
                elem.setStyle({
                    color: '#44C565',
                    weight: 5,
                });
                elem.bringToFront()
            }
            else {
                elem.setStyle({
                    color: '#0044FA',
                    weight: 3,

                });

            }

        });
    };
    service.ChangeSelectedStop = function (position, selectedStopLayer) {

        var cssIcon = L.divIcon({
            className: 'css-icon',
            html: '<div class="gps_ring"></div>'
            , iconSize: [22, 22],
            zIndex: -1

        });
        L.marker([position.lat, position.lng], {
            icon: cssIcon,
            zIndexOffset: -1
        }).addTo(selectedStopLayer)


    };

    /**
     * drawMarkers
     * @param markers
     * @param markersLayer
     */
    service.drawMarkers = function (markers, markersLayer) {
        angular.forEach(markers, function (item) {
            var directionIcon = L.icon({
                iconUrl: 'app/assets/images/historic/' + item.iconDirection,
                iconSize: [15, 15],
                iconAnchor: [7, 7],
                popupAnchor: [0, 0]

            });
            var marker = L.marker([item.lat, item.lng], {
                iconAngle: item.angle,
                icon: directionIcon
            }).addTo(markersLayer)
                .bindPopup(item.content);
            marker.on('mouseover', function () {
                this.openPopup();
            });
            marker.on('mouseout', function () {
                this.closePopup();
            });

        })


    };


    function calculDistance(lngLat1, lngLat2) {
        var distance = 200;
        var isNear = false;
        var lat1 = lngLat1.lat;
        var lng1 = lngLat1.lng;
        var lat2 = lngLat2.lat;
        var lng2 = lngLat2.lng;
        var R = 6371; // Radius of the earth in km
        var dLat = (lat2 - lat1) * (Math.PI / 180);  // deg2rad below
        var dLon = (lng2 - lng1) * (Math.PI / 180);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c * 1000; // Distance in m
        if (d < distance)
            isNear = true;
        return isNear;
    }

    /**
     * calcul path distance
     * @param trajet
     * @returns {number}
     */
    function calculDistanceTrajet(trajet, sensorOdo) {
        var distance = 0;
        if (sensorOdo && sensorOdo.isAbsolute) {
            var startOdo = -1;
            var endOdo = -1;
            var i = 0;
            while ((startOdo < 0 || endOdo < 0) && i < trajet.paths.length) {

                if (startOdo < 0 && Number.isFinite(trajet.paths[i].odo)) {
                    startOdo = trajet.paths[i].odo;
                }

                if (endOdo < 0 && Number.isFinite(trajet.paths[trajet.paths.length - 1 - i].odo)) {
                    endOdo = trajet.paths[trajet.paths.length - 1 - i].odo;
                }
                i++
            }
            if (startOdo >= 0 && endOdo >= 0 && startOdo <= endOdo) {
                distance = endOdo - startOdo;
            }


        } else {
            angular.forEach(trajet.paths, function (item) {
                if (item.io.odo)
                    distance += item.io.odo / 1000
            });
        }
        return distance;
    }

    function calculSpeedTrajet(trajet) {
        var maxSpeed = 0;
        var avgSpeed = 0;
        var speedSum = 0;
        var speedNum = 0;
        angular.forEach(trajet.paths, function (item) {
            if (item.io.spd) {
                if (item.io.spd > maxSpeed)
                    maxSpeed = item.io.spd
                speedSum = speedSum + item.io.spd
                speedNum++;

            }
        });
        if (speedSum > 0)
            avgSpeed = speedSum / speedNum;
        var speedValues = {maxSpeed: maxSpeed, avgSpeed: avgSpeed}
        return speedValues;
    }

    /**
     * calcul Date diff
     * @param date1
     * @param date2
     * @returns {{sec: string, min: string, hour: string}}
     */
    function dateDiff(date1, date2) {
        var diff = {sec: '00', min: '00', hour: '00'};// now// Initialisation du retour

        var tmp = moment(date2, "YYYY-MM/DD HH:mm:ss") - moment(date1, "YYYY-MM/DD HH:mm:ss");
        tmp = Math.floor(tmp / 1000);             // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60;                    // Extraction du nombre de secondes
        diff.sec = ('0' + diff.sec).slice(-2);

        tmp = Math.floor((tmp - diff.sec) / 60);    // Nombre de minutes (partie entière)
        diff.min = tmp % 60;
        diff.min = ('0' + diff.min).slice(-2);// Extraction du nombre de minutes

        tmp = Math.floor((tmp - diff.min) / 60);    // Nombre d'heures (entières)
        diff.hour = tmp % 24;
        diff.hour = ('0' + diff.hour).slice(-2);// Extraction du nombre d'heures

        tmp = Math.floor((tmp - diff.hour) / 24);   // Nombre de jours restants
        return diff;

    }

    function dateDiffInSeconds(startDate, endDate) {
        var diff = 0;

        var tmp = moment(endDate, "YYYY-MM/DD HH:mm:ss") - moment(startDate, "YYYY-MM/DD HH:mm:ss");
        tmp = Math.floor(tmp / 1000);
        diff = tmp;
        return diff;

    }


    /**
     * get  Time
     * @param tmp
     * @returns {{sec: string, min: string, hour: string}}
     */
    service.getDiffTime = function (tmp) {
        var diff = {sec: '00', min: '00', hour: '00'};// now// Initialisation du retour
        tmp = Math.floor(tmp / 1000);             // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60;                    // Extraction du nombre de secondes
        diff.sec = ('0' + diff.sec).slice(-2);

        tmp = Math.floor((tmp - diff.sec) / 60);    // Nombre de minutes (partie entière)
        diff.min = tmp % 60;
        diff.min = ('0' + diff.min).slice(-2);// Extraction du nombre de minutes

        tmp = Math.floor((tmp - diff.min) / 60);    // Nombre d'heures (entières)
        diff.hour = tmp % 24;
        diff.hour = ('0' + diff.hour).slice(-2);  // Extraction du nombre d'heures

        tmp = Math.floor((tmp - diff.hour) / 24);   // Nombre de jours restants
        return diff;


    };

    return service;

});
