/**
 * Created by Kais CHAIBI on 12/04/2017.
 */
angular.module("app.services").factory('reportConextSrv',function () {

    function reportConextSrv(){
        //console.log("construct ")
    }

    /**
     * set report context attribute
     * @param attribute
     * @param value
     */
    reportConextSrv.prototype.set=function(attribute,value){
        var self=this;
        this["get"+attribute.charAt(0).toUpperCase() + attribute.slice(1)]=function(){
           return  self[attribute]
       };
        this[attribute]=value;
    };
    /**
     * get report context attribute
     * @param attribute
     * @returns {*}
     */
    reportConextSrv.prototype.get=function(attribute){

        return  this[attribute];
    };
    reportConextSrv.prototype.buildParam=function(attribute,options){
     var self=this;
       if(options) {
           if (options && options.default) {
               if(typeof options.default==='string' )
             try {
                 this.set(attribute, eval(options.default));
             }catch(e){
                 console.error(e);
             }
               else{
                   console.warn("unknown report params options   default type  only String is supported ")
                   // may will be added later for custom default params
               }
           }
           if(options.editable){
               //console.log("editable");
               if(options.type ==="ref"){
                   // build form for ref
                   if(self.get(attribute)){

                   }
               }else if (options.type ==="checkBox"){
                   // build prop for boolean
               }
               else if (options.type ==="groupBox"){
                   // build prop for boolean
               }
               else if (options.type ==="select"){
                   // build prop for boolean
               }
               else  {
                   // build prop for input type string as default  "input")
               }
               // show forms
           }
           // check if param exist in context
       }

    };

    return  new reportConextSrv();
});

