/**
 * Created by Ahmed Alaya on 15/02/2017.
 */

angular.module("app").controller('adminAccountEditCtrl', function ($rootScope,$scope, $state,companySrv,$stateParams,ngDialog,$translate,Notification,countrySrv) {

    $scope.countries = countrySrv.getCountryList();

    companySrv.getCompaniesList({query: {_id: $stateParams.accountId}, options: {populate:'_ctry'} },1000,0, function (data) {
        if(data.success) {
            $scope.account = data.result[0];
            console.log('qqsdqsd',$scope.account)
        }else {
            console.log(data.error)
        }
    });

    $scope.save=function(){

        companySrv.updateCompanyById($stateParams.accountId, $scope.account,function(data){
            if(data.success){
                $state.go("app.admin.account")
                Notification($translate.instant("ALERT_ACCOUNT_UPDATED",{name:$scope.account.name}));
                $scope.loadAccounts()
            }else{
                console.log(data.error)
            }

        },function(error){
            if (error){
                console.log(error);
                $state.go("app.admin.account")}

        })
    };

    $scope.cancelAccount = function () {
        if ($state.current.name === "app.admin.account.edit") {
            $state.go('app.admin.account');
        }
        else {
            ngDialog.closeAll();
        }
    };

});
