angular.module('app.widgets').directive('tableWdg', function ($rootScope, deviceMsgSrv) {
    return {
        templateUrl:  "app/commons/widgets/table/table.tpl.html",
        scope: {
            item : "=",
            options : "="
        },
        link : function (scope, elem, attr) {
            scope.collection = [];
            scope.itemsByPage = 5;

            //        $scope.periods = ["CUSTOM", "TODAY", "YESTERDAY","THIS_WEEK", "LAST_WEEK", "SEVEN_DAYS", "THIS_MONTH", "LAST_MONTH", "THIRTEEN_DAYS", "THIS_YEAR" ];

            if(scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.period){
                switch (scope.item.extra.histo.period){
                    case "TODAY" :
                        scope.startDt = moment().clone().startOf('day');
                        scope.endDt = moment().clone();
                        break;
                    case "YESTERDAY":
                        scope.startDt = moment().clone().subtract(1, "day").startOf("day");
                        scope.endDt = moment().clone().subtract(1, "day").endOf("day");
                        break;
                    case "THIS_WEEK":
                        scope.startDt = moment().clone().startOf("week");
                        scope.endDt = moment().clone();
                        break;
                    case "LAST_WEEK":
                        scope.startDt = moment().clone().subtract(1, 'weeks').startOf("week");
                        scope.endDt = moment().clone().subtract(1, 'weeks').endOf("week");
                        break;
                    case "SEVEN_DAYS":
                        scope.startDt = moment().clone().subtract(7, 'days').startOf("day");
                        scope.endDt = moment().clone();
                        break;
                    case "THIS_MONTH":
                        scope.startDt = moment().clone().startOf("month");
                        scope.endDt = moment().clone();
                        break;
                    case "LAST_MONTH":
                        scope.startDt = moment().clone().subtract(1, "months").startOf("month");
                        scope.endDt = moment().clone().subtract(1, "months").endOf("month");
                        break;
                    case "THIRTEEN_DAYS":
                        scope.startDt = moment().clone().subtract(30, "days").startOf("day");
                        scope.endDt = moment().clone();
                        break;
                    case "THIS_YEAR":
                        scope.startDt = moment().clone().startOf("year");
                        scope.endDt = moment().clone();
                        break;
                    default:
                        scope.startDt = moment(scope.item.extra.histo.startDt).clone();
                        scope.endDt = moment(scope.item.extra.histo.endDt).clone();
                        break;
                }
            }


            function getValue(device, input) {
                var val = "";

                if(input)
                    var parts = input.split('.');

                if(device && device.rt && parts && parts.length === 2){
                    val = device.rt[parts[1]];
                }else {
                    if(device && device.rt && device.rt.io && parts)
                        val = device.rt.io[parts[2]];
                }
                return val;

            }

            scope.getDeviceMessages = function (tableState, ctrl) {
                if(scope.startDt && scope.endDt) {

                    var asset = scope.asset;
                    scope.collection = [];
                    if (!scope.stCtrl && ctrl) {
                        scope.stCtrl = ctrl;
                    }

                    if (!tableState && $scope.stCtrl) {
                        scope.stCtrl.pipe();
                        return;
                    }
                    var start = 0;
                    var number = scope.itemsByPage;
if(scope.asset && asset && asset._id && scope.startDt!=null &&  scope.endDt!=null ){
                    var query = [
                        {
                            "$match": {
                                "_asset": "#OBJID#" + asset._id + "#OBJIDEND#",
                                "gps_dt": {
                                    $gte: "#ISODATE#" + scope.startDt.clone().toDate().toISOString() + "#ISODATEEND#",
                                    $lte: "#ISODATE#" + scope.endDt.clone().toDate().toISOString() + "#ISODATEEND#"
                                }
                            }
                        }
                    ];

                    if (tableState.pagination) {
                        var pagination = tableState.pagination;
                        number = pagination.number || scope.itemsByPage;
                        start = pagination.start / number + 1 || 1;
                    }

                    deviceMsgSrv.getDeviceMsgAgg2({aggregate: query}, number, start, function (response) {

                        if (response.success === true) {
                            angular.forEach(response.result, function (message) {
                                scope.collection.push({
                                    asset: asset.name,
                                    value: Number(getMessageValue(message, scope.item.input)).toFixed(2),
                                    time: message.gps_dt
                                });
                            });


                            tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                            tableState.pagination.totalItemCount = response.total_count;

                            scope.tableState = tableState;
                        }

                    }, function (err) {
                        console.log(err);
                    })
                }
                }

            };

            function getMessageValue(message, input) {
                var val = "";
                var parts = input.split('.');

                if(parts.length === 2){
                    val = message[parts[1]];
                }else {
                    val = message.io[parts[2]];
                }
                return val;

            }

            if(scope.item._assets && scope.item._assets.length>0) {

                angular.forEach(scope.item._assets, function (asset) {
                    if (scope.item.type === "TABLE_HISTO") {
                        scope.asset = asset;
                    } else {
                        var val = Number(getValue(asset, scope.item.input)).toFixed(2);
                        if (val) {
                            scope.collection.push({asset: asset.name, value: val});
                        }

                    }
                });
            }
            /**
             * Function to be called from outside directive to update widget
             */
            angular.extend(scope.options,{onChange:function(cfg){

                if(cfg && cfg._id) {
                    scope.item = cfg;
                    if (scope.item._assets && scope.item._assets.length > 0) {
                        scope.collection = [];
                        angular.forEach(scope.item._assets, function (asset) {
                            if (scope.item.type === "TABLE_HISTO") {
                                if(scope.item.extra && scope.item.extra.histo && scope.item.extra.histo.period){
                                    switch (scope.item.extra.histo.period){
                                        case "TODAY" :
                                            scope.startDt = moment().clone().startOf('day');
                                            scope.endDt = moment().clone();
                                            break;
                                        case "YESTERDAY":
                                            scope.startDt = moment().clone().subtract(1, "day").startOf("day");
                                            scope.endDt = moment().clone().subtract(1, "day").endOf("day");
                                            break;
                                        case "THIS_WEEK":
                                            scope.startDt = moment().clone().startOf("week");
                                            scope.endDt = moment().clone();
                                            break;
                                        case "LAST_WEEK":
                                            scope.startDt = moment().clone().subtract(1, 'weeks').startOf("week");
                                            scope.endDt = moment().clone().subtract(1, 'weeks').endOf("week");
                                            break;
                                        case "SEVEN_DAYS":
                                            scope.startDt = moment().clone().subtract(7, 'days').startOf("day");
                                            scope.endDt = moment().clone();
                                            break;
                                        case "THIS_MONTH":
                                            scope.startDt = moment().clone().startOf("month");
                                            scope.endDt = moment().clone();
                                            break;
                                        case "LAST_MONTH":
                                            scope.startDt = moment().clone().subtract(1, "months").startOf("month");
                                            scope.endDt = moment().clone().subtract(1, "months").endOf("month");
                                            break;
                                        case "THIRTEEN_DAYS":
                                            scope.startDt = moment().clone().subtract(30, "days").startOf("day");
                                            scope.endDt = moment().clone();
                                            break;
                                        case "THIS_YEAR":
                                            scope.startDt = moment().clone().startOf("year");
                                            scope.endDt = moment().clone();
                                            break;
                                        default:
                                            scope.startDt = moment(scope.item.extra.histo.startDt).clone();
                                            scope.endDt = moment(scope.item.extra.histo.endDt).clone();
                                            break;
                                    }
                                }



                                scope.asset = asset;
                                if(scope.startDt && scope.endDt){
                                    scope.getDeviceMessages({});
                                    scope.tableState.pagination.start = 0;
                                }
                            } else {
                                var val = Number(getValue(asset, scope.item.input)).toFixed(2);
                                if (val) {
                                    scope.collection.push({asset: asset.name, value: val});
                                }

                            }
                        });


                    }
                }




            }});

            /**
             * listen  for  newMsg and  update value
             * @type {function(): void|*|function()}
             */
            var listener =$rootScope.$on("newMsg",function(event,args){
                if (scope.item.type !== "TABLE_HISTO") {
                    if (args && args._id) {
                        var index = -1;
                        if (scope.item._assets && scope.item._assets.length > 0) {
                            for (var i = 0; i < scope.collection.length; i++) {
                                if (scope.collection[i].asset === args.name) {
                                    index = i;
                                }
                            }
                            var val = Number(getValue(args, scope.item.input)).toFixed(2);
                            if (index > -1) {
                                scope.collection[index].value = val;
                            }
                        }

                    }
                }
            })

        }
    }
});