angular.module('app').controller('sidebarHomeCtrl', function ($scope,$state,$stateParams, $rootScope, $q, $timeout, ngDialog, dashboardSrv) {
$scope.shown = false;
    /**
     * init dashboard list
     * @type {Array}
     */
    $rootScope.currentDashboard=[];
/*    console.log('$rootScope.selectedDashboard',$rootScope.selectedDashboard)
    console.log('$stateParams',$stateParams.dashId)
    console.log('$rootScope.currentDashboard',$rootScope.currentDashboard._id)*/

    /**
     * Loading dashboards
     */
    function  loadDashboard(){
        dashboardSrv.getDashboardList({options:{populate:"_share._shared_by _widgets._assets" ,sortBy:{order:1}}},1000,0,function(res){

            if(res && res.success){
                $scope.dashboards=res.result;
                if($scope.dashboards.length>0){
                    $scope.dashboards =$scope.dashboards.map(function(val){
                        var isRemovable=false;
                        var isEditable=false;
                        var isUserOwner =false;
                        if($rootScope.SESSION.user._id===val._created_by){
                            isUserOwner=true;
                            isEditable=true;
                            isRemovable =true;
                        }else{
                            if(val._share && val._share.length>0){
                                for(var i = 0 ; i<val._share.length ; i++){
                                    if(val._share[i]._users && val._share[i]._users.length>0){
                                        var usersId=val._share[i]._users.map(function(id){ return id.toString()});
                                        if(usersId.indexOf($rootScope.SESSION.user._id.toString())>=0  ){

                                            if(val._share[i].edit_permission===true)
                                                isEditable=true;
                                            if(val._share[i].delete_permission===true)
                                                isRemovable=true;

                                        }
                                    }
                                }
                            }
                        }
                        val.isRemovable=isRemovable;
                        val.isEditable=isEditable;
                        val.isUserOwner=isUserOwner;
                        return val ;
                    });
                    $scope.dashboards.sort(function(val1,val2){
                        var isBefore = false;
                        if(val1.isUserOwner && !val2.isUserOwner )
                            isBefore=true;
                        else  if(!val1.isUserOwner && val2.isUserOwner ){
                            isBefore=false;
                        }else{

                            isBefore=val1.order<val2.order;

                        }
                        return  !isBefore;

                    });


                    var ID=($stateParams.dashId?$stateParams.dashId:($rootScope.selectedDashboard?$rootScope.selectedDashboard:''));
                    var found=false;

                    if(ID !== '') {
                        $scope.dashboards.forEach(function (val) {
                           if(val._id==ID) {
                               $rootScope.currentDashboard = val;
                               $rootScope.selectedDashboard=val._id;
                               found=true;
                               $state.go('app.home.dash',{dashId:val._id});
                                         }

                        })
                    }else if(found==false && ID == ''){
                        $rootScope.currentDashboard = $scope.dashboards[0]
                        $rootScope.selectedDashboard=$rootScope.currentDashboard._id;
                        $state.go('app.home.dash',{dashId:$rootScope.currentDashboard._id});
                    }



                    if($rootScope.$rootScope.currentDashboard){
                        if($rootScope.$rootScope.currentDashboard.isEditable===true) {
                            $rootScope.$emit("resize:enable");
                            $rootScope.$emit("drag:enable");
                        }else{
                            $rootScope.$emit("resize:disable");
                            $rootScope.$emit("drag:disable");
                        }
                    }


                }
            }

        },function(err){
            console.log(error);
        })

    }

    $scope.mode = "VIEW";


    /**
     * Loading devices
     */
    $scope.devices = [
        {
            _id : "1",
            name: "6984 TU 172",
            rt : {
                "uid" : "356307045021648",
                "gps_dt" : "2017-01-17T11:36:56.500Z",
                "srv_dt" : "2017-01-17T11:39:06.688Z",
                "loc" : {
                    "type" : "Point",
                    "coordinates" : [
                        10.2731904,
                        36.7751872
                    ]
                },
                "io" : {
                    CAN_APP: 0,
                    CAN_APP_dt: "2017-11-20T11:43:26.000Z",
                    CAN_FLL : 0,
                    CAN_FLL_L : 32,
                    CAN_FLL_L_dt :"2017-11-20T11:01:01.000Z",
                    CAN_ODO : 71997,
                    CAN_ODO_dt : "2017-11-20T11:43:26.000Z",
                    CAN_RPM : 0,
                    CAN_RPM_dt : "2017-11-20T11:47:40.000Z",
                    CAN_TFU : 42668,
                    CAN_TFU_dt : "2017-11-20T11:46:36.000Z",
                    CAN_VSS : 0,
                    CAN_VSS_dt : "2017-11-20T11:46:36.000Z",
                    alt : 4,
                    alt_dt : "2017-11-20T11:45:15.000Z",
                    ang : 310,
                    ang_dt : "2017-11-20T11:46:36.000Z",
                    bat_curr : 0,
                    bat_curr_dt : "2017-10-11T09:57:49.000Z",
                    bat_sig : 12458,
                    bat_sig_dt : "2017-11-20T14:47:40.000Z",
                    bat_volt :  0,
                    bat_volt_dt : "2017-10-11T09:57:49.000Z",
                    con :  0,
                    con_dt : "2017-11-20T11:47:40.000Z",
                    dig1  : 0,
                    dig1_dt : "2017-11-20T11:47:40.000Z",
                    dig2 : 0,
                    dig3 : 0,
                    dig4 : 0,
                    dls_temp1 : 0,
                    evt_id : 0,
                    evt_id_dt  : "2017-11-20T12:47:40.000Z",
                    gsm_sig : 4,
                    gsm_sig_dt  : "2017-11-20T11:46:36.000Z",
                    ibtn : 0,
                    ignition  : 0,
                    ignition_dt : "2017-11-20T11:03:18.000Z",
                    motion  : 0,
                    motion_dt  : "2017-11-20T11:46:36.000Z",
                    nb_sat  : 14,
                    nb_sat_dt : "2017-11-20T14:47:40.000Z",
                    odo : 0,
                    odo_abs : 6658354000,
                    odo_abs_dt  : "2017-09-20T07:56:19.000Z",
                    priority : 0,
                    priority_dt : "2017-11-20T12:47:40.000Z",
                    spd : 0,
                    spd_dt :  "2017-11-20T11:46:36.000Z"
                },
                "odo" : 0.0
            }
        },
        {
            _id : "2",
            name: "4925 TU 183",
            rt : {
                "uid" : "356307045021648",
                "gps_dt" : "2017-01-17T11:36:56.500Z",
                "srv_dt" : "2017-01-17T11:39:06.688Z",
                "loc" : {
                    "type" : "Point",
                    "coordinates" : [
                        10.2731904,
                        36.7751872
                    ]
                },
                "io" : {
                    "alt" : 12,
                    "spd" : 53,
                    "ang" : 215,
                    "con" : 1,
                    "odo" : 318,
                    "nb_sat" : 8,
                    "dig1" : 1,
                    "dig2" : 0,
                    "dig3" : 0,
                    "dig4" : 0
                },
                "odo" : 0.0
            }
        },
        {
            _id : "3",
            name: "4922 TU 183",
            rt : {
                "uid" : "356307045021648",
                "gps_dt" : "2017-01-17T11:36:56.500Z",
                "srv_dt" : "2017-01-17T11:39:06.688Z",
                "loc" : {
                    "type" : "Point",
                    "coordinates" : [
                        10.2731904,
                        36.7751872
                    ]
                },
                "io" : {
                    "alt" : 12,
                    "spd" : 53,
                    "ang" : 215,
                    "con" : 1,
                    "odo" : 318,
                    "nb_sat" : 8,
                    "dig1" : 1,
                    "dig2" : 0,
                    "dig3" : 0,
                    "dig4" : 0
                },
                "odo" : 0.0
            }
        }
    ];


    /**
     * Loading widgets types
     */
    $scope.realtimeWidgets = [
        "ANGLE",
        "FUEL",
        "ODOMETER",
        "SPEEDOMETER",
        "ALTIMETER",
        "THERMOMETER",
        "GRAPH",
        "TABLE",
        "MAP",
        "STRING",
        "NUMBER",
        "LED",
        "IMAGE_MAP"
    ];

    $scope.historicWidgets = [
        "GRAPH_HISTO",
        "TABLE_HISTO",
        "MAP_HISTO"
    ];



    /**
     * Preparing widget from droppable device and dashboard
     * @param e
     * @param ui
     * @param dashboard
     */
    $scope.deviceToWidget = function (e, ui, dashboard) {
        var deferred = $q.defer();
        $scope.dropTo = dashboard;

        $scope.widget = {};
        $scope.widget.style = "DARK";
        $scope.widget.type = $scope.draggedWidget;


        ngDialog.open({
            template: "app/commons/dialogs/widget/widget.tpl.html",
            controller: "widgetDlgCtrl",
            scope: $scope,
            className: "ngdialog-theme-default custom_big_dialog"
        });

        deferred.reject();
        return deferred.promise;
    };

    /**
     * Deleting dashboard
     */
    $scope.deleteDash = function (dashboard) {
        $scope.deletedDashboard = dashboard;
        var dialog = ngDialog.open({
            template : "app/commons/dialogs/dashboard/confirm.dashboard.tpl.html",
            controller: "confirmDashDeleteDlg",
            scope: $scope
        });

        dialog.closePromise.then(function(data) {
            // if(data.value && data.value !== '$document'){
            //     $scope.dashboards = data.value;
            // }
            loadDashboard();

            $timeout(function () {
                angular.element(document).ready(function () {
                    angular.element('#accordion .collapse .card-block').css({
                        "height" : angular.element("body").height() - (angular.element('.navbar').outerHeight() +  angular.element('.dashboards').outerHeight() + (angular.element('.card-header').outerHeight())),
                        "max-height" : angular.element("body").height() - (angular.element('.navbar').outerHeight() +  angular.element('.dashboards').outerHeight() + (angular.element('.card-header').outerHeight()))
                    });
                });
            }, 1000);
        });

    };

    /**
     * Sharing dashboard
     * @param dashboard
     */
    $scope.shareDash = function (dashboard) {
        $scope.sharedDashboard = dashboard;
        var dialog = ngDialog.open({
            template : "app/commons/dialogs/dashboard/share.dashboard.tpl.html",
            controller: "shareDashboardDlg",
            scope: $scope
        });
    };


    /**
     * Getting dragged device
     * @param e
     * @param ui
     * @param device
     */
    $scope.startCallback = function (e, ui, widget) {
        $scope.draggedWidget = widget;
        $('#historic').addClass('no-scroll');
        $('#realtime').addClass('no-scroll');
    };

      $scope.stopCallback = function () {
          $('#historic').removeClass('no-scroll');
          $('#realtime').removeClass('no-scroll');
      };

    //$rootScope.currentDashboard = $scope.dashboards[0];

    /**
     * Create new dashboard
     */

    $scope.createDash = function () {
        $scope.dashboard = {};
        $scope.mode = "CREATE";
        $scope.object = "DASHBOARD";
    };

    $scope.cancelEdit = function () {
        $scope.mode = "VIEW";
    };

    $rootScope.deviceToWidget = function (dashboard) {
        $scope.dropTo = dashboard;

        $scope.widget = {};
        $scope.widget.style = "DARK";
        $scope.widget.type = $scope.draggedWidget;

        ngDialog.open({
            template: "app/commons/dialogs/widget/widget.tpl.html",
            controller: "widgetDlgCtrl",
            scope: $scope,
            className: "ngdialog-theme-default custom_big_dialog"
        });

    };

    /**
     * Save newly created Dashboard
     */
    $scope.saveObj = function () {
        if($scope.object === "DASHBOARD"){
            if($scope.mode === 'CREATE'){
                $scope.dashboard.order = $scope.dashboards.length - 1;
                $scope.dashboard._widgets=[] ;
                $scope.shown = false;
                dashboardSrv.add($scope.dashboard,function(res){

                    if(res && res.success){
                        $scope.dashboards.push(res.result);
                        loadDashboard();
                        $timeout(function () {
                            angular.element(document).ready(function () {
                                angular.element('#accordion .collapse .card-block').css({
                                    "height" : angular.element("body").height() - (angular.element('.navbar').outerHeight() +  angular.element('.dashboards').outerHeight() + (angular.element('.card-header').outerHeight())),
                                    "max-height" : angular.element("body").height() - (angular.element('.navbar').outerHeight() +  angular.element('.dashboards').outerHeight() + (angular.element('.card-header').outerHeight()))
                                });
                            });
                        }, 500);
                    }else{
                        console.error(res);
                    }

                },function(err){

                });



                $scope.object = "";
                $scope.dashboard = {};

                $rootScope.currentDashboard = $scope.dashboards[$scope.dashboards.length - 1];
                $scope.mode = "VIEW";
            }else{
                dashboardSrv.updateDashboardById($scope.dashboard._id,$scope.dashboard,function(res){

                    if(res && res.success){
                        $scope.object = "";
                        $scope.index = "";
                        $scope.mode = "VIEW";
                        $scope.shown = false;
                        for(var i=0;i<$scope.dashboards.length;i++){
                            if($scope.dashboards[i]._id=== $scope.dashboard._id){
                                $scope.dashboards[i] = $scope.dashboard;
                                break ;
                            }

                        }
                        loadDashboard()
                    }else{
                        console.error(res);
                    }
                },function(err){

                    console.error(err);
                });

                $rootScope.currentDashboard = $scope.dashboards[$scope.index];

            }
        }
    };

    /**
     * Selecting dashboard
     * @param dashboard
     */



    $scope.selectDash = function (dashboard) {

       // $stateParams.dashId=dashboard._id;
        $rootScope.currentDashboard = dashboard;
        $rootScope.selectedDashboard= dashboard._id;
//console.log('$rootScope.selectedDashboard 2',$rootScope.selectedDashboard)
        $state.go('app.home.dash',{dashId:dashboard._id});

        if($rootScope.currentDashboard){

            if($rootScope.currentDashboard.isEditable===true) {
                $rootScope.$broadcast("resize:enable");
                $rootScope.$broadcast("drag:enable");
            }else{
                $rootScope.$broadcast("resize:disable");
                $rootScope.$broadcast("drag:disable");
            }
        }
    };

    /**
     * Configuring dashboard
     * @param dashboard
     */
    $scope.configDash = function (dashboard) {
        $scope.object = "DASHBOARD";
        $scope.mode = "EDIT";
        $scope.dashboard = dashboard;
        $scope.index = $scope.dashboards.indexOf($scope.dashboard);
        $rootScope.currentDashboard = $scope.dashboards[$scope.index];
    };


     $rootScope.$on('actionDashboard', function (event, options) {
        var dashboard = options.dashboard;
        var action = options.action;

        switch (action){
            case "DELETE" :
                $scope.deleteDash(dashboard);
                break;
            case "CONFIG":
                $scope.configDash(dashboard);
                break;
            case "SHARE":
                $scope.shareDash(dashboard);
                break;
        }

    });


    /**
     * Configuring widget
     */
    $rootScope.$on('configWidget', function (e, widget) {
        $scope.inputs = $scope.getInputs(widget.device);
        $scope.widget = widget;
        $scope.object = "WIDGET";
        ngDialog.open({
            template: "app/commons/dialogs/widget/widget.tpl.html",
            controller: "widgetDlgCtrl",
            scope: $scope,
            className: "ngdialog-theme-default custom_big_dialog"
        });
    });

    $scope.inputChanged = function () {
        //Numeric 0-1
        //ana value
        var input = $scope.widget.input;
        var parts = input.split('.');
        var name = "";
        if(parts.length === 2){
            name = parts[1].toUpperCase();
        }else{
            name = parts[2].toUpperCase();
        }
        switch (name){
            case 'LOC' :
                $scope.widgetTypes = ['MAP'];
                break;
            case 'ODO' :
                $scope.widgetTypes = ['ODOMETER', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'CAN_TEMP' :
                $scope.widgetTypes = ['THERMOMETER', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'CAN_FLL' :
                $scope.widgetTypes = ['BARREL', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'CAN_FLL_L' :
                $scope.widgetTypes = ['BARREL', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'CAN_TFU' :
                $scope.widgetTypes = ['BARREL', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'CAN_ODO' :
                $scope.widgetTypes = ['ODOMETER', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'SPD' :
                $scope.widgetTypes = ['SPEEDOMETER', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'ALT' :
                $scope.widgetTypes = ['ALTIMETER', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'ANG' :
                $scope.widgetTypes = ["ANGLE",'GRAPH', 'STRING', 'NUMBER'];
                break;
            case 'CON' :
                $scope.widgetTypes = ['LED', 'GRAPH', 'STRING', 'NUMBER'];
                break;
            default :
                $scope.widgetTypes = [
                    "ANGLE",
                    "ODOMETER",
                    "SPEEDOMETER",
                    "THERMOMETER",
                    "GRAPH",
                    "MAP",
                    "STRING",
                    "NUMBER",
                    "LED"
                ];
                break;
        }
    };



    $timeout(function () {
        angular.element(document).ready(function () {
            angular.element('#accordion .collapse .card-block').css({
                "height" : angular.element("body").height() - (angular.element('.navbar').outerHeight() +  angular.element('.dashboards').outerHeight() + (angular.element('.card-header').outerHeight())),
                "max-height" : angular.element("body").height() - (angular.element('.navbar').outerHeight() +  angular.element('.dashboards').outerHeight() + (angular.element('.card-header').outerHeight()))
            });
        });


    }, 500);

    $(document).ready(function(){
        $('[data-toggle="popover"]').popover();
    });

    loadDashboard();

    $scope.$on('$destroy', function () {
        $rootScope.$$listeners.actionDashboard=[];
    });
});