angular.module("app.services").factory("mediaSrv",function (CONFIG, $timeout, $http, Upload, $rootScope) {

    var service={};

    service.uploadFiles=function(files ,success,error,progress){
        //open progress bar
        try {
            if (files && files.length) {
                Upload.upload({
                    url: CONFIG.API_URI + '/' + "media",
                    data: {
                        file: files
                    }
                }).then(function (response) {
                    $rootScope.$broadcast("progressClose");
                    success(response.data);
                }, function (response) {
                    $rootScope.$broadcast("progressClose");
                    if (response.status > 0) {

                        error(response);
                    } else {
                        success(response);
                    }


                }, function (evt) {

                    //console.log(evt);
                    $rootScope.$broadcast("progress", evt);
                    progress(evt);

                });
            } else {
                error("NO_FILES");
            }
        }catch(e){
            console.log(e);
        }

    };

    service.downloadFile=function(id ,fileName,success,error,progress){
        //open progress bar
        $http({method:"GET",url:CONFIG.API_URI  + '/' + "media/download/"+id , responseType: "blob" ,onProgress: function(event) {
                $timeout(function() {

                    progress(event);
                    $rootScope.$broadcast("progress",event)
                });
            }}).then(function (data, status, headers) {

            try {
                success(data);
            }catch(e){
                console.error(e);
            }

        }),error(function (data) {
            $rootScope.$broadcast("progressClose");

            error(data)
        },function notify(evt) {
            console.log('notified',evt);
        });

    };

    return service;
});