/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

angular.module("app.services").factory('assetSrv', function ($http,$rootScope, $filter) {

    var service={};

    service.getAssetsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/asset/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        },function(data) {
            error(data)

        });
    };
    service.getAssetsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/asset/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    service.getAssetById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/asset/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.deleteAssetById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/asset/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response  && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    service.updateAssetById=function(id,asset,success,error){
        $http.put($rootScope.config.API_URI+'/asset/'+id,asset).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };
    service.add=function(asset,success,error){
        $http.post($rootScope.config.API_URI+'/asset/',asset).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };


    /**
     * GET Asset Icon
     * @param asset
     * @param view
     * @param status
     */
    service.getIcon = function (asset, view, status) {
        var path = "app/assets/images/fleet/";
        var type = "personal_vehicle";
        if(angular.isDefined(asset)){
            if(angular.isDefined(asset.type)) {
                type = asset.type.replace(" ", "_").toLowerCase();
            }
        }

        if(status === undefined) {
            status = "stop";

            if(asset.rt === undefined){
                status = "disabled";
            }else{
                // Car current stat
                if(angular.isDefined(asset.rt.io) && angular.isDefined(asset.rt.io.con) && asset.rt.io.con === 1 && angular.isDefined(asset.rt.io.spd) && asset.rt.io.spd > 0){
                    status = "drive";
                }
                if(angular.isDefined(asset.rt.io) && angular.isDefined(asset.rt.io.con) && asset.rt.io.con === 1 && angular.isDefined(asset.rt.io.spd) && asset.rt.io.spd === 0){
                    status = "idle";
                }

                var now = moment();
                var lastUpdate = moment(asset.rt.gps_dt);
                var difference = now.diff(lastUpdate, 'days');
                if(difference >= 3 ){
                    status = "disabled";
                }
            }
        }

        return path + type + "/" + status + "_" + view + ".png";


    };
    service.getIcons = function (asset) {
        var path = "app/assets/images/fleet/";
        var type = "personal_vehicle";
        var status = "stop";
        if(angular.isDefined(asset)){
            if(angular.isDefined(asset.type)) {

                //If path  (path + assetType) not exist assetType = personal_vehicle
                //Create path

                    for(var i=0; i<$rootScope.assetTypeList.length; i++){
                        if(asset.type.toLowerCase() === $rootScope.assetTypeList[i]._id.toLowerCase()){
                            type = asset.type.replace(" ", "_").toLowerCase();
                        }
                    }
            }
        }

        if(asset.rt === undefined){
            status = "disabled";
        }else{
            // Car current stat
            if(angular.isDefined(asset.rt.io) && angular.isDefined(asset.rt.io.con) && asset.rt.io.con === 1 && angular.isDefined(asset.rt.io.spd) && asset.rt.io.spd > 0){
                status = "drive";
            }
            if(angular.isDefined(asset.rt.io) && angular.isDefined(asset.rt.io.con) && asset.rt.io.con === 1 && angular.isDefined(asset.rt.io.spd) && asset.rt.io.spd === 0){
                status = "idle";
            }

            var now = moment();
            var lastUpdate = moment(asset.rt.gps_dt);
            var difference = now.diff(lastUpdate, 'days');
            if(difference >= 3 ){
                status = "disabled";
            }

        }

        return {
            side : path + type + "/" + status + "_side.png",
            up : path + type + "/" + status + "_up.png",
            _3d : path + type + "/" + status + "_3d.png"
        };
    };


    service.getIconType = function (type, status) {
        var path = "app/assets/images/fleet/";
        var assetType = "personal_vehicle";
        var sts = "disabled";
        if(angular.isDefined(type)){
            assetType = type.replace(" ", "_").toLowerCase();
        }
        if(angular.isDefined(status)){
            sts = status;
        }
        return path + assetType + "/"+sts+"_side.png";


    };
    service.getAssetBrand = function () {
        return [
            {
                "id" : "ABARTHA",
                "name"  : "Abartha"
            },
            {
                "id" : "ALFA_ROMEO",
                "name"  : "Alfa Romeo"
            },
            {
                "id" : "ASTON_MARTIN",
                "name" : "Aston Martin"
            },
            {
                "id" : "AUDI",
                "name" : "Audi"
            },
            {
                "id" : "BENTLEY",
                "name" : "Bentley"
            } ,
            {
                "id" : "BMW",
                "name" : "BMW"
            } ,
            {
                "id" : "BUGATTI",
                "name" : "Bugatti"
            } ,
            {
                "id" : "CADILLAC",
                "name" : "Cadillac"
            } ,
            {
                "id" : "CAPARO",
                "name" : "Caparo"
            } ,
            {
                "id" : "CATERHAM",
                "name" : "Caterham"
            } ,
            {
                "id" : "CATERPILLAR",
                "name" : "Caterpillar"
            } ,
            {
                "id" : "CHEVROLET",
                "name" : "Chevrolet"
            } ,
            {
                "id" : "CHRYSLER",
                "name" : "Chrysler"
            } ,
            {
                "id" : "CITROEN",
                "name" : "Citroën"
            } ,
            {
                "id" : "CORVETTE",
                "name" : "Corvette"
            },
            {
                "id" : "DAF",
                "name" : "DAF"
            },
            {
                "id" : "DACIA",
                "name" : "Dacia"
            } ,
            {
                "id" : "DAEWOO",
                "name" : "Daewoo"
            } ,
            {
                "id" : "DAIHATSU",
                "name" : "Daihatsu"
            },
            {
                "id" : "DATSUN",
                "name" : "Datsun"
            } ,
            {
                "id" : "DELOREAN",
                "name" : "Delorean"
            },
            {
                "id" : "DODGE",
                "name" : "Dodge"
            } ,
            {
                "id" : "ARASH",
                "name" : "Arash"
            } ,
            {
                "id" : "FERRARI",
                "name" : "Ferrari"
            } ,
            {
                "id" : "FIAT",
                "name" : "Fiat"
            } ,
            {
                "id" : "FISKER",
                "name" : "Fisker"
            } ,
            {
                "id" : "FORD",
                "name" : "Ford"
            } ,
            {
                "id" : "HONDA",
                "name" : "Honda"
            },
            {
                "id" : "HUMMER",
                "name" : "Hummer"
            },
            {
                "id" : "HYUNDAI",
                "name" : "Hyundai"
            } ,
            {
                "id" : "INFINITI",
                "name" : "Infiniti"
            },
            {
                "id" : "IVECO",
                "name" : "Iveco"
            },
            {
                "id" : "ISUZU",
                "name" : "Isuzu"
            },
            {
                "id" : "JAGUAR",
                "name" : "Jaguar"
            },
            {
                "id" : "JCB",
                "name" : "JCB"
            },
            {
                "id" : "JEEP",
                "name" : "Jeep"
            },
            {
                "id" : "KIA",
                "name" : "KIA"
            } ,
            {
                "id" : "KTM",
                "name" : "KTM"
            } ,
            {
                "id" : "KOENIGSEGG",
                "name" : "Koenigsegg"
            } ,
            {
                "id" : "LADA",
                "name" : "Lada"
            } ,
            {
                "id" : "LAMBORGHINI",
                "name" : "Lamborghini"
            } ,
            {
                "id" : "LANCIA",
                "name" : "Lancia"
            } ,
            {
                "id" : "LAND_ROVER",
                "name" : "Land Rover"
            } ,
            {
                "id" : "LEXUS",
                "name" : "Lexus"
            } ,
            {
                "id" : "LIGIER",
                "name" : "Ligier"
            },
            {
                "id" : "LINCOLN",
                "name" : "Lincoln"
            } ,
            {
                "id" : "LOTUS",
                "name" : "Lotus"
            },
            {
                "id" : "MAHINDRA",
                "name" : "Mahindra"
            },
            {
                "id" : "MARTINI",
                "name" : "Martini"
            },
            {
                "id" : "MAN",
                "name" : "MAN"
            },
            {
                "id" : "MASERATI",
                "name" : "Maserati"
            },
            {
                "id" : "MAYBACH",
                "name" : "Maybach"
            } ,
            {
                "id" : "MAZDA",
                "name" : "Mazda"
            },
            {
                "id" : "MCLAREN",
                "name" : "MCLaren"
            } ,
            {
                "id" : "MERCEDES",
                "name" : "Mercedes"
            },
            {
                "id" : "MG",
                "name" : "MG"
            } ,
            {
                "id" : "MINI",
                "name" : "Mini"
            } ,
            {
                "id" : "MITSUBISHI",
                "name" : "Mitsubishi"
            },
            {
                "id" : "MORGAN",
                "name" : "Morgan"
            },
            {
                "id" : "NISSAN",
                "name" : "Nissan"
            } ,
            {
                "id" : "NOBLE",
                "name" : "Noble"
            } ,
            {
                "id" : "OM",
                "name" : "OM"
            },
            {
                "id" : "OPEL",
                "name" : "Opel"
            },
            {
                "id" : "PAGANI",
                "name" : "Pagani"
            },
            {
                "id" : "PEUGEOT",
                "name" : "Peugeot"
            },
            {
                "id" : "PONTIAC",
                "name" : "Pontiac"
            },
            {
                "id" :  "PORSCHE",
                "name" : "Porsche"
            } ,
            {
                "id" :  "PROTON",
                "name" : "Proton"
            } ,
            {
                "id" : "RENAULT",
                "name" : "Renault"
            },
            {
                "id" : "ROLLS_ROYCE",
                "name" : "Rolls Royce"
            },
            {
                "id" : "ROVER",
                "name" : "Rover"
            },
            {
                "id" : "SAAB",
                "name" : "Saab"
            },
            {
                "id" : "SSANGYONG",
                "name" : "SsangYong"
            },
            {
                "id" : "SEAT",
                "name" : "Seat"
            },
            {
                "id" : "SCANIA",
                "name" : "Scania"
            },
            {
                "id" : "SKODA",
                "name" : "Skoda"
            },
            {
                "id" : "SMART",
                "name" : "Smart"
            },
            {
                "id" : "SPYKER",
                "name" : "Spyker"
            },
            {
                "id" : "SUBARU",
                "name" : "Subaru"
            },
            {
                "id" : "SUZUKI",
                "name" : "Suzuki"
            },
            {
                "id" : "TATA",
                "name" : "TATA"
            },
            {
                "id" : "TOYOTA",
                "name" : "Toyota"
            },
            {
                "id" : "VAUXHALL",
                "name" : "Vauxhall"
            },
            {
                "id" : "VOLKSWAGEN",
                "name" : "Volkswagen"
            },
            {
                "id" : "VOLVO",
                "name" : "Volvo"
            },
            {
                "id" : "WALLYSCAR",
                "name" : "Wallyscar"
            },
            {
                "id" : "OTHERS",
                "name" : "Autres"
            }
        ];
    };

    service.getAssetBrandById = function (id) {
       if(id){
           if($filter('filter')(service.getAssetBrand(), {'id':id.toUpperCase()})){
               return $filter('filter')(service.getAssetBrand(), {'id':id.toUpperCase()});
           }else{
               return {
                   id : "OTHERS",
                   name : id
               }
           }
       }else{
           return {
               id : "OTHERS",
               name : "Others"
           }
       }
    };

    return service;

});